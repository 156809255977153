export const data = [
    {
    "name": "Jungle Sniper",
    "category": "Shooting & War",
    "create_date": "2024-07-17",
    "description": "Shoot the soldiers in the Jungle. Click/tap to zoom in, move to aim and release to shoot.",
    "url": "https://cdn.htmlgames.com/JungleSniper/index.html",
    "youtube": "https://www.youtube.com/watch?v=rJ-OMlcjDzQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JungleSniper&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JungleSniper/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JungleSniper/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JungleSniper/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/junglesniper200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/junglesniper500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/junglesniper800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/junglesniper1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/junglesniper1280720.webp"
    },
    {
    "name": "Balloon Maze",
    "category": "Maze Games",
    "create_date": "2024-07-16",
    "description": "Pop all the balloons. Move the ball through the maze and hit all the balloons.",
    "url": "https://cdn.htmlgames.com/BalloonMaze/index.html",
    "youtube": "https://www.youtube.com/watch?v=aEtg5blGksE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BalloonMaze&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BalloonMaze/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BalloonMaze/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BalloonMaze/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/balloonmaze200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/balloonmaze500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/balloonmaze800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/balloonmaze1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/balloonmaze1280720.webp"
    },
    {
    "name": "Kitty Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2024-07-12",
    "description": "A Mahjong game with a cat theme. Combine 2 of the same free tiles to remove the 2 tiles.",
    "url": "https://cdn.htmlgames.com/KittyMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=KDY27VcSe74",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=KittyMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/KittyMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/KittyMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/KittyMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/kittymahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/kittymahjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/kittymahjong800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/kittymahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/kittymahjong1280720.webp"
    },
    {
    "name": "Mysterious Pirate Jewels 3",
    "category": "Connect 3",
    "create_date": "2024-07-08",
    "description": "Remove all colored backgrounds in this match 3 game with 45 new levels. Connect three or more of the same colored jewels to remove the jewels and the backgrounds.",
    "url": "https://cdn.htmlgames.com/MysteriousPirateJewels3/index.html",
    "youtube": "https://www.youtube.com/watch?v=VGifL0HpaWs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MysteriousPirateJewels3&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MysteriousPirateJewels3/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MysteriousPirateJewels3/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MysteriousPirateJewels3/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mysteriouspiratejewels3200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mysteriouspiratejewels3500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mysteriouspiratejewels3800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mysteriouspiratejewels31024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mysteriouspiratejewels31280.webp"
    },
    {
    "name": "Pyramid Solitaire - Ancient China",
    "category": "Pyramid",
    "create_date": "2024-07-05",
    "description": "A Pyramid Solitaire game in Ancient China. Remove all cards from the pagode by combining 2 cards to a total value of 13.",
    "url": "https://cdn.htmlgames.com/PyramidSolitaireAncientChina/",
    "youtube": "https://www.youtube.com/watch?v=D3N6Dt1C6sw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PyramidSolitaireAncientChina&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PyramidSolitaireAncientChina/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PyramidSolitaireAncientChina/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PyramidSolitaireAncientChina/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/pyramidsolitaire-ancientchina200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/pyramidsolitaire-ancientchina500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/pyramidsolitaire-ancientchina800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/pyramidsolitaire-ancientchina1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/pyramidsolitaire-ancientchina1280720.webp"
    },
    {
    "name": "Aladdin Solitaire",
    "category": "Solitaire Games",
    "create_date": "2024-07-02",
    "description": "Remove all cards by combining the same cards. Click on 2 of the same open cards to remove the cards, click on the closed stack to turn over 2 cards.",
    "url": "https://cdn.htmlgames.com/AladdinSolitaire/",
    "youtube": "https://www.youtube.com/watch?v=aBDqOfY5oQ4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=AladdinSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/AladdinSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/AladdinSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/AladdinSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/aladdinsolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/aladdinsolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/aladdinsolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/aladdinsolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/aladdinsolitaire1280720.webp"
    },
    {
    "name": "Tetris Puzzle",
    "category": "Puzzle Games",
    "create_date": "2024-06-25",
    "description": "Drop down blocks and fill the gaps. Click where you want the block to land. You can rotate a block.",
    "url": "https://cdn.htmlgames.com/TetrisPuzzle/index.html",
    "youtube": "https://www.youtube.com/watch?v=MQlYxpArdKU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TetrisPuzzle&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TetrisPuzzle/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TetrisPuzzle/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TetrisPuzzle/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tetrispuzzle200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tetrispuzzle500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tetrispuzzle800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tetrispuzzle1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tetrispuzzle1280720.webp"
    },
    {
    "name": "Mahjong Move & Match",
    "category": "Mahjong Slide",
    "create_date": "2024-06-25",
    "description": "Move and Match Mahjong tiles. You can combine 2 of the same adjacent tiles. You can move tiles over empty spaces in the same row or column.",
    "url": "https://cdn.htmlgames.com/MahjongMoveAndMatch/index.html",
    "youtube": "https://www.youtube.com/watch?v=tMDBa8XBaGE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongMoveAndMatch&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongMoveAndMatch/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongMoveAndMatch/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongMoveAndMatch/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongmove&match200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongmove&match500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongmove&match800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongmove&match1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongmove&match1280720.webp"
    },
    {
    "name": "Barcelona Hidden Objects",
    "category": "Hidden Object Games",
    "create_date": "2024-06-20",
    "description": "Find all the hidden objects in Barcelona. Click on all the hidden objects.",
    "url": "https://cdn.htmlgames.com/BarcelonaHiddenObjects/index.html",
    "youtube": "https://www.youtube.com/watch?v=DHxAQbAFiAw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BarcelonaHiddenObjects&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BarcelonaHiddenObjects/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BarcelonaHiddenObjects/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BarcelonaHiddenObjects/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/barcelonahiddenobjects200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/barcelonahiddenobjects500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/barcelonahiddenobjects800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/barcelonahiddenobjects1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/barcelonahiddenobjects1280720.webp"
    },
    {
    "name": "Puzzle Drop - Space Adventure",
    "category": "Puzzle Games",
    "create_date": "2024-06-19",
    "description": "Drop pieces to solve the Space Adventure puzzle. Drop the pieces on the right spot.",
    "url": "https://cdn.htmlgames.com/PuzzleDropSpaceAdventure/index.html",
    "youtube": "https://www.youtube.com/watch?v=ymFoKHPHl9k",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PuzzleDropSpaceAdventure&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PuzzleDropSpaceAdventure/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PuzzleDropSpaceAdventure/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PuzzleDropSpaceAdventure/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/puzzledropspaceadventure200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/puzzledropspaceadventure500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/puzzledropspaceadventure800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/puzzledropspaceadventure1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/puzzledropspaceadventure1280720.webp"
    },
    {
    "name": "Tap It Away 3D",
    "category": "Brain Games",
    "create_date": "2024-06-14",
    "description": "Tap away all blocks in 3D. Click/tap on a block and it will move in the direction of the arrow. You can rotate your view.",
    "url": "https://cdn.htmlgames.com/TapItAway3D/index.html",
    "youtube": "https://www.youtube.com/watch?v=DLkwfElay-U",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TapItAway3D&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TapItAway3D/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TapItAway3D/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TapItAway3D/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tapitaway3d200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tapitaway3d500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tapitaway3d800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tapitaway3d1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tapitaway3d1280720.webp"
    },
    {
    "name": "Zig Zag Gate",
    "category": "Skill",
    "create_date": "2024-06-10",
    "description": "Zig Zag through all gates. Click/tap to change the shape to get through the gate.",
    "url": "https://cdn.htmlgames.com/ZigZagGate/index.html",
    "youtube": "https://www.youtube.com/watch?v=7eGKHTekR7w",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ZigZagGate&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ZigZagGate/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ZigZagGate/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ZigZagGate/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/zigzaggate200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/zigzaggate500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/zigzaggate800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/zigzaggate1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/zigzaggate1280720.webp"
    },
    {
    "name": "Happy Bees",
    "category": "Match 3 Games",
    "create_date": "2024-06-06",
    "description": "Remove Bees by matching 3 or more of the same. Drag a row or column and connect the same bees. Reach the indicated numbers of bees to remove to advance to the next level.",
    "url": "https://cdn.htmlgames.com/HappyBees/index.html",
    "youtube": "https://www.youtube.com/watch?v=8ZfNswRK1PY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HappyBees&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HappyBees/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HappyBees/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HappyBees/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/happybees200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/happybees500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/happybees800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/happybees1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/happybees1280.webp"
    },
    {
    "name": "JapanJong",
    "category": "Mahjong Solitaire",
    "create_date": "2024-06-04",
    "description": "Play 80 levels of Japan Mahjong. Combine 2 of the same free tiles and remove all tiles.",
    "url": "https://cdn.htmlgames.com/JapanJong/index.html",
    "youtube": "https://www.youtube.com/watch?v=idy9MCKdKpo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JapanJong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JapanJong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JapanJong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JapanJong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/japanjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/japanjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/japanjong800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/japanjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/japanjong1280720.webp"
    },
    {
    "name": "Freecell Extreme",
    "category": "Freecell",
    "create_date": "2024-06-03",
    "description": "Freecell game, every level more difficult. Move all the cards to the four foundations from Ace to King. On the tableau build down in alternating color. You can temporarily place a card on a Free Cell.",
    "url": "https://cdn.htmlgames.com/FreecellExtreme/index.html",
    "youtube": "https://www.youtube.com/watch?v=IjAqF_QUEOA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FreecellExtreme&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FreecellExtreme/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FreecellExtreme/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FreecellExtreme/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/freecellextreme200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/freecell-extreme-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/freecell-extreme-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/freecell-extreme-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/freecell-extreme-1280.webp"
    },
    {
    "name": "Ninja Breakout",
    "category": "Arkanoid",
    "create_date": "2024-05-31",
    "description": "Breakout game with Ninja's. Try to shoot all bricks.",
    "url": "https://cdn.htmlgames.com/NinjaBreakout/",
    "youtube": "https://www.youtube.com/watch?v=r-xSAJ603Oo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=NinjaBreakout&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/NinjaBreakout/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/NinjaBreakout/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/NinjaBreakout/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/ninjabreakout200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/ninjabreakout500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/ninjabreakout800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/ninja-breakout-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/ninjabreakout1280720.webp"
    },
    {
    "name": "Bubble Throw",
    "category": "Bubble Shooter",
    "create_date": "2024-05-28",
    "description": "Throw bubbles and match 3 or more of the same of the same color. Reach the indicated goal.",
    "url": "https://cdn.htmlgames.com/BubbleThrow/index.html",
    "youtube": "https://www.youtube.com/watch?v=xO2Ga4HaUhU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BubbleThrow&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BubbleThrow/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BubbleThrow/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BubbleThrow/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bubblethrow200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bubblethrow500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bubblethrow800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/bubblethrow1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/bubblethrow1280720.webp"
    },
    {
    "name": "Circus Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2024-05-23",
    "description": "Play a game of Mahjong in the Circus. Remove all tiles in pairs of the same free tiles. Play via 'Select Layout' all layouts individually without time limit.",
    "url": "https://cdn.htmlgames.com/CircusMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=D5MG8cmher4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CircusMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CircusMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CircusMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CircusMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/circusmahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/circus-mahjong-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/circus-mahjong-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/circus-mahjong-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/circus-mahjong-1280.webp"
    },
    {
    "name": "Merge Cards",
    "category": "2048 & Merge",
    "create_date": "2024-05-22",
    "description": "Merge cards into higher values. Drag cards and merge 2 of the same value into a bigger value.",
    "url": "https://cdn.htmlgames.com/MergeCards/index.html",
    "youtube": "https://www.youtube.com/watch?v=lHdp8nOlJxo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MergeCards&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MergeCards/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MergeCards/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MergeCards/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mergecards200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mergecards500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mergecards800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mergecards1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mergecards1280720.webp"
    },
    {
    "name": "Zoo Collapse",
    "category": "Collapse Games",
    "create_date": "2024-05-20",
    "description": "Collapse the Zoo animals. Click on groups of 2 or more of the same animals and reach the goal.",
    "url": "https://cdn.htmlgames.com/ZooCollapse/index.html",
    "youtube": "https://www.youtube.com/watch?v=4KL2Re-UH50",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ZooCollapse&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ZooCollapse/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ZooCollapse/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ZooCollapse/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/zoocollapse200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/zoocollapse500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/zoocollapse800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/zoocollapse1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/zoocollapse1280720.webp"
    },
    {
    "name": "Farm Mysteries",
    "category": "Hidden Object Games",
    "create_date": "2024-05-17",
    "description": "Find all the hidden objects at the Farm. Click to find an object or difference.",
    "url": "https://cdn.htmlgames.com/FarmMysteries/index.html",
    "youtube": "https://www.youtube.com/watch?v=T-AOKMKj5NA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FarmMysteries&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FarmMysteries/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FarmMysteries/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FarmMysteries/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/farmmysteries200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/farmmysteries500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/farmmysteries800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/farmmysteries1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/farmmysteries1280720.webp"
    },
    {
    "name": "Traffic Control",
    "category": "Skill",
    "create_date": "2024-05-16",
    "description": "Control traffic safely. Click on traffic lights to operate them.",
    "url": "https://cdn.htmlgames.com/TrafficControl/index.html",
    "youtube": null,
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TrafficControl&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TrafficControl/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TrafficControl/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TrafficControl/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/trafficcontrol200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/trafficcontrol500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/trafficcontrol800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/trafficcontrol1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/trafficcontrol1280720.webp"
    },
    {
    "name": "Daily Queens",
    "category": "Daily Puzzles",
    "create_date": "2024-05-15",
    "description": "Play everday three new Queens puzzles. Place queens in such a way that there is only 1 queen per row and per column and per region. Queens cannot touch even not diagonally.",
    "url": "https://cdn.htmlgames.com/DailyQueens/index.html",
    "youtube": "https://www.youtube.com/watch?v=vuqpixm0VDM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyQueens&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyQueens/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyQueens/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyQueens/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/daily-queens-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/daily-queens-500x300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/daily-queens-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/daily-queens-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/daily-queens-1280.webp"
    },
    {
    "name": "Connect the Blocks",
    "category": "Mahjong Connect",
    "create_date": "2024-05-14",
    "description": "Connect the animals blocks. The connecting path cannot have more than two 90-degree turns. You can only connect free blocks in the same layer.",
    "url": "https://cdn.htmlgames.com/ConnectTheBlocks/index.html",
    "youtube": "https://www.youtube.com/watch?v=PNprba3xVUM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ConnectTheBlocks&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ConnectTheBlocks/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ConnectTheBlocks/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ConnectTheBlocks/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/connecttheblocks200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/connecttheblocks500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/connecttheblocks800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/connecttheblocks1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/connecttheblocks1280720.webp"
    },
    {
    "name": "Easter Island Solitaire",
    "category": "Tripeaks & Golf",
    "create_date": "2024-05-10",
    "description": "Play a Tri-peaks game on Easter Island. Try to remove all cards by selecting cards that are 1 higher or 1 lower in value than the open card.",
    "url": "https://cdn.htmlgames.com/EasterIslandSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=kFPHpPwDMIU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=EasterIslandSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/EasterIslandSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/EasterIslandSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/EasterIslandSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/easterislandsolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/easterislandsolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/easterislandsolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/easterislandsolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/easterislandsolitaire1280720.webp"
    },
    {
    "name": "Connect the Dots",
    "category": "Puzzle Games",
    "create_date": "2024-05-09",
    "description": "Connect all the Dots and use all the lines just once.",
    "url": "https://cdn.htmlgames.com/ConnectTheDots/index.html",
    "youtube": "https://www.youtube.com/watch?v=xOLcaoZqvJ0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ConnectTheDots&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ConnectTheDots/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ConnectTheDots/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ConnectTheDots/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/connectthedots200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/connectthedots500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/connectthedots800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/connectthedots1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/connectthedots1280720.webp"
    },
    {
    "name": "Harbour Escape",
    "category": "Brain Games",
    "create_date": "2024-04-26",
    "description": "Escape from the harbour. Use logic to get your red boat out of the harbor. Move boats so your red boat can reach the exit.",
    "url": "https://cdn.htmlgames.com/HarbourEscape/index.html",
    "youtube": "https://www.youtube.com/watch?v=O8ipt5uVqp4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HarbourEscape&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HarbourEscape/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HarbourEscape/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HarbourEscape/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/harbourescape200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/harbour-escape-500-300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/harbour-escape-800-450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/harbour-escape-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/harbour-escape-1280-720.webp"
    },
    {
    "name": "Picture Pie - Harbour City",
    "category": "Puzzle Games",
    "create_date": "2024-04-25",
    "description": "Play a picture pie puzzle in Harbour City. You can swap 2 adjacent parts. Try to complete the image.",
    "url": "https://cdn.htmlgames.com/PicturePieHarbourCity/index.html",
    "youtube": "https://www.youtube.com/watch?v=LJBHmPFwa8o",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PicturePieHarbourCity&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PicturePieHarbourCity/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PicturePieHarbourCity/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PicturePieHarbourCity/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/picturepieharbourcity200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/picturepieharbourcity500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/picturepieharbourcity800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/picturepieharbourcity1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/picturepieharbourcity1280720.webp"
    },
    {
    "name": "Goblin's Treasure Match",
    "category": "Bejeweled",
    "create_date": "2024-04-24",
    "description": "Fun match 3 game with Goblins. Swap 2 items to match 3 or more of the same in a row or column. Remove as many items as indicated.",
    "url": "https://cdn.htmlgames.com/GoblinsTreasureMatch/index.html",
    "youtube": "https://www.youtube.com/watch?v=qlCbJ6MjT4c",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=GoblinsTreasureMatch&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/GoblinsTreasureMatch/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/GoblinsTreasureMatch/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/GoblinsTreasureMatch/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/goblinstreasurematch200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/goblins-treasure-match-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/goblins-treasure-match-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/goblins-treasure-match-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/goblins-treasure-match-1280x720.webp"
    },
    {
    "name": "Black and White Mahjong 3",
    "category": "Mahjong Solitaire",
    "create_date": "2024-04-18",
    "description": "New Black and White Mahjong game. Combine a black tile and a white tile. You can only combine free tiles and tiles with the same symbols. Via level select you can play all 300 levels.",
    "url": "https://cdn.htmlgames.com/BlackAndWhiteMahjong3/index.html",
    "youtube": "https://www.youtube.com/watch?v=5Kv3_LOy4I8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BlackAndWhiteMahjong3&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BlackAndWhiteMahjong3/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BlackAndWhiteMahjong3/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BlackAndWhiteMahjong3/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/blackandwhitemahjong3200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/blackandwhitemahjong3500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/blackandwhitemahjong3800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/blackandwhitemahjong31024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/blackandwhitemahjong31280720.webp"
    },
    {
    "name": "Hawaiian Solitaire",
    "category": "Klondike",
    "create_date": "2024-04-17",
    "description": "Try to move all cards to the 4 foundations in Hawaii. On the tableau build down and alternating color. Move groups of cards regardless of sequence.",
    "url": "https://cdn.htmlgames.com/HawaiianSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=Df3hiiQuAEs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HawaiianSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HawaiianSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HawaiianSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HawaiianSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hawaiiansolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hawaiiansolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hawaiiansolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hawaiiansolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hawaiiansolitaire1280720.webp"
    },
    {
    "name": "Twenty One",
    "category": "Card Games",
    "create_date": "2024-04-08",
    "description": "Play Twenty One (a blackjack variant). Try to beat the dealer in 5 levels. A J=2, a Q=3 and a K=4 points.",
    "url": "https://cdn.htmlgames.com/TwentyOne/index.html",
    "youtube": "https://www.youtube.com/watch?v=KsinazQcaYM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TwentyOne&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TwentyOne/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TwentyOne/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TwentyOne/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/twentyone200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/twentyone500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/twentyone800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/twentyone1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/twentyone1280720.webp"
    },
    {
    "name": "Peg Solitaire",
    "category": "Brain Games",
    "create_date": "2024-04-08",
    "description": "Remove all the Pegs but one by jumping over Pegs.",
    "url": "https://cdn.htmlgames.com/PegSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=_S9BTwQZZMI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PegSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PegSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PegSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PegSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/pegsolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/pegsolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/pegsolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/pegsolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/pegsolitaire1280720.webp"
    },
    {
    "name": "Bubble Bubble",
    "category": "Bubble Shooter",
    "create_date": "2024-04-05",
    "description": "Enjoy this bubble bubble shooter game. Shoot up bubbles and match 3 or more of the same color. Try to remove all bubbles.",
    "url": "https://cdn.htmlgames.com/BubbleBubble/index.html",
    "youtube": "https://www.youtube.com/watch?v=853PjEeItC8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BubbleBubble&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BubbleBubble/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BubbleBubble/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BubbleBubble/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bubblebubble200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bubblebubble500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bubblebubble800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/bubblebubble1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/bubblebubble1280720.webp"
    },
    {
    "name": "Rome Hidden Objects",
    "category": "Hidden Object Games",
    "create_date": "2024-04-03",
    "description": "Find all the hidden objects in Rome. Click on all the hidden objects.",
    "url": "https://cdn.htmlgames.com/RomeHiddenObjects/index.html",
    "youtube": "https://www.youtube.com/watch?v=raQJ4lQAfuI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=RomeHiddenObjects&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/RomeHiddenObjects/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/RomeHiddenObjects/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/RomeHiddenObjects/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/romehiddenobjects200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/romehiddenobjects500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/romehiddenobjects800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/romehiddenobjects1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/romehiddenobjects1280720.webp"
    },
    {
    "name": "Shape Grid",
    "category": "Puzzles",
    "create_date": "2024-04-02",
    "description": "Move shapes on the grid. Groups of 4 or more of the same tiles are removed.",
    "url": "https://cdn.htmlgames.com/ShapeGrid/index.html",
    "youtube": "https://www.youtube.com/watch?v=isAo60-4jKs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ShapeGrid&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ShapeGrid/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ShapeGrid/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ShapeGrid/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/shapegrid200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/shapegrid500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/shapegrid800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/shape-grid-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/shapegrid1280720.webp"
    },
    {
    "name": "Music Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2024-04-01",
    "description": "A mahjong solitaire game full of music. Remove tiles in pairs of the same free tiles. Try to free the tiles with the music notes.",
    "url": "https://cdn.htmlgames.com/MusicMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=UR2_trOy_T8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MusicMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MusicMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MusicMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MusicMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/musicmahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/musicmahjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/musicmahjong800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/musicmahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/musicmahjong1280720.webp"
    },
    {
    "name": "Puppy Puzzle",
    "category": "Collapse Games",
    "create_date": "2024-03-28",
    "description": "Remove the puppies. Click on groups of 2 or more of the same puppies. Reach the indicated goal.",
    "url": "https://cdn.htmlgames.com/PuppyPuzzle/index.html",
    "youtube": "https://www.youtube.com/watch?v=cP146ef0dkM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PuppyPuzzle&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PuppyPuzzle/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PuppyPuzzle/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PuppyPuzzle/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/puppypuzzle200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/puppypuzzle500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/puppypuzzle800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/puppypuzzle1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/puppypuzzle1280720.webp"
    },
    {
    "name": "Easter Hex Puzzle",
    "category": "Puzzle Games",
    "create_date": "2024-03-21",
    "description": "Help the Easter Rabbit in this Hex Puzzle. Make sure the rabbit can reach the Easter egg by clicking on tiles. Click on a tile to activate or deactive it and its neighbours.",
    "url": "https://cdn.htmlgames.com/EasterHexPuzzle/index.html",
    "youtube": "https://www.youtube.com/watch?v=vVtr5YH2GNY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=EasterHexPuzzle&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/EasterHexPuzzle/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/EasterHexPuzzle/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/EasterHexPuzzle/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/easterhexpuzzle200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/easterhexpuzzle500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/easterhexpuzzle800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/easterhexpuzzle1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/easterhexpuzzle1280720.webp"
    },
    {
    "name": "Tetris 1024",
    "category": "2048 & Merge",
    "create_date": "2024-03-19",
    "description": "Drop down and merge numbers. Reach the indicated goal.",
    "url": "https://cdn.htmlgames.com/Tetris1024/index.html",
    "youtube": "https://www.youtube.com/watch?v=xbuNgR9dhAo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Tetris1024&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Tetris1024/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Tetris1024/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Tetris1024/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tetris1024200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tetris1024500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tetris1024800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tetris10241024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tetris10241280720.webp"
    },
    {
    "name": "Flower World 2",
    "category": "Match 3 Games",
    "create_date": "2024-03-13",
    "description": "New levels for Flower World: match 3 game. Swap 2 flowers and match 3 or more in a row. Reach the indicated goals.",
    "url": "https://cdn.htmlgames.com/FlowerWorld2/index.html",
    "youtube": "https://www.youtube.com/watch?v=OE2QgBxtvSI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FlowerWorld2&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FlowerWorld2/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FlowerWorld2/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FlowerWorld2/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/flowerworld2200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/flowerworld2-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/flowerworld2-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/flowerworld2-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/flowerworld2-1280x720.webp"
    },
    {
    "name": "Pyramid Tripeaks",
    "category": "Tripeaks & Golf",
    "create_date": "2024-03-12",
    "description": "Fun Tripeaks Solitaire game in Ancient Egypt. Remove all cards by playing cards that are 1 higher or 1 lower in value then the open card.",
    "url": "https://cdn.htmlgames.com/PyramidTripeaks/index.html",
    "youtube": "https://www.youtube.com/watch?v=bXrDhn7ERmg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PyramidTripeaks&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PyramidTripeaks/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PyramidTripeaks/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PyramidTripeaks/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/pyramidtripeaks200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/pyramidtripeaks500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/pyramidtripeaks800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/pyramidtripeaks1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/pyramidtripeaks1280720.webp"
    },
    {
    "name": "Dream Pet Solitaire",
    "category": "Mahjong Solitaire",
    "create_date": "2024-03-06",
    "description": "Play Solitaire Mahjong with the dream Pets. Combine 2 of the same pets to remove the pets.",
    "url": "https://cdn.htmlgames.com/DreamPetSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=XvnCWxwbtEw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DreamPetSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DreamPetSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DreamPetSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DreamPetSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dreampetsolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dreampetsolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dreampetsolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dreampetsolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dreampetsolitaire1280720.webp"
    },
    {
    "name": "Mystery House",
    "category": "Hidden Clues",
    "create_date": "2024-03-04",
    "description": "Enjoy the hidden object and mini games. Find the hidden objects and finish the mini games.",
    "url": "https://cdn.htmlgames.com/MysteryHouse/index.html",
    "youtube": "https://www.youtube.com/watch?v=VNmsBj3LDSQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MysteryHouse&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MysteryHouse/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MysteryHouse/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MysteryHouse/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mysteryhouse200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mysteryhouse500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mysteryhouse800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mysteryhouse1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mysteryhouse1280720.webp"
    },
    {
    "name": "The Brain Train",
    "category": "Brain Games",
    "create_date": "2024-02-28",
    "description": "Solve the train puzzles. Make sure the train can get out. Combine 3 of the same cars to remove them.",
    "url": "https://cdn.htmlgames.com/TheBrainTrain/index.html",
    "youtube": "https://www.youtube.com/watch?v=bH2S2I-eGuI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TheBrainTrain&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TheBrainTrain/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TheBrainTrain/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TheBrainTrain/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/thebraintrain200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/thebraintrain500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/thebraintrain800480.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/thebraintrain1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/thebraintrain1280720.webp"
    },
    {
    "name": "Montana Solitaire",
    "category": "Montana",
    "create_date": "2024-02-26",
    "description": "Arrange cards from 2 to King. Move a card to an empty spot that if the card on the left is of the same suit and is one lower in value.",
    "url": "https://cdn.htmlgames.com/MontanaSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=wknmRDb5Kzg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MontanaSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MontanaSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MontanaSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MontanaSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/montanasolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/montanasolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/montanasolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/montanasolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/montanasolitaire1280720.webp"
    },
    {
    "name": "Extreme Billiards",
    "category": "Zuma Games",
    "create_date": "2024-02-22",
    "description": "Remove all balls from the chain. Shoot balls in the chain and get 3 or more of the same connected balls.",
    "url": "https://cdn.htmlgames.com/ExtremeBilliards/index.html",
    "youtube": "https://www.youtube.com/watch?v=IMOLIg0pjJY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ExtremeBilliards&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ExtremeBilliards/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ExtremeBilliards/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ExtremeBilliards/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/extremebiliards200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/extremebiliards500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/extremebiliards800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/extremebilliards1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/extremebiliards1280720.webp"
    },
    {
    "name": "Fill the Maze",
    "category": "Maze Games",
    "create_date": "2024-02-20",
    "description": "Fill up the complete maze. Roll the ball through the maze.",
    "url": "https://cdn.htmlgames.com/FillTheMaze/index.html",
    "youtube": "https://www.youtube.com/watch?v=zR5IWMTA4F8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FillTheMaze&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FillTheMaze/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FillTheMaze/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FillTheMaze/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/fillthemaze200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/fillthemaze500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/fillthemaze800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/fillthemaze1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/fillthemaze1280720.webp"
    },
    {
    "name": "3D Sort",
    "category": "Sorting Games",
    "create_date": "2024-02-14",
    "description": "3D sort game. Sort all the items by combining 3 of the same.",
    "url": "https://cdn.htmlgames.com/3DSort/index.html",
    "youtube": "https://www.youtube.com/watch?v=EfFO1AEk5Yg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=3DSort&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/3DSort/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/3DSort/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/3DSort/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/3d-sort-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/3d-sort-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/3d-sort-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/3d-sort-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/3d-sort-1280.webp"
    },
    {
    "name": "Queen of Egypt - Cleopatra's Jewels",
    "category": "Bejeweled",
    "create_date": "2024-02-13",
    "description": "Jewel match3 game set in ancient Egypt. Swap 2 jewels to get 3 or more in a row.",
    "url": "https://cdn.htmlgames.com/QueenOfEgyptCleopatrasJewels/index.html",
    "youtube": "https://www.youtube.com/watch?v=Slj4SGDijbU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=QueenOfEgyptCleopatrasJewels&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/QueenOfEgyptCleopatrasJewels/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/QueenOfEgyptCleopatrasJewels/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/QueenOfEgyptCleopatrasJewels/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/queenofegyptcleopatrasjewels200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/queenofegyptcleopatrasjewels500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/queenofegyptcleopatrasjewels800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/queenofegyptcleopatrasjewels1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/queenofegyptcleopatrasjewels1280720.webp"
    },
    {
    "name": "Mahjong 3D Connect",
    "category": "3D Mahjong",
    "create_date": "2024-02-09",
    "description": "40 levels with 3D Mahjong Connect. Match 2 of the same cubes if you can connect them with a path that has no more then 2 turns of 90 degrees. You can rotate your view.",
    "url": "https://cdn.htmlgames.com/Mahjong3DConnect/index.html",
    "youtube": "https://www.youtube.com/watch?v=fAOCWuSUeW0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Mahjong3DConnect&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Mahjong3DConnect/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Mahjong3DConnect/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Mahjong3DConnect/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjong3dconnect200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjong3dconnect500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjong3dconnect800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjong3dconnect1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjong3dconnect1280720.webp"
    },
    {
    "name": "Pyramid Solitaire - Ancient Egypt",
    "category": "Pyramid",
    "create_date": "2024-02-07",
    "description": "A Pyramid Solitaire game in Ancient Eygpt. Remove all cards from the pyramid by combining 2 cards to a total value of 13.",
    "url": "https://cdn.htmlgames.com/PyramidSolitaireAncientEgypt/",
    "youtube": "https://www.youtube.com/watch?v=w72B9s_xISw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PyramidSolitaireAncientEgypt&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PyramidSolitaireAncientEgypt/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PyramidSolitaireAncientEgypt/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PyramidSolitaireAncientEgypt/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/pyramidsolitaireancientegypt200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/pyramidsolitaireancientegypt500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/pyramidsolitaireancientegypt800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/pyramidsolitaireancientegypt1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/pyramidsolitaireancientegypt1280720.webp"
    },
    {
    "name": "Water Pouring Puzzle",
    "category": "Puzzle Games",
    "create_date": "2024-02-06",
    "description": "Pour water and solve the puzzle. You must get the goal amount into one of the cups by pouring water over between cups.",
    "url": "https://cdn.htmlgames.com/WaterPouringPuzzle/index.html",
    "youtube": "https://www.youtube.com/watch?v=eQ27-ZkUnIY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=WaterPouringPuzzle&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/WaterPouringPuzzle/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/WaterPouringPuzzle/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/WaterPouringPuzzle/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/waterpouringpuzzle200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/waterpouringpuzzle500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/waterpouringpuzzle800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/waterpouringpuzzle1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/waterpouringpuzzle1280720.webp"
    },
    {
    "name": "Fantasy - Hidden Stars",
    "category": "Hidden Object Games",
    "create_date": "2024-02-02",
    "description": "Find all the hidden stars on the fantasy images. Click on the spot where you found a star.",
    "url": "https://cdn.htmlgames.com/FantasyHiddenStars/index.html",
    "youtube": "https://www.youtube.com/watch?v=HQFdtyko-JU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FantasyHiddenStars&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FantasyHiddenStars/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FantasyHiddenStars/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FantasyHiddenStars/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/fantasyhiddenstars200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/fantasyhiddenstars500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/fantasyhiddenstars800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/fantasyhiddenstars1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/fantasyhiddenstars1280720.webp"
    },
    {
    "name": "Archery Training",
    "category": "Shooting & War",
    "create_date": "2024-02-01",
    "description": "Train your shooting skills. Aim, shoot and hit the bulls eye.",
    "url": "https://cdn.htmlgames.com/ArcheryTraining/index.html",
    "youtube": "https://www.youtube.com/watch?v=p3Gp0BdJn0w",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ArcheryTraining&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ArcheryTraining/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ArcheryTraining/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ArcheryTraining/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/acherytraining200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/acherytraining500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/acherytraining800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/acherytraining1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/acherytraining1280720.webp"
    },
    {
    "name": "Solitaire Collection",
    "category": "Freecell",
    "create_date": "2024-01-23",
    "description": "A collection of Solitaire games. Each game has different rules (?).",
    "url": "https://cdn.htmlgames.com/SolitaireCollection/index.html",
    "youtube": "https://www.youtube.com/watch?v=YYhyHanaV_A",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SolitaireCollection&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SolitaireCollection/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SolitaireCollection/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SolitaireCollection/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/solitairecollection200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/solitairecollection500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/solitairecollection800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/solitairecollection1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/solitairecollection1280720.webp"
    },
    {
    "name": "2048 Billiards",
    "category": "2048 & Merge",
    "create_date": "2024-01-17",
    "description": "Fun mix of Billiards with 2048. Shoot and combine 2 of the same balls and reach the goal.",
    "url": "https://cdn.htmlgames.com/2048Billiards/index.html",
    "youtube": "https://www.youtube.com/watch?v=VZHdwMWAc_0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=2048Billiards&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/2048Billiards/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/2048Billiards/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/2048Billiards/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/2048billiards200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/2048billiards500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/2048billiards800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/2048billiards1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/2048billiards1280720.webp"
    },
    {
    "name": "Jewels Kyodai",
    "category": "Mahjong Solitaire",
    "create_date": "2024-01-15",
    "description": "Mahjong Solitaire with Jewels. Remove all tiles in pairs of the same free tiles.",
    "url": "https://cdn.htmlgames.com/JewelsKyodai/index.html",
    "youtube": "https://www.youtube.com/watch?v=is-tbz1xSCE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JewelsKyodai&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JewelsKyodai/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JewelsKyodai/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JewelsKyodai/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jewelskyodai200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jewelskyodai500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/jewelskyodai800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/jewelskyodai1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/jewelskyodai1280720.webp"
    },
    {
    "name": "Emoji Match 3",
    "category": "Match 3 Games",
    "create_date": "2024-01-12",
    "description": "Match 3 game with Emoji's. Swap 2 emoji's to get 3 or more on a row. Remove the special backgrounds.",
    "url": "https://cdn.htmlgames.com/EmojiMatch3/index.html",
    "youtube": "https://www.youtube.com/watch?v=euvJldQVk6Q",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=EmojiMatch3&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/EmojiMatch3/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/EmojiMatch3/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/EmojiMatch3/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/emojimatch3200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/emojimatch3500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/emojimatch3800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/emojimatch31024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/emojimatch31280720.webp"
    },
    {
    "name": "Pinball Breakout",
    "category": "Pinball",
    "create_date": "2024-01-10",
    "description": "What do you get if you combine Pinball and Breakout? Shoot all the bricks using the pinball flippers.",
    "url": "https://cdn.htmlgames.com/PinballBreakout/index.html",
    "youtube": "https://www.youtube.com/watch?v=G9bThUYIgt4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PinballBreakout&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PinballBreakout/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PinballBreakout/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PinballBreakout/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/pinballbreakout200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/pinballbreakout500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/pinballbreakout800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/pinballbreakout1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/pinballbreakout1280720.webp"
    },
    {
    "name": "Sort Bubbles",
    "category": "Sorting Games",
    "create_date": "2024-01-03",
    "description": "Sort the bubbles. Make sure each tube contains only one type of bubble.",
    "url": "https://cdn.htmlgames.com/SortBubbles/index.html",
    "youtube": "https://www.youtube.com/watch?v=OolLm9C5oyc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SortBubbles&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SortBubbles/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SortBubbles/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SortBubbles/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/sortbubbles200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/sortbubbles500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/sortbubbles800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/sortbubbles1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/sortbubbles1280720.webp"
    },
    {
    "name": "Bubble Buster",
    "category": "Bubble Shooter",
    "create_date": "2023-12-14",
    "description": "Enjoy this Bubble Shooter game. Shoot bubbles up and make groups of three or more same bubbles.",
    "url": "https://cdn.htmlgames.com/BubbleBuster/index.html",
    "youtube": "https://www.youtube.com/watch?v=4Gxbvg0TmYQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BubbleBuster&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BubbleBuster/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BubbleBuster/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BubbleBuster/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bubblebuster200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bubblebuster500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bubblebuster800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/bubblebuster1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/bubblebuster1280720.webp"
    },
    {
    "name": "Funfair Mysteries",
    "category": "Hidden Object Games",
    "create_date": "2023-12-13",
    "description": "Find all the hidden objects at the Funfair. Click to find an object or difference.",
    "url": "https://cdn.htmlgames.com/FunfairMysteries/index.html",
    "youtube": "https://www.youtube.com/watch?v=Rd8JDCVlY6k",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FunfairMysteries&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FunfairMysteries/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FunfairMysteries/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FunfairMysteries/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/funfairmysteries200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/funfairmysteries500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/funfairmysteries800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/funfairmysteries1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/funfairmysteries1280720.webp"
    },
    {
    "name": "Santa Tripeaks",
    "category": "Tripeaks & Golf",
    "create_date": "2023-12-12",
    "description": "Tripeaks Solitaire game for Christmas. Try to remove all cards by selecting cards that are 1 higher or lower in value than the open card at the bottom.",
    "url": "https://cdn.htmlgames.com/SantaTripeaks/index.html",
    "youtube": "https://www.youtube.com/watch?v=4aLWG1X3y00",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SantaTripeaks&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SantaTripeaks/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SantaTripeaks/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SantaTripeaks/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/santatripeaks200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/santatripeaks500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/santatripeaks800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/santatripeaks1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/santatripeaks1280720.webp"
    },
    {
    "name": "Christmas Merge",
    "category": "2048 & Merge",
    "create_date": "2023-12-07",
    "description": "Connect items for Christmas to merge into bigger items. Reach the indicated goal. Drag to connect the same Christmas items and to merge by 3 or more of the same.",
    "url": "https://cdn.htmlgames.com/ChristmasMerge/index.html",
    "youtube": "https://www.youtube.com/watch?v=L4Bq2a_3LBc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ChristmasMerge&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ChristmasMerge/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ChristmasMerge/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ChristmasMerge/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/christmasmerge200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/christmasmerge500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/christmasmerge800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/christmasmerge1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/christmasmerge1280720.webp"
    },
    {
    "name": "Christmas Dimensions",
    "category": "3D Mahjong",
    "create_date": "2023-12-06",
    "description": "Play a Xmas Mahjong Game in 3 Dimensions. Click on two of the same christmas blocks that have at least two adjacent free sides.",
    "url": "https://cdn.htmlgames.com/ChristmasDimensions/index.html",
    "youtube": "https://www.youtube.com/watch?v=8e2BmfGzluk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ChristmasDimensions&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ChristmasDimensions/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ChristmasDimensions/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ChristmasDimensions/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/christmas-dimensions-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/christmas-dimensions-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/christmas-dimensions-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/christmas-dimensions-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/christmas-dimensions-1280.webp"
    },
    {
    "name": "Daily Worms",
    "category": "Daily Puzzles",
    "create_date": "2023-11-30",
    "description": "Play the daily worms puzzle game. Connect all the dots with a path. Use all the cells and paths need to change direction after every cell.",
    "url": "https://cdn.htmlgames.com/DailyWorms/index.html",
    "youtube": null,
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyWorms&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyWorms/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyWorms/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyWorms/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/daily-worms-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/daily-worms-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/daily-worms-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/daily-worms-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/daily-worms-1280.webp"
    },
    {
    "name": "Speed Directions",
    "category": "Skill",
    "create_date": "2023-11-28",
    "description": "Complete the course. Click to change direction.",
    "url": "https://cdn.htmlgames.com/SpeedDirections/index.html",
    "youtube": "https://www.youtube.com/watch?v=o0qTvb3aURU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SpeedDirections&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SpeedDirections/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SpeedDirections/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SpeedDirections/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/speeddirections200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/speeddirections500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/speeddirections800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/speeddirections1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/speeddirections1280720.webp"
    },
    {
    "name": "Thieves of Egypt Solitaire",
    "category": "Klondike",
    "create_date": "2023-11-28",
    "description": "Move all cards to the 8 foundations from Ace to King. On the tableau you can place cards on other cards in descending order and alternating color. Click on the stack (top left) to get new open cards.",
    "url": "https://cdn.htmlgames.com/ThievesOfEgyptSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=h57YV54b0MM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ThievesOfEgyptSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ThievesOfEgyptSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ThievesOfEgyptSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ThievesOfEgyptSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/thievesofegyptsolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/thievesofegyptsolitaire-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/thievesofegyptsolitaire-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/thievesofegyptsolitaire-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/thievesofegyptsolitaire-1280.webp"
    },
    {
    "name": "Puzzle Drop - Egypt",
    "category": "Puzzle Games",
    "create_date": "2023-11-23",
    "description": "Drop pieces to solve the puzzle. Drop the pieces on the right spot.",
    "url": "https://cdn.htmlgames.com/PuzzleDropEgypt/index.html",
    "youtube": "https://www.youtube.com/watch?v=CSPKc_VYboc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PuzzleDropEgypt&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PuzzleDropEgypt/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PuzzleDropEgypt/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PuzzleDropEgypt/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/puzzledropegypt200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/puzzledropegypt500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/puzzledropegypt800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/puzzledropegypt1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/puzzledropegypt1280720.webp"
    },
    {
    "name": "Red and Green 2",
    "category": "Skill",
    "create_date": "2023-11-22",
    "description": "Shoot cannon balls and bump the creatures to their own sweets. Move to aim and click to shoot.",
    "url": "https://cdn.htmlgames.com/RedAndGreen2/index.html",
    "youtube": "https://www.youtube.com/watch?v=WAiml6iAPw8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=RedAndGreen2&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/RedAndGreen2/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/RedAndGreen2/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/RedAndGreen2/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/redandgreen2200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/redandgreen2500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/redandgreen2800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/redandgreen21024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/redandgreen21280720.webp"
    },
    {
    "name": "Bubble Trip",
    "category": "Bubble Shooter",
    "create_date": "2023-11-15",
    "description": "Remove all bubbles. Shoot bubbles up and match 3 or more of the same colored bubbles.",
    "url": "https://cdn.htmlgames.com/BubbleTrip/index.html",
    "youtube": "https://www.youtube.com/watch?v=myMcx_3OUjA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BubbleTrip&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BubbleTrip/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BubbleTrip/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BubbleTrip/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bubbletrip200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bubbletrip500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bubbletrip800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/bubbletrip1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/bubbletrip1280720.webp"
    },
    {
    "name": "Jungle Link",
    "category": "Mahjong Connect",
    "create_date": "2023-11-14",
    "description": "Remove all Jungle tiles. Connect two of the same tiles with a path that has no more than two 90 degree angles to remove the tiles.",
    "url": "https://cdn.htmlgames.com/JungleLink/index.html",
    "youtube": "https://www.youtube.com/watch?v=TlzT9f64y48",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JungleLink&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JungleLink/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JungleLink/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JungleLink/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jungle-link-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jungle-link-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/jungle-link-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/jungle-link-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/jungle-link-1280.webp"
    },
    {
    "name": "Rotate Puzzle - Cats and Dogs",
    "category": "Puzzle Games",
    "create_date": "2023-11-10",
    "description": "Rotate pieces and complete the puzzle. Click a piece to rotate it.",
    "url": "https://cdn.htmlgames.com/RotatePuzzleCatsAndDogs/",
    "youtube": "https://www.youtube.com/watch?v=2u6Lg-LZxAs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=RotatePuzzleCatsAndDogs&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/RotatePuzzleCatsAndDogs/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/RotatePuzzleCatsAndDogs/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/RotatePuzzleCatsAndDogs/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/rotatepuzzle-catsanddogs200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/rotatepuzzle-catsanddogs500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/rotatepuzzle-catsanddogs800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/rotatepuzzle-catsanddogs1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/rotatepuzzle-catsanddogs1280720.webp"
    },
    {
    "name": "Snake Dash",
    "category": "Maze Games",
    "create_date": "2023-11-07",
    "description": "Eat all the dots. Move around to eat the dots but do not bite your tail.",
    "url": "https://cdn.htmlgames.com/SnakeDash/index.html",
    "youtube": "https://www.youtube.com/watch?v=Qs7-kVIJmoI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SnakeDash&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SnakeDash/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SnakeDash/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SnakeDash/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/snakedash200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/snakedash500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/snakedash800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/snakedash1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/snakedash1280720.webp"
    },
    {
    "name": "Tokyo Hidden Objects",
    "category": "Hidden Object Games",
    "create_date": "2023-11-02",
    "description": "Find all the hidden objects in Tokyo. Click on all the hidden objects.",
    "url": "https://cdn.htmlgames.com/TokyoHiddenObjects/index.html",
    "youtube": "https://www.youtube.com/watch?v=soov6xnxXxA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TokyoHiddenObjects&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TokyoHiddenObjects/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TokyoHiddenObjects/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TokyoHiddenObjects/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tokyohiddenobjects200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tokyohiddenobjects500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tokyohiddenobjects800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tokyohiddenobjects1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tokyohiddenobjects1280720.webp"
    },
    {
    "name": "Remember the Bubbles",
    "category": "Memory",
    "create_date": "2023-10-31",
    "description": "Remember the color of the bubbles.  Recreate the colors on the bottom row.",
    "url": "https://cdn.htmlgames.com/RememberTheBubbles/index.html",
    "youtube": "https://www.youtube.com/watch?v=Bz9W9yOnVNo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=RememberTheBubbles&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/RememberTheBubbles/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/RememberTheBubbles/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/RememberTheBubbles/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/rememberbubbles200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/rememberbubbles500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/rememberbubbles800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/rememberbubbles1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/rememberbubbles1280720.webp"
    },
    {
    "name": "Tetrix",
    "category": "Tetris",
    "create_date": "2023-10-26",
    "description": "Enjoy this classic Tetris game. Drop down the tetris blocks and complete full horizontal lines.",
    "url": "https://cdn.htmlgames.com/Tetrix/index.html",
    "youtube": "https://www.youtube.com/watch?v=oSmQYgo5-Ro",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Tetrix&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Tetrix/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Tetrix/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Tetrix/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tetrix200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tetrix500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tetrix800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tetrix1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tetrix1280720.webp"
    },
    {
    "name": "Mahjong Solitaire",
    "category": "Mahjong Solitaire",
    "create_date": "2023-10-24",
    "description": "A Mahjong Solitaire game. Match 2 of the same free tiles to remove the tiles.",
    "url": "https://cdn.htmlgames.com/MahjongSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=c1VWBlGotUI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongsolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjong-solitaire-500x300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjong-solitaire-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongsolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjong-solitaire-1280x720.webp"
    },
    {
    "name": "Halloween Jewels",
    "category": "Bejeweled",
    "create_date": "2023-10-24",
    "description": "Halloween match 3 game with Jewels. Swap 2 jewels to match 3 or more.",
    "url": "https://cdn.htmlgames.com/HalloweenJewels/index.html",
    "youtube": "https://www.youtube.com/watch?v=fNHoVn9o0tQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HalloweenJewels&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HalloweenJewels/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HalloweenJewels/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HalloweenJewels/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/halloweenjewels200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/halloweenjewels500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/halloweenjewels800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/halloweenjewels1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/halloweenjewels1280720.webp"
    },
    {
    "name": "Spooky Dimensions",
    "category": "3D Mahjong",
    "create_date": "2023-10-17",
    "description": "Play a Spooky Mahjong Game in 3 Dimensions. Click on two of the same spooky blocks that have at least two adjacent free sides.",
    "url": "https://cdn.htmlgames.com/SpookyDimensions/index.html",
    "youtube": "https://www.youtube.com/watch?v=9t5OVEzUVBk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SpookyDimensions&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SpookyDimensions/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SpookyDimensions/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SpookyDimensions/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/spooky-dimensions-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/spooky-dimensions-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/spooky-dimensions-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/spooky-dimensions-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/spooky-dimensions-1280x720.webp"
    },
    {
    "name": "Three Blind Mice",
    "category": "Spider",
    "create_date": "2023-10-12",
    "description": "Group cards on the tableau down in suit from King to Ace. Groups of cards, regardless of sequence, can be moved if the top card of the group is 1 lower in value than the card you are moving the group to.",
    "url": "https://cdn.htmlgames.com/ThreeBlindMice/index.html",
    "youtube": "https://www.youtube.com/watch?v=lrK31G4MyAs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ThreeBlindMice&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ThreeBlindMice/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ThreeBlindMice/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ThreeBlindMice/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/threeblindmice200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/threeblindmice500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/threeblindmice800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/threeblindmice1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/threeblindmice1280720.webp"
    },
    {
    "name": "Halloween Card Connect",
    "category": "Card Games",
    "create_date": "2023-10-10",
    "description": "Connect the same cards for Halloween. The connecting path cannot have more than 2 turns of 90 degrees.",
    "url": "https://cdn.htmlgames.com/HalloweenCardConnect/index.html",
    "youtube": "https://www.youtube.com/watch?v=qk3LcJvG05I",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HalloweenCardConnect&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HalloweenCardConnect/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HalloweenCardConnect/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HalloweenCardConnect/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/halloweencardconnect200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/halloweencardconnect500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/halloweencardconnect800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/halloweencardconnect1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/halloweencardconnect1280720.webp"
    },
    {
    "name": "Find the Keys",
    "category": "Maze Games",
    "create_date": "2023-10-03",
    "description": "Find the keys to exit the maze. Move around to find all the keys.",
    "url": "https://cdn.htmlgames.com/FindTheKeys/index.html",
    "youtube": "https://www.youtube.com/watch?v=I0BiTjn_V-0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FindTheKeys&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FindTheKeys/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FindTheKeys/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FindTheKeys/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/findthekeys200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/findthekeys500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/findthekeys800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/findthekeys1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/findthekeys1280720.webp"
    },
    {
    "name": "Triple Tiles",
    "category": "Mahjong Games",
    "create_date": "2023-09-27",
    "description": "Combine three of the same tiles. Remove all tiles to advance to the next level.",
    "url": "https://cdn.htmlgames.com/TripleTiles/index.html",
    "youtube": "https://www.youtube.com/watch?v=WQxfwTppZgI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TripleTiles&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TripleTiles/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TripleTiles/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TripleTiles/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tripletiles200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tripletiles500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tripletiles800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tripletiles1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tripletiles1280720.webp"
    },
    {
    "name": "Chenonceau Hidden Objects",
    "category": "Hidden Object Games",
    "create_date": "2023-09-26",
    "description": "Find all objects in the Chenonceau Castle. Click on an object.",
    "url": "https://cdn.htmlgames.com/ChenonceauHiddenObjects/index.html",
    "youtube": "https://www.youtube.com/watch?v=Xp6zf1sbmKc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ChenonceauHiddenObjects&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ChenonceauHiddenObjects/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ChenonceauHiddenObjects/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ChenonceauHiddenObjects/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/chenonceauhiddenobjects200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/chenonceauhiddenobjects500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/chenonceauhiddenobjects800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/chenonceauhiddenobjects1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/chenonceauhiddenobjects1280720.webp"
    },
    {
    "name": "Jewel Blitz",
    "category": "Bejeweled",
    "create_date": "2023-09-22",
    "description": "Match 3 game with Blitz elements. Swap 2 elements to get 3 or more the same ones in a row. Collect time elements for more time.",
    "url": "https://cdn.htmlgames.com/JewelBlitz/index.html",
    "youtube": "https://www.youtube.com/watch?v=Zl52cbrftw8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JewelBlitz&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JewelBlitz/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JewelBlitz/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JewelBlitz/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jewelblitz200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jewelblitz500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/jewelblitz800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/jewelblitz1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/jewelblitz1280720.webp"
    },
    {
    "name": "Jolly Jong Birds",
    "category": "Mahjong Solitaire",
    "create_date": "2023-09-19",
    "description": "Fun Mahjong solitaire game with birds. Find 2 of the same bird tiles to remove the 2 tiles.",
    "url": "https://cdn.htmlgames.com/JollyJongBirds/index.html",
    "youtube": "https://www.youtube.com/watch?v=nQ9ASeMq44c",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JollyJongBirds&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JollyJongBirds/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JollyJongBirds/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JollyJongBirds/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jollyjongbirds200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jollyjongbirds500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/jollyjongbirds800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/jollyjongbirds1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/jollyjongbirds1280720.webp"
    },
    {
    "name": "Block Domino",
    "category": "Board",
    "create_date": "2023-09-14",
    "description": "Classic block domino game. Try to play your tiles on the table before your opponent does.",
    "url": "https://cdn.htmlgames.com/BlockDomino/index.html",
    "youtube": "https://www.youtube.com/watch?v=zjXArFmexIk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BlockDomino&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BlockDomino/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BlockDomino/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BlockDomino/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/blockdomino200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/blockdomino500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/blockdomino800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/blockdomino1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/blockdomino1280720.webp"
    },
    {
    "name": "Woggle",
    "category": "Word Games",
    "create_date": "2018-01-11",
    "description": "Connect letters and find words on the grid. Find the indicated word.",
    "url": "https://cdn.htmlgames.com/Woggle/index.html",
    "youtube": "https://www.youtube.com/watch?v=1kaQffl2C9A",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Woggle&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Woggle/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Woggle/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Woggle/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/woggle200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/woggle500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/woggle800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/woggle1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/woggle1280720.webp"
    },
    {
    "name": "Traffic Racer 2",
    "category": "Racing",
    "create_date": "2023-09-08",
    "description": "Race in busy traffic and collect bonusses. Use the arrow keys or the arrows to steer your car.",
    "url": "https://cdn.htmlgames.com/TrafficRacer2/index.html",
    "youtube": "https://www.youtube.com/watch?v=_d__rnvDw0g",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TrafficRacer2&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TrafficRacer2/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TrafficRacer2/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TrafficRacer2/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/trafficracer2200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/trafficracer2500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/trafficracer2800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/trafficracer21024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/trafficracer21280720.webp"
    },
    {
    "name": "Spike Solitaire",
    "category": "Klondike",
    "create_date": "2023-09-04",
    "description": "A Klondike solitaire game. Move all the cards to the 4 foundations from A to K in suit and ascending order. On the tableau you can build down on alternate color.",
    "url": "https://cdn.htmlgames.com/SpikeSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=6fe9Kb_cH8Y",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SpikeSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SpikeSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SpikeSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SpikeSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/spikesolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/spikesolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/spikesolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/spikesolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/spikesolitaire1280720.webp"
    },
    {
    "name": "Dream Pet Dimensions",
    "category": "3D Mahjong",
    "create_date": "2023-08-31",
    "description": "Play a Dream Pet Game in 3 Dimensions. Click on 2 of the same blocks that have at least 2 adjacent free sides.",
    "url": "https://cdn.htmlgames.com/DreamPetDimensions/index.html",
    "youtube": "https://www.youtube.com/watch?v=0Ua_n17X8G8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DreamPetDimensions&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DreamPetDimensions/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DreamPetDimensions/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DreamPetDimensions/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dream-pet-dimensions-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dream-pet-dimensions-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dream-pet-dimensions-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dream-pet-dimensions-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dream-pet-dimensions-1280.webp"
    },
    {
    "name": "Country Labyrinth 3",
    "category": "Maze Games",
    "create_date": "2023-08-30",
    "description": "Find your way in the Country Labyrinth (3). Make a path from the Flag to the Country.",
    "url": "https://cdn.htmlgames.com/CountryLabyrinth3/index.html",
    "youtube": "https://www.youtube.com/watch?v=SOvoHCKsAlE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CountryLabyrinth3&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CountryLabyrinth3/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CountryLabyrinth3/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CountryLabyrinth3/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/countrylabyrinth3200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/countrylabyrinth3500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/countrylabyrinth3800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/countrylabyrinth31024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/countrylabyrinth31280720.webp"
    },
    {
    "name": "Princess Jewels",
    "category": "Match 3 Games",
    "create_date": "2023-08-28",
    "description": "Match3 game with the Jewels of the Princess.  Drop blocks down and combine 3 same jewels horizontally, vertically or diagonally. Change the order of the jewels with the arrow keys.",
    "url": "https://cdn.htmlgames.com/PrincessJewels/",
    "youtube": "https://www.youtube.com/watch?v=8ZieeeouhwU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PrincessJewels&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PrincessJewels/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PrincessJewels/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PrincessJewels/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/princessjewels200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/princess-jewels-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/princess-jewels-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/princess-jewels-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/princess-jewels-1280.webp"
    },
    {
    "name": "Pirate Mysteries",
    "category": "Hidden Object Games",
    "create_date": "2023-08-24",
    "description": "Find all the hidden objects in this Pirate Mystery game. Click on found objects.",
    "url": "https://cdn.htmlgames.com/PirateMysteries/index.html",
    "youtube": "https://www.youtube.com/watch?v=WCv8j1lezKI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PirateMysteries&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PirateMysteries/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PirateMysteries/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PirateMysteries/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/piratemysteries200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/piratemysteries500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/piratemysteries800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/piratemysteries1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/piratemysteries1280720.webp"
    },
    {
    "name": "Neon Hex",
    "category": "Puzzle Games",
    "create_date": "2023-08-21",
    "description": "Fill the entire grid. Move pieces to the grid and fill it completely",
    "url": "https://cdn.htmlgames.com/NeonHex/index.html",
    "youtube": "https://www.youtube.com/watch?v=pPjEFTes6IQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=NeonHex&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/NeonHex/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/NeonHex/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/NeonHex/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/neonhex200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/neonhex500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/neonhex800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/neonhex1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/neonhex1280.webp"
    },
    {
    "name": "Circus Match 3",
    "category": "Match 3 Games",
    "create_date": "2023-08-17",
    "description": "Play a match 3 game in the Circus. Swap 2 items to get 3 or more in a row. Remove all colored backgrounds.",
    "url": "https://cdn.htmlgames.com/CircusMatch3/index.html",
    "youtube": "https://www.youtube.com/watch?v=8zzquR6pfgQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CircusMatch3&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CircusMatch3/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CircusMatch3/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CircusMatch3/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/circusmatch3200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/circusmatch3500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/circusmatch3800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/circusmatch31024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/circusmatch31280.webp"
    },
    {
    "name": "Olko 2",
    "category": "Mahjong Games",
    "create_date": "2023-08-15",
    "description": "Remove all tiles from the game. You can only reach the tiles via the sides.",
    "url": "https://cdn.htmlgames.com/Olko2/index.html",
    "youtube": "https://www.youtube.com/watch?v=fErWvh7feCA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Olko2&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Olko2/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Olko2/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Olko2/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/olko2200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/olko2500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/olko2800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/olko21024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/olko21280720.webp"
    },
    {
    "name": "Flower Merge",
    "category": "2048 & Merge",
    "create_date": "2023-08-14",
    "description": "Connect Flowers to merge into bigger Flowers. Reach the indicated goal. Drag to connect the same flowers and to merge by 3 or more of the same.",
    "url": "https://cdn.htmlgames.com/FlowerMerge/index.html",
    "youtube": "https://www.youtube.com/watch?v=_tb-bHbdevg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FlowerMerge&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FlowerMerge/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FlowerMerge/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FlowerMerge/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/flowermerge200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/flowermerge500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/flowermerge800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/flowermerge1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/flowermerge1280720.webp"
    },
    {
    "name": "Daily Tairupeinto",
    "category": "Daily Puzzles",
    "create_date": "2023-08-14",
    "description": "Everyday new Tairupeinto levels. Make regions on the grid black (or white). The numbers on the sides indicate how many cells are black in a row or column.",
    "url": "https://cdn.htmlgames.com/DailyTairupeinto/index.html",
    "youtube": "https://www.youtube.com/watch?v=YfeQWPF9Hr8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyTairupeinto&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyTairupeinto/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyTairupeinto/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyTairupeinto/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/daily-tairupeinto-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/daily-tairupeinto-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/daily-tairupeinto-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/daily-tairupeinto-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/daily-tairupeinto-1280.webp"
    },
    {
    "name": "Rotris",
    "category": "Tetris",
    "create_date": "2023-08-11",
    "description": "Play a rotating Tetris game. Drop down blocks and make squares of at least 3x3 to remove those squares.",
    "url": "https://cdn.htmlgames.com/Rotris/index.html",
    "youtube": "https://www.youtube.com/watch?v=lGBpfSouaIo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Rotris&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Rotris/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Rotris/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Rotris/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/rotris200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/rotris500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/rotris800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/rotris1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/rotris1280720.webp"
    },
    {
    "name": "Portuguese Solitaire",
    "category": "Solitaire Games",
    "create_date": "2023-08-08",
    "description": "Move all cards to the foundations from Ace to King. On the tableau build down regardless of suit.",
    "url": "https://cdn.htmlgames.com/PortugueseSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=m3owqGV7WQk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PortugueseSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PortugueseSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PortugueseSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PortugueseSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/portuguesesolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/portuguesesolitaire500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/portuguesesolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/portuguesesolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/portuguesesolitaire800480.webp"
    },
    {
    "name": "Endless Dimensions",
    "category": "3D Mahjong",
    "create_date": "2023-08-03",
    "description": "Play a Endless Mahjong Game in 3 Dimensions. Click on two of the same blocks that have at least two adjacent free sides.",
    "url": "https://cdn.htmlgames.com/EndlessDimensions/index.html",
    "youtube": "https://www.youtube.com/watch?v=AtD51DBjSUg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=EndlessDimensions&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/EndlessDimensions/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/EndlessDimensions/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/EndlessDimensions/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/endless-dimensions-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/endless-dimensions-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/endless-dimensions-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/endless-dimensions-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/endless-dimensions-1280.webp"
    },
    {
    "name": "Fruit Bubble",
    "category": "Bubble Shooter",
    "create_date": "2023-08-01",
    "description": "Bubble Shooter game with Fruit. Shoot up fruit and match 3 or more of the same fruit.",
    "url": "https://cdn.htmlgames.com/FruitBubble/index.html",
    "youtube": "https://www.youtube.com/watch?v=VbePOKWLXKw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FruitBubble&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FruitBubble/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FruitBubble/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FruitBubble/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/fruitbubble200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/fruitbubble500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/fruitbubble800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/fruitbubble1280.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/fruitbubble1280.webp"
    },
    {
    "name": "London Hidden Objects",
    "category": "Hidden Object Games",
    "create_date": "2023-07-06",
    "description": "Find all the hidden objects in London. Click on all the hidden objects.",
    "url": "https://cdn.htmlgames.com/LondonHiddenObjects/index.html",
    "youtube": "https://www.youtube.com/watch?v=wHrWKbxGcsc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=LondonHiddenObjects&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/LondonHiddenObjects/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/LondonHiddenObjects/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/LondonHiddenObjects/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/londonhiddenobjects200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/londonhiddenobjects500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/londonhiddenobjects800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/londonhiddenobjects1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/londonhiddenobjects1280720.webp"
    },
    {
    "name": "Airport Sniper",
    "category": "Shooting & War",
    "create_date": "2023-07-04",
    "description": "Shoot the targets at the airport. Click to zoom in and then aim and release.",
    "url": "https://cdn.htmlgames.com/AirportSniper/",
    "youtube": "https://www.youtube.com/watch?v=ZQlmxL7hn54",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=AirportSniper&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/AirportSniper/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/AirportSniper/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/AirportSniper/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/airportsniper200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/airportsniper500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/airportsniper800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/airportsniper1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/airportsniper1280720.webp"
    },
    {
    "name": "Squirrel Connection",
    "category": "Puzzle Games",
    "create_date": "2023-06-30",
    "description": "Connect tiles to match patterns.  You have to match the pattern of the tiles on the left side on the grid by connecting tiles on the grid.",
    "url": "https://cdn.htmlgames.com/SquirrelConnection/index.html",
    "youtube": "https://www.youtube.com/watch?v=-5F1pEDqeFk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SquirrelConnection&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SquirrelConnection/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SquirrelConnection/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SquirrelConnection/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/squirrelconnection200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/squirrelconnection500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/squirrelconnection800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/squirrelconnection1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/squirrelconnection1280720.webp"
    },
    {
    "name": "Paganini Solitaire",
    "category": "Montana",
    "create_date": "2023-06-22",
    "description": "Arrange all cards in color from Ace to King. Move a card to empty spots that are the same color and one higher in sequence or start with a A on the extreme left. After the Ace you have to place a 6. You have 3 shuffles.",
    "url": "https://cdn.htmlgames.com/PaganiniSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=sijZdfqo6No",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PaganiniSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PaganiniSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PaganiniSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PaganiniSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/paganinisolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/paganinisolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/paganinisolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/paganinisolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/paganinisolitaire1280720.webp"
    },
    {
    "name": "Jewel Classic",
    "category": "Bejeweled",
    "create_date": "2023-06-21",
    "description": "Swap 2 jewels and match 3 or more. Reach the goal.",
    "url": "https://cdn.htmlgames.com/JewelClassic/index.html",
    "youtube": "https://www.youtube.com/watch?v=PXUYwUfJ_nI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JewelClassic&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JewelClassic/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JewelClassic/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JewelClassic/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jewelclassic200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jewelclassic500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/jewelclassic800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/jewelclassic1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/jewelclassic1280720.webp"
    },
    {
    "name": "Tesla Defense",
    "category": "Tower Defense",
    "create_date": "2023-06-19",
    "description": "Defend your base. Shoot at all enemies. You can build defenses to help you.",
    "url": "https://cdn.htmlgames.com/TeslaDefense/index.html",
    "youtube": "https://www.youtube.com/watch?v=VKMmx97a3tA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TeslaDefense&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TeslaDefense/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TeslaDefense/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TeslaDefense/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tesladefense200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tesladefense500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tesladefense800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tesladefense1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tesladefense1280720.webp"
    },
    {
    "name": "Link Numbers",
    "category": "Puzzle Games",
    "create_date": "2023-06-13",
    "description": "Add numbers and link all numbers to one path.  Add a number in an empty cell, you can connect numbers horizontally, vertically and diagonally.",
    "url": "https://cdn.htmlgames.com/LinkNumbers/index.html",
    "youtube": "https://www.youtube.com/watch?v=HdeHK8ppF_k",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=LinkNumbers&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/LinkNumbers/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/LinkNumbers/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/LinkNumbers/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/linknumbers200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/linknumbers500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/linknumbers800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/linknumbers1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/linknumbers1280720.webp"
    },
    {
    "name": "Summer Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2023-06-12",
    "description": "A colorful mahjong solitaire game. Remove all tiles in pairs of the same free tiles.",
    "url": "https://cdn.htmlgames.com/SummerMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=UuKzKJkN8vw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SummerMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SummerMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SummerMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SummerMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/summermahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/summermahjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/summermahjong800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/summermahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/summermahjong1280720.webp"
    },
    {
    "name": "Restaurant Hidden Differences",
    "category": "Difference Games",
    "create_date": "2023-06-07",
    "description": "Find all the restaurant differcences. Click on a difference when found.",
    "url": "https://cdn.htmlgames.com/RestaurantHiddenDifferences/index.html",
    "youtube": "https://www.youtube.com/watch?v=IMOLmKgGUZM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=RestaurantHiddenDifferences&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/RestaurantHiddenDifferences/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/RestaurantHiddenDifferences/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/RestaurantHiddenDifferences/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/restauranthiddendifferences200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/restauranthiddendifferences500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/restauranthiddendifferences800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/restauranthiddendifferences1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/restauranthiddendifferences1280720.webp"
    },
    {
    "name": "Neon Breaker",
    "category": "Arkanoid",
    "create_date": "2023-06-01",
    "description": "Shoot all the bricks. Aim and release to shoot.",
    "url": "https://cdn.htmlgames.com/NeonBreaker/index.html",
    "youtube": "https://www.youtube.com/watch?v=G8-oKnK1Zgc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=NeonBreaker&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/NeonBreaker/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/NeonBreaker/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/NeonBreaker/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/neonbreaker200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/neonbreaker500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/neonbreaker800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/neonbreaker1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/neonbreaker1280720.webp"
    },
    {
    "name": "Tower of London Solitaire",
    "category": "Freecell",
    "create_date": "2023-05-30",
    "description": "Freecell variation. Move all the cards to the foundations from Ace to King up in suit. On the tableau build down by alternate color. Use a freecell to temporarily park a card.",
    "url": "https://cdn.htmlgames.com/TowerOfLondonSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=wA6cAqyXNHQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TowerOfLondonSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TowerOfLondonSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TowerOfLondonSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TowerOfLondonSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/toweroflondonsolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/toweroflondonsolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/toweroflondonsolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/toweroflondonsolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/toweroflondonsolitaire1280720.webp"
    },
    {
    "name": "Toy Factory",
    "category": "Collapse Games",
    "create_date": "2023-05-26",
    "description": "Collapse game in the Toy factory. Remove all blocks below the animals to finish a level.",
    "url": "https://cdn.htmlgames.com/ToyFactory/index.html",
    "youtube": "https://www.youtube.com/watch?v=vKHO4_mpM0U",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ToyFactory&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ToyFactory/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ToyFactory/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ToyFactory/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/toy-factory-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/toy-factory-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/toy-factory-800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/toy-factory-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/toy-factory-1280720.webp"
    },
    {
    "name": "Time Mahjong",
    "category": "Mahjong Games",
    "create_date": "2023-05-24",
    "description": "Mahjong game with special time tiles. Remove all tiles in pairs of the same free tiles. Remove two free time tiles for extra time.",
    "url": "https://cdn.htmlgames.com/TimeMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=ZSErzoDCyTs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TimeMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TimeMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TimeMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TimeMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/timemahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/timemahjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/timemahjong800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/timemahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/timemahjong1280720.webp"
    },
    {
    "name": "Mahjongg 3 Dimensions",
    "category": "3D Mahjong",
    "create_date": "2023-05-18",
    "description": "Play a Mahjong Game in 3 Dimensions. Click on 2 of the same blocks that have at least 2 adjacent free sides.",
    "url": "https://cdn.htmlgames.com/Mahjongg3Dimensions/index.html",
    "youtube": "https://www.youtube.com/watch?v=vHjJXWLObgw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Mahjongg3Dimensions&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Mahjongg3Dimensions/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Mahjongg3Dimensions/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Mahjongg3Dimensions/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongg-3-dimensions-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongg-3-dimensions-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongg-3-dimensions-800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongg-3-dimensions-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongg-3-dimensions-1280-720.webp"
    },
    {
    "name": "Neon Lights",
    "category": "Brain Games",
    "create_date": "2023-05-15",
    "description": "Connect all the lamps to the main Battery. Click on a wire to rotate it.",
    "url": "https://cdn.htmlgames.com/NeonLights/index.html",
    "youtube": "https://www.youtube.com/watch?v=sUWgdo7RQxc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=NeonLights&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/NeonLights/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/NeonLights/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/NeonLights/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/neonlights200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/neon-lights-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/neon-lights-800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/neon-lights-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/neon-lights-1280720.webp"
    },
    {
    "name": "Classic Bubble Shooter",
    "category": "Bubble Shooter",
    "create_date": "2023-05-09",
    "description": "Classic Bubble Shooter game. Shoot up bubbles and match 3 or more of the same color.",
    "url": "https://cdn.htmlgames.com/ClassicBubbleShooter/index.html",
    "youtube": "https://www.youtube.com/watch?v=dt6DlLmM2ps",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ClassicBubbleShooter&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ClassicBubbleShooter/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ClassicBubbleShooter/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ClassicBubbleShooter/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/classic-bubble-shooter-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/classicbubbleshooter500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/classic-bubble-shooter-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/classic-bubble-shooter-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/classic-bubble-shooter-1280720.webp"
    },
    {
    "name": "Classic Gin Rummy",
    "category": "Card Games",
    "create_date": "2023-05-08",
    "description": "Classic 2 Gin Rummy game. Try to win 10 levels.",
    "url": "https://cdn.htmlgames.com/ClassicGinRummy/index.html",
    "youtube": "https://www.youtube.com/watch?v=iawAgTgdakM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ClassicGinRummy&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ClassicGinRummy/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ClassicGinRummy/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ClassicGinRummy/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/classicginrummy200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/classicginrummy500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/classicginrummy800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/classicginrummy1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/classicginrummy1280720.webp"
    },
    {
    "name": "1001 Arabian Nights",
    "category": "Bejeweled",
    "create_date": "2023-05-05",
    "description": "Beautifull Match 3 game. Let the pieces of the item fall down and complete the item by matching 3 or more jewels in a row.",
    "url": "https://cdn.htmlgames.com/1001ArabianNights/index.html",
    "youtube": "https://www.youtube.com/watch?v=32Crai0MyZk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=1001ArabianNights&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/1001ArabianNights/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/1001ArabianNights/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/1001ArabianNights/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/1001-arabian-nights-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/1001-arabian-nights-500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/1001-arabian-nights-800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/1001-arabian-nights-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/1001arabiannights1280720.webp"
    },
    {
    "name": "Hunting Jack - At Home",
    "category": "Hidden Object Games",
    "create_date": "2023-05-01",
    "description": "Find all the hidden dogs in the house. Click/tap when you find a dog.",
    "url": "https://cdn.htmlgames.com/HuntingJackAtHome/index.html",
    "youtube": "https://www.youtube.com/watch?v=zb7yrawNmN4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HuntingJackAtHome&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HuntingJackAtHome/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HuntingJackAtHome/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HuntingJackAtHome/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/huntingjackathome500300.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/huntingjackinthecity500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/huntingjackathome800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/huntingjackathome1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/huntingjackathome1280720.webp"
    },
    {
    "name": "Aladdin and the Magic Lamp",
    "category": "Match 3 Games",
    "create_date": "2023-04-18",
    "description": "Collect the magic items. Let the items drop down to collect them. Click on a spot that when swapped with a neighbouring tile would match 3 or more in a row.",
    "url": "https://cdn.htmlgames.com/AladdinAndTheMagicLamp/index.html",
    "youtube": "https://www.youtube.com/watch?v=C0dQ1r7lQZg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=AladdinAndTheMagicLamp&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/AladdinAndTheMagicLamp/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/AladdinAndTheMagicLamp/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/AladdinAndTheMagicLamp/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/aladdinandthemagiclamp200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/aladdinandthemagiclamp500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/aladdinandthemagiclamp800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/aladdinandthemagiclamp1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/aladdinandthemagiclamp1280720.webp"
    },
    {
    "name": "Galaxy Shooter",
    "category": "Shooting & War",
    "create_date": "2023-04-17",
    "description": "Bescherm de aarde tegen de buitenaardse ruimteschepen. Tap/click to shoot.",
    "url": "https://cdn.htmlgames.com/GalaxyShooter/index.html",
    "youtube": "https://www.youtube.com/watch?v=8sOmiGALuQA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=GalaxyShooter&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/GalaxyShooter/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/GalaxyShooter/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/GalaxyShooter/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/galaxyshooter200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/galaxyshooter500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/galaxyshooter800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/galaxyshooter1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/galaxyshooter1280720.webp"
    },
    {
    "name": "Dream Pet Connect",
    "category": "Mahjong Connect",
    "create_date": "2023-04-14",
    "description": "Connect the same animals in this Dream Pet Link game. The connecting path cannot change directions more than 2x.",
    "url": "https://cdn.htmlgames.com/DreamPetConnect/index.html",
    "youtube": "https://www.youtube.com/watch?v=mhcQ2Nhh7ps",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DreamPetConnect&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DreamPetConnect/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DreamPetConnect/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DreamPetConnect/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dreampetconnect200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dreampetconnect500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dreampetconnect800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dreampetconnect1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dreampetconnect1280720.webp"
    },
    {
    "name": "Tri Towers Solitaire",
    "category": "Tripeaks & Golf",
    "create_date": "2023-04-12",
    "description": "Remove all cards and reveal the three towers. You can remove cards from the game by clicking on cards that are 1 higher or 1 lower in value than the open card at the bottom. Click on the closed stack to get a new open card.",
    "url": "https://cdn.htmlgames.com/TriTowersSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=4KcnaaiPZd8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TriTowersSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TriTowersSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TriTowersSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TriTowersSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tri-towers-solitaire-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tritowerssolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tritowerssolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tritowerssolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tritowerssolitaire1280720.webp"
    },
    {
    "name": "Easter Merge",
    "category": "2048 & Merge",
    "create_date": "2023-04-06",
    "description": "Connect items for easter to merge into bigger items. Reach the indicated goal. Drag to connect the same easter items and to merge by 3 or more of the same.",
    "url": "https://cdn.htmlgames.com/EasterMerge/index.html",
    "youtube": "https://www.youtube.com/watch?v=gcKCfIxZ9vo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=EasterMerge&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/EasterMerge/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/EasterMerge/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/EasterMerge/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/eastermerge200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/eastermerge500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/eastermerge800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/eastermerge1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/eastermerge1280720.webp"
    },
    {
    "name": "Mahjong Blocks - Easter",
    "category": "Mahjong Solitaire",
    "create_date": "2023-04-04",
    "description": "Remove all the Easter Mahjong Blocks. Click on two of the same blocks to remove the blocks.",
    "url": "https://cdn.htmlgames.com/MahjongBlocksEaster/index.html",
    "youtube": "https://www.youtube.com/watch?v=q0cO2Au40CY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongBlocksEaster&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongBlocksEaster/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongBlocksEaster/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongBlocksEaster/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongblockseaster200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongblockseaster500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongblockseaster800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongblockseaster1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongblockseaster1280720.webp"
    },
    {
    "name": "Hidden Spots - Birds",
    "category": "Hidden Object Games",
    "create_date": "2023-03-30",
    "description": "Find all the hidden spots on the Bird Photos. Click on the image where you have spotted a hidden spot.",
    "url": "https://cdn.htmlgames.com/HiddenSpotsBirds/index.html",
    "youtube": "https://www.youtube.com/watch?v=_SLlkp75YFg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HiddenSpotsBirds&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HiddenSpotsBirds/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HiddenSpotsBirds/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HiddenSpotsBirds/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hiddenspots-birds200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hiddenspots-birds500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hiddenspots-birds800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hiddenspots-birds1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hiddenspots-birds1280720.webp"
    },
    {
    "name": "Kill the Virus",
    "category": "Match 3 Games",
    "create_date": "2023-03-28",
    "description": "Kill the Viruses by matching viruses. Move a virus at the front of a row to another row and try to get 3 or more the same ones in a row.",
    "url": "https://cdn.htmlgames.com/KillTheVirus/index.html",
    "youtube": "https://www.youtube.com/watch?v=fduGXGN_yNE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=KillTheVirus&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/KillTheVirus/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/KillTheVirus/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/KillTheVirus/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/killthevirus200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/killthevirus500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/killthevirus800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/killthevirus1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/killthevirus1280720.webp"
    },
    {
    "name": "Solitaire Harvest",
    "category": "Tripeaks & Golf",
    "create_date": "2023-03-23",
    "description": "Remove all cards from this Golf Solitaire game. You can remove cards that are 1 higher or 1 lower in value than the open card. Click on the closed stack to get a new open card or use the jokers.",
    "url": "https://cdn.htmlgames.com/SolitaireHarvest/index.html",
    "youtube": "https://www.youtube.com/watch?v=vIq0DmdACk8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SolitaireHarvest&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SolitaireHarvest/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SolitaireHarvest/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SolitaireHarvest/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/solitaireharvest200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/solitaireharvest500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/solitaireharvest800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/solitaireharvest1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/solitaireharvest1280720.webp"
    },
    {
    "name": "All Threes Domino",
    "category": "Board",
    "create_date": "2023-03-20",
    "description": "Play All Threes Domino game with your partner. Drag tiles on the board, try to get multiples of 3 on the ends of the domino chain to score.",
    "url": "https://cdn.htmlgames.com/AllThreesDomino/index.html",
    "youtube": "https://www.youtube.com/watch?v=wPpmPzSEprw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=AllThreesDomino&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/AllThreesDomino/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/AllThreesDomino/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/AllThreesDomino/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/allthreesdomino200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/allthreesdomino500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/allthreesdomino800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/allthreesdomino1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/allthreesdomino1280720.webp"
    },
    {
    "name": "Slide Puzzle",
    "category": "Puzzle Games",
    "create_date": "2023-03-16",
    "description": "Solve the slide puzzle. Move all pieces to the correct spot.",
    "url": "https://cdn.htmlgames.com/SlidePuzzle/index.html",
    "youtube": "https://www.youtube.com/watch?v=yq4D7ldfrZ4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SlidePuzzle&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SlidePuzzle/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SlidePuzzle/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SlidePuzzle/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/slidepuzzle200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/slidepuzzle500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/slidepuzzle800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/slidepuzzle1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/slidepuzzle1280720.webp"
    },
    {
    "name": "Happy Bird",
    "category": "Skill",
    "create_date": "2023-03-16",
    "description": "Fly around and grab all items. Click on a rock and hold on to move up.",
    "url": "https://cdn.htmlgames.com/HappyBird/index.html",
    "youtube": "https://www.youtube.com/watch?v=ya8X8x7QL94",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HappyBird&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HappyBird/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HappyBird/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HappyBird/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/happybird200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/happybird500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/happybird800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/happybird1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/happybird1280720.webp"
    },
    {
    "name": "Daily Hitori",
    "category": "Daily Puzzles",
    "create_date": "2023-03-13",
    "description": "Solve 3 new Hitori puzzles every day. Rules: a number cannot appear more than once in each row or column, black cells cannot be horizontally or vertically adjacent to each other and the remaining cells form one area and are all connected horizontally or vertically.",
    "url": "https://cdn.htmlgames.com/DailyHitori/index.html",
    "youtube": "https://www.youtube.com/watch?v=ySRTL9AVJNE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyHitori&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyHitori/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyHitori/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyHitori/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/daily-hitori-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/daily-hitori-500x300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/daily-hitori-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/daily-hitori-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/daily-hitori-1280.webp"
    },
    {
    "name": "Prague Hidden Objects",
    "category": "Hidden Object Games",
    "create_date": "2023-03-09",
    "description": "Find all the hidden objects in Prague. Click on all the hidden objects.",
    "url": "https://cdn.htmlgames.com/PragueHiddenObjects/index.html",
    "youtube": "https://www.youtube.com/watch?v=h5eiDluu_w8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PragueHiddenObjects&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PragueHiddenObjects/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PragueHiddenObjects/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PragueHiddenObjects/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/praquehiddenobjects200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/praquehiddenobjects500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/praquehiddenobjects800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/praquehiddenobjects1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/praquehiddenobjects1280720.webp"
    },
    {
    "name": "Sensei Mahjongg",
    "category": "Mahjong Solitaire",
    "create_date": "2023-03-06",
    "description": "Traditional Mahjongg Solitaire game. Combine two of the same free tiles each time and clear the entire layout.",
    "url": "https://cdn.htmlgames.com/SenseiMahjongg/index.html",
    "youtube": "https://www.youtube.com/watch?v=kkwQ8ZZupqc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SenseiMahjongg&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SenseiMahjongg/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SenseiMahjongg/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SenseiMahjongg/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/senseimahjongg200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/senseimahjongg500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/senseimahjongg800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/senseimahjongg1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/senseimahjongg1280720.webp"
    },
    {
    "name": "Bubble Billiards",
    "category": "Bubble Shooter",
    "create_date": "2023-03-01",
    "description": "Bubble shooter game with Billiard balls. Shoot balls up and make groups of 3 or more same balls.",
    "url": "https://cdn.htmlgames.com/BubbleBilliards/index.html",
    "youtube": "https://www.youtube.com/watch?v=RxeiQsL8dZc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BubbleBilliards&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BubbleBilliards/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BubbleBilliards/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BubbleBilliards/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bubblebilliards200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bubblebilliards500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bubblebilliards800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/bubblebilliards1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/bubblebilliards1280720.webp"
    },
    {
    "name": "Skip Cards",
    "category": "Card Games",
    "create_date": "2023-02-27",
    "description": "A classic card game against a computer opponent. The object of this game is to get rid of all your cards by playing them on the centre stacks. Start with a 1 up to 12. The Skip card is wild.",
    "url": "https://cdn.htmlgames.com/SkipCards/index.html",
    "youtube": "https://www.youtube.com/watch?v=0LJP_XAIs1w",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SkipCards&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SkipCards/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SkipCards/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SkipCards/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/skipcards200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/skipcards500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/skipcards800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/skipcards1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/skipcards1280720.webp"
    },
    {
    "name": "SteamJong",
    "category": "Mahjong Solitaire",
    "create_date": "2023-02-23",
    "description": "Play a SteamPunk Mahjong game. Remove all tiles from a level by matching two of the same free tiles.",
    "url": "https://cdn.htmlgames.com/SteamJong/index.html",
    "youtube": "https://www.youtube.com/watch?v=e6kXb6McTt8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SteamJong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SteamJong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SteamJong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SteamJong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/steamjong-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/steamjong-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/steamjong-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/steamjong-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/steamjong-1280.webp"
    },
    {
    "name": "Animal Tetris",
    "category": "Tetris",
    "create_date": "2023-02-20",
    "description": "Tetris game with Animals. Drop down blocks and create complete horizontal lines with the animals.",
    "url": "https://cdn.htmlgames.com/AnimalTetris/index.html",
    "youtube": "https://www.youtube.com/watch?v=JpDWqqJwQIA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=AnimalTetris&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/AnimalTetris/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/AnimalTetris/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/AnimalTetris/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/animaltetris200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/animaltetris500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/animaltetris800480.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/animaltetris1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/animaltetris1280720.webp"
    },
    {
    "name": "10 to 20",
    "category": "Puzzle Games",
    "create_date": "2023-02-16",
    "description": "Combine the same nembers and reach the goal. Drag a number on the same number to get a number 1 higher in value.",
    "url": "https://cdn.htmlgames.com/10to20/index.html",
    "youtube": "https://www.youtube.com/watch?v=4TnTLu5p_WE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=10to20&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/10to20/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/10to20/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/10to20/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/10to20200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/10to20500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/10to20800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/10to201024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/10to201280720.webp"
    },
    {
    "name": "River Solitaire",
    "category": "Klondike",
    "create_date": "2023-02-13",
    "description": "A Klondike Solitaire variation. Move all cards to the four foundations from Ace to King. On the tableau build down in alternating color.",
    "url": "https://cdn.htmlgames.com/RiverSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=jkH8KQ3ss2E",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=RiverSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/RiverSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/RiverSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/RiverSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/riversolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/river-solitaire-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/river-solitaire-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/river-solitaire-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/river-solitaire-1280.webp"
    },
    {
    "name": "Zoo Mahjong",
    "category": "Mahjong Games",
    "create_date": "2023-02-09",
    "description": "Combine three of the same Mahjong tiles in Japan. Remove all stones to advance to the next level.",
    "url": "https://cdn.htmlgames.com/ZooMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=v4nmnv0_0ZA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ZooMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ZooMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ZooMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ZooMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/zoomahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/zoomahjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/zoomahjong800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/zoomahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/zoomahjong1280720.webp"
    },
    {
    "name": "Pyramid of Love",
    "category": "Pyramid",
    "create_date": "2023-02-08",
    "description": "A Pyramid Solitaire game for Valentine. Combine 2 free cards to a total value of 13. Value of the cards is the face value and a J=11, Q=12, A=1; the K=13 and can be removed as a single card.",
    "url": "https://cdn.htmlgames.com/PyramidOfLove/index.html",
    "youtube": "https://www.youtube.com/watch?v=3RTkLYvvFJA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PyramidOfLove&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PyramidOfLove/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PyramidOfLove/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PyramidOfLove/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/pyramidoflove200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/pyramidoflove500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/pyramidoflove800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/pyramidoflove1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/pyramidoflove1280720.webp"
    },
    {
    "name": "Medieval Merge",
    "category": "2048 & Merge",
    "create_date": "2023-02-07",
    "description": "Connect items to merge into bigger items. Reach the indicated goal. Drag to connect the same items and to merge by 3 or more of the same.",
    "url": "https://cdn.htmlgames.com/MedievalMerge/index.html",
    "youtube": "https://www.youtube.com/watch?v=s0eZHBPXPSg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MedievalMerge&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MedievalMerge/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MedievalMerge/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MedievalMerge/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/medievalmerge200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/medievalmerge500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/medievalmerge800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/medievalmerge1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/medievalmerge1280720.webp"
    },
    {
    "name": "Klootzakken",
    "category": "Card Games",
    "create_date": "2023-02-02",
    "description": "Play the Dutch card game with 4 players. Try to get rid of your cards by playing cards that are higher than the cards on the table. Cards rank from high to low: 3 2 A K Q J 10 9 8 7. You may pass, after 4 passes a new card may be lead.",
    "url": "https://cdn.htmlgames.com/Klootzakken/index.html",
    "youtube": "https://www.youtube.com/watch?v=VCfZ7TU3ByA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Klootzakken&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Klootzakken/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Klootzakken/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Klootzakken/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/klootzakken200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/klootzakken500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/klootzakken800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/klootzakken1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/klootzakken1280720.webp"
    },
    {
    "name": "President",
    "category": "Card Games",
    "create_date": "2023-02-02",
    "description": "Play the President card game with 4 players. Try to get rid of your cards by playing cards that are higher than the cards on the table. Cards rank from high to low: 2 A K Q J 10 9 8 7 6 5 4 3. You may pass, after 4 passes a new card may be lead.",
    "url": "https://cdn.htmlgames.com/President/index.html",
    "youtube": "https://www.youtube.com/watch?v=49gW1zVmZso",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=President&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/President/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/President/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/President/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/president200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/president500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/president800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/president1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/president1280720.webp"
    },
    {
    "name": "Pyramid Mahjong Solitaire",
    "category": "Mahjong Games",
    "create_date": "2023-01-31",
    "description": "A Pyramid Solitaire game with Mahjong Tiles. Choose 1 or 2 tiles up to a total value of 13. An A=1, a J=11, a Q=12 and a K is 13.",
    "url": "https://cdn.htmlgames.com/PyramidMahjongSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=gc7AKS5Ws0g",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PyramidMahjongSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PyramidMahjongSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PyramidMahjongSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PyramidMahjongSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/pyramidmahjongsolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/pyramidmahjongsolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/pyramidmahjongsolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/pyramidmahjongsolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/pyramidmahjongsolitaire1280720.webp"
    },
    {
    "name": "Egypt Collapse",
    "category": "Collapse Games",
    "create_date": "2023-01-30",
    "description": "Remove all tiles by clicking on groups of tiles. You can remove single tiles with a bomb, but be carefull: you only have a few.",
    "url": "https://cdn.htmlgames.com/EgyptCollapse/index.html",
    "youtube": "https://www.youtube.com/watch?v=Y5I7nTw3xQ8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=EgyptCollapse&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/EgyptCollapse/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/EgyptCollapse/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/EgyptCollapse/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/egyptcollapse200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/egyptcollapse500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/egyptcollapse800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/egyptcollapse1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/egyptcollapse1280720.webp"
    },
    {
    "name": "Archer",
    "category": "Shooting & War",
    "create_date": "2023-01-23",
    "description": "Shoot carefully and hit only the fruit. Click and drag to aim and release to shoot.",
    "url": "https://cdn.htmlgames.com/Archer/index.html",
    "youtube": "https://www.youtube.com/watch?v=Hz6lGfys_Oo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Archer&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Archer/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Archer/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Archer/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/archer200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/archer500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/archer800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/archer1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/archer1280720.webp"
    },
    {
    "name": "Jigsaw Jam World",
    "category": "Puzzle Games",
    "create_date": "2023-01-20",
    "description": "Complete the Jigsaw Puzzle and travel the World by dragging pieces to the correct spot.",
    "url": "https://cdn.htmlgames.com/JigsawJamWorld/index.html",
    "youtube": "https://www.youtube.com/watch?v=jJWnVBgN83o",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JigsawJamWorld&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JigsawJamWorld/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JigsawJamWorld/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JigsawJamWorld/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jigsawjamworld200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jigsawjamworld-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/jigsawjamworld-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/jigsawjamworld-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/jigsawjamworld-1280.webp"
    },
    {
    "name": "Glacier Solitaire",
    "category": "Pyramid",
    "create_date": "2023-01-16",
    "description": "Combine cards (or coins) to a total value of 11 to remove the cards (or coins) and built a Ice Pyramid. Click on 2 or more free cards/coins and reach 11 in value.",
    "url": "https://cdn.htmlgames.com/GlacierSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=N6BPxPVkKP4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=GlacierSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/GlacierSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/GlacierSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/GlacierSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/glaciersolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/glaciersolitaire500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/glacier-solitaire-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/glacier-solitaire-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/glacier-solitaire-1280x720.webp"
    },
    {
    "name": "Jewel Rush",
    "category": "Bejeweled",
    "create_date": "2023-01-12",
    "description": "Match 3 game with Jewels. Swap 2 jewels to match 3 or more.",
    "url": "https://cdn.htmlgames.com/JewelRush/index.html",
    "youtube": "https://www.youtube.com/watch?v=7Pfga8NOabg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JewelRush&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JewelRush/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JewelRush/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JewelRush/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jewelrush200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jewelrush500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/jewelrush800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/jewelrush1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/jewelrush1280720.webp"
    },
    {
    "name": "Tens",
    "category": "Math Games",
    "create_date": "2023-01-09",
    "description": "Add up numbers and reach 10. Move blocks onto the grid and reach 10 in a column or row.",
    "url": "https://cdn.htmlgames.com/Tens/index.html",
    "youtube": "https://www.youtube.com/watch?v=Ofxg2NxPwyc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Tens&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Tens/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Tens/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Tens/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tens200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tens500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tens800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tens1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tens1280720.webp"
    },
    {
    "name": "Reach 2048",
    "category": "2048 & Merge",
    "create_date": "2023-01-05",
    "description": "Move numbers and combine 4 or more of the same. Combined numbers grow. Reach the indicated numbers.",
    "url": "https://cdn.htmlgames.com/Reach2048/index.html",
    "youtube": "https://www.youtube.com/watch?v=UYNveAy_yPA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Reach2048&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Reach2048/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Reach2048/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Reach2048/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/reach2048-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/reach2048-500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/reach2048-800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/reach2048-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/reach2048-1280.webp"
    },
    {
    "name": "Kraken",
    "category": "Card Games",
    "create_date": "2023-01-03",
    "description": "Play the Dutch card game 'Kraken'. Kraken is a variant on the dutch game 'Klaverjassen'. Players can choose to double the game if they think a team cannot get halve of the points.",
    "url": "https://cdn.htmlgames.com/Kraken/",
    "youtube": "https://www.youtube.com/watch?v=5NcTgvc4xUQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Kraken&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Kraken/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Kraken/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Kraken/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/kraken-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/kraken-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/kraken-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/kraken-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/kraken-1280x720.webp"
    },
    {
    "name": "Hidden Spots - Christmas",
    "category": "Hidden Object Games",
    "create_date": "2022-12-20",
    "description": "Find all the hidden spots on the Christmas images. Click on the image where you have spotted a hidden spot.",
    "url": "https://cdn.htmlgames.com/HiddenSpotsChristmas/index.html",
    "youtube": "https://www.youtube.com/watch?v=Wds3L1YB7Qg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HiddenSpotsChristmas&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HiddenSpotsChristmas/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HiddenSpotsChristmas/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HiddenSpotsChristmas/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hiddenspotschristmas200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hiddenspotschristmas500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hiddenspotschristmas800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hiddenspotschristmas1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hiddenspotschristmas1280720.webp"
    },
    {
    "name": "Hex Link Christmas",
    "category": "Mahjong Connect",
    "create_date": "2022-12-19",
    "description": "Mahjong Hex Connect game for Christmas. Connect two identical tiles with a path with no more than 2 changes of direction.",
    "url": "https://cdn.htmlgames.com/HexLinkChristmas/index.html",
    "youtube": "https://www.youtube.com/watch?v=Mg7o6CJXe_U",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HexLinkChristmas&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HexLinkChristmas/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HexLinkChristmas/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HexLinkChristmas/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/merrychristmas200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/merrychristmas500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/merrychristmas800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/merrychristmas1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/merrychristmas1280720.webp"
    },
    {
    "name": "One Line",
    "category": "Puzzle Games",
    "create_date": "2022-12-14",
    "description": "Fill all cells with just one line. Move the snake to fill all cells.",
    "url": "https://cdn.htmlgames.com/OneLine/index.html",
    "youtube": "https://www.youtube.com/watch?v=G_wPpS6S16w",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=OneLine&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/OneLine/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/OneLine/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/OneLine/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/oneline200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/oneline500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/oneline800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/oneline1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/oneline1280.webp"
    },
    {
    "name": "Dunk Balls",
    "category": "Sports",
    "create_date": "2022-12-06",
    "description": "Try to dunk all balls. Move the paddle and get the balls in the basket.",
    "url": "https://cdn.htmlgames.com/DunkBalls/index.html",
    "youtube": null,
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DunkBalls&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DunkBalls/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DunkBalls/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DunkBalls/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dunkballs200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dunkballs500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dunkballs800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dunkballs1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dunkballs1280720.webp"
    },
    {
    "name": "Coloring Mandalas",
    "category": "Retro",
    "create_date": "2022-12-02",
    "description": "Coloring game with Mandalas. Select a mandala and start coloring. Save or print the result as an image.",
    "url": "https://cdn.htmlgames.com/ColoringMandalas/index.html",
    "youtube": "https://www.youtube.com/watch?v=fMgmfjpY16M",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ColoringMandalas&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ColoringMandalas/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ColoringMandalas/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ColoringMandalas/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/coloring-mandalas-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/coloring-mandalas-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/coloring-mandalas-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/coloring-mandalas-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/coloring-mandalas-1280.webp"
    },
    {
    "name": "Mahjong Triple Dimensions",
    "category": "3D Mahjong",
    "create_date": "2022-11-30",
    "description": "Triple 3D Mahjong. Match 3 of the same free cubes. Cubes are free if they have two free adjacent sides. You can rotate your view.",
    "url": "https://cdn.htmlgames.com/MahjongTripleDimensions/index.html",
    "youtube": "https://www.youtube.com/watch?v=yEP2ZcIdtTs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongTripleDimensions&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongTripleDimensions/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongTripleDimensions/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongTripleDimensions/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongtripledimensions-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongtripledimensions-500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongtripledimensions-800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongtripledimensions-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongtripledimensions-1280600.webp"
    },
    {
    "name": "Mahjongg China",
    "category": "Mahjong Solitaire",
    "create_date": "2022-11-28",
    "description": "Play the ancient china mahjongg game. To remove all tiles you need to combine 2 of the same free tiles.",
    "url": "https://cdn.htmlgames.com/MahjonggChina/index.html",
    "youtube": "https://www.youtube.com/watch?v=T3PK5sMk6yQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjonggChina&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjonggChina/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjonggChina/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjonggChina/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjonggchina200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjonggchina500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjonggchina800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjonggchina1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjonggchina1280.webp"
    },
    {
    "name": "Chinese Freecell",
    "category": "Freecell",
    "create_date": "2022-11-25",
    "description": "A Freecell game with only 3 suits. Move all cards to the foundations from A to K. On the tableau you can build down on any color except the same.",
    "url": "https://cdn.htmlgames.com/ChineseFreeCell/index.html",
    "youtube": "https://www.youtube.com/watch?v=ZnrQA-qOFNE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ChineseFreeCell&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ChineseFreeCell/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ChineseFreeCell/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ChineseFreeCell/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/chinesefreecell200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/chinesefreecell500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/chinesefreecell800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/chinesefreecell1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/chinesefreecell1280720.webp"
    },
    {
    "name": "Space Blast",
    "category": "Collapse Games",
    "create_date": "2022-11-21",
    "description": "Blast planets away in Space. Click on groups of 2 or more of the same planets. Reach the indicated goal.",
    "url": "https://cdn.htmlgames.com/SpaceBlast/index.html",
    "youtube": "https://www.youtube.com/watch?v=1BQL6cTlvSs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SpaceBlast&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SpaceBlast/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SpaceBlast/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SpaceBlast/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/space-blast-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/space-blast-500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/space-blast-800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/space-blast-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/space-blast-1280720.webp"
    },
    {
    "name": "Hidden Forest",
    "category": "Hidden Object Games",
    "create_date": "2022-11-17",
    "description": "Find all objects in the forest. Find the objects shown in the top bar.",
    "url": "https://cdn.htmlgames.com/HiddenForest/index.html",
    "youtube": "https://www.youtube.com/watch?v=H-EwXgw4EuE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HiddenForest&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HiddenForest/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HiddenForest/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HiddenForest/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hiddenforest200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hiddenforest500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hiddenforest800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hiddenforest1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hiddenforest1280720.webp"
    },
    {
    "name": "Neon Math",
    "category": "Math Games",
    "create_date": "2022-11-15",
    "description": "Drag numbers and reach 0.  Use the sums on the tiles.",
    "url": "https://cdn.htmlgames.com/NeonMath/index.html",
    "youtube": "https://www.youtube.com/watch?v=nuMD4bAskaw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=NeonMath&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/NeonMath/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/NeonMath/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/NeonMath/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/neonmath200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/neon-math-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/neon-math-800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/neon-math-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/neon-math-1280.webp"
    },
    {
    "name": "Klaverjassen",
    "category": "Card Games",
    "create_date": "2022-11-10",
    "description": "Play the Dutch game of Klaverjassen. Determine if you want to play 'Amsterdams' or 'Rotterdams' (2 well known variants of the game). After that determine how trump should be selected: Normal or 'Utrechts'.",
    "url": "https://cdn.htmlgames.com/Klaverjassen/index.html",
    "youtube": "https://www.youtube.com/watch?v=7pxUbd70r-k",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Klaverjassen&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Klaverjassen/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Klaverjassen/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Klaverjassen/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/klaverjassen200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/klaverjassen500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/klaverjassen800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/klaverjassen1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/klaverjassen1280.webp"
    },
    {
    "name": "Circus Pairs",
    "category": "Memory",
    "create_date": "2022-11-08",
    "description": "Find all pairs in this memory game. Click/tap on a tile to turn it. Find all pairs.",
    "url": "https://cdn.htmlgames.com/CircusPairs/index.html",
    "youtube": "https://www.youtube.com/watch?v=gGY3bS4v07s",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CircusPairs&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CircusPairs/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CircusPairs/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CircusPairs/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/circuspairs200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/circuspairs500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/circuspairs800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/circuspairs1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/circuspairs1280.webp"
    },
    {
    "name": "Dark Mahjong Connect",
    "category": "Mahjong Connect",
    "create_date": "2022-11-03",
    "description": "Mahjong Connect with a Dark  theme. Connect 2 of the same tiles and remove all tiles.",
    "url": "https://cdn.htmlgames.com/DarkMahjongConnect/index.html",
    "youtube": "https://www.youtube.com/watch?v=wzm79SEh0vE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DarkMahjongConnect&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DarkMahjongConnect/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DarkMahjongConnect/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DarkMahjongConnect/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/darkmahjongconnect200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/darkmahjongconnect500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/darkmahjongconnect800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/darkmahjongconnect1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/darkmahjongconnect1280720.webp"
    },
    {
    "name": "Egypt Runes",
    "category": "Collapse Games",
    "create_date": "2022-10-31",
    "description": "Click on groups of 3 or more of the same rune items to remove those. Remove all grey backgrounds and/or drop down the coins and reach the indicated goal.",
    "url": "https://cdn.htmlgames.com/EgyptRunes/index.html",
    "youtube": "https://www.youtube.com/watch?v=h31WJ3BvU9E",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=EgyptRunes&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/EgyptRunes/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/EgyptRunes/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/EgyptRunes/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/egyptrunes200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/egyptrunes500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/egyptrunes800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/egyptrunes1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/egyptrunes1280720.webp"
    },
    {
    "name": "Alien Pyramid Solitaire",
    "category": "Pyramid",
    "create_date": "2022-10-20",
    "description": "A Pyramid Solitaire game with Aliens. Combine 2 free cards to a total value of 13. Value of the cards is the face value and a J=11, Q=12, A=1; the K=13 and can be removed as a single card.",
    "url": "https://cdn.htmlgames.com/AlienPyramidSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=8diibtAgGZI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=AlienPyramidSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/AlienPyramidSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/AlienPyramidSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/AlienPyramidSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/alien-pyramid-solitaire-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/alien-pyramid-solitaire-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/alien-pyramid-solitaire-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/alien-pyramid-solitaire-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/alien-pyramid-solitaire-1280.webp"
    },
    {
    "name": "Carrom Pool",
    "category": "Billiards",
    "create_date": "2022-10-17",
    "description": "Disc Pool game against a computer opponent. Pocket all your discs in one of the four corners. If you pocked the red disc, you must pocked one of your discs in the next shot.",
    "url": "https://cdn.htmlgames.com/CarromPool/index.html",
    "youtube": "https://www.youtube.com/watch?v=tPO8otIb1DQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CarromPool&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CarromPool/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CarromPool/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CarromPool/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/carrompool200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/carrompool500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/carrompool800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/carrompool1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/carrompool1280720.webp"
    },
    {
    "name": "Mahjong - Butterfly Garden",
    "category": "Mahjong Games",
    "create_date": "2022-10-10",
    "description": "Play 5 different Mahjong games in 200 levels. Every level starts with an explanation how to remove all tiles.",
    "url": "https://cdn.htmlgames.com/MahjongButterflyGarden/index.html",
    "youtube": "https://www.youtube.com/watch?v=H2fS8vX48uc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongButterflyGarden&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongButterflyGarden/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongButterflyGarden/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongButterflyGarden/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongbutterflygarden200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongbutterflygarden500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongbutterflygarden800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongbutterflygarden1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongbutterflygarden1280720.webp"
    },
    {
    "name": "Cruise Ship Hidden Objects",
    "category": "Hidden Object Games",
    "create_date": "2022-10-06",
    "description": "Find all the hidden objects on the Cruise Ship. The map is a hint possibility.",
    "url": "https://cdn.htmlgames.com/CruiseShipHiddenObjects/",
    "youtube": "https://www.youtube.com/watch?v=5A4O--ZsYco",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CruiseShipHiddenObjects&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CruiseShipHiddenObjects/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CruiseShipHiddenObjects/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CruiseShipHiddenObjects/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/cruiseshiphiddenobjects200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/cruiseshiphiddenobjects500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/cruiseshiphiddenobjects800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/cruiseshiphiddenobjects1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/cruiseshiphiddenobjects1280720.webp"
    },
    {
    "name": "Draculi",
    "category": "Mahjong Games",
    "create_date": "2022-10-04",
    "description": "Grab the same tiles in this halloween game. Grab 2 of the same tiles each time that should be accessible from the sides.",
    "url": "https://cdn.htmlgames.com/Draculi/index.html",
    "youtube": "https://www.youtube.com/watch?v=IYpenOsVHBk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Draculi&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Draculi/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Draculi/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Draculi/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/draculi200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/draculi500300.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/draculi1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/draculi1280720.webp"
    },
    {
    "name": "BBQ Roast",
    "category": "Brain Games",
    "create_date": "2022-09-30",
    "description": "Roast and grow food on the BBQ. Combine 3 or more of the same food to let it grow.",
    "url": "https://cdn.htmlgames.com/BBQRoast/index.html",
    "youtube": "https://www.youtube.com/watch?v=lFW6w_ZuSck",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BBQRoast&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BBQRoast/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BBQRoast/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BBQRoast/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bbqroast200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bbqroast500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bbqroast800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/bbqroast1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/bbqroast1280720.webp"
    },
    {
    "name": "Scary Bubbles",
    "category": "Bubble Shooter",
    "create_date": "2022-09-29",
    "description": "Play 80 bubble shooter levels for Halloween. Shoot up bubbles and try to remove all by matching 3 or more of the same.",
    "url": "https://cdn.htmlgames.com/ScaryBubbles/index.html",
    "youtube": "https://www.youtube.com/watch?v=Ey8sW4JPrd4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ScaryBubbles&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ScaryBubbles/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ScaryBubbles/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ScaryBubbles/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/scarybubbles200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/scarybubbles500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/scarybubbles800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/scarybubbles1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/scarybubbles1280720.webp"
    },
    {
    "name": "House of Cards",
    "category": "Solitaire Games",
    "create_date": "2022-09-22",
    "description": "Try to build a house of cards. Make sure the foundations and lower layers are strong enough to support higher layers.  Walls are pairs of the same value or suit. Roofs can be any card. Walls of 20 and 21 in value have special capabilities.",
    "url": "https://cdn.htmlgames.com/HouseOfCards/index.html",
    "youtube": "https://www.youtube.com/watch?v=D11NWHJukDA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HouseOfCards&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HouseOfCards/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HouseOfCards/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HouseOfCards/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/houseofcards200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/houseofcards500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/houseofcards800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/houseofcards1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/houseofcards1280720.webp"
    },
    {
    "name": "Mahjong Collapse",
    "category": "Mahjong Games",
    "create_date": "2022-09-19",
    "description": "Click and collapse groups of mahjong tiles. Try to remove all tiles.",
    "url": "https://cdn.htmlgames.com/MahjongCollapse/index.html",
    "youtube": "https://www.youtube.com/watch?v=ZYe1na-VZDU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongCollapse&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongCollapse/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongCollapse/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongCollapse/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongcollapse200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongcollapse500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongcollapse800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongcollapse1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongcollapse1280720.webp"
    },
    {
    "name": "Forest Queen 2",
    "category": "Bejeweled",
    "create_date": "2022-09-16",
    "description": "Magical match3 game in the fores with all new levels. Remove all magical items from the game by matching 3 or more of the same jewels below the items.",
    "url": "https://cdn.htmlgames.com/ForestQueen2/index.html",
    "youtube": "https://www.youtube.com/watch?v=GnuCiTLxvj4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ForestQueen2&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ForestQueen2/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ForestQueen2/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ForestQueen2/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/forest-queen2-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/forest-queen2-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/forest-queen2-800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/forest-queen2-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/forest-queen2-800480.webp"
    },
    {
    "name": "Neon Arkanoid",
    "category": "Arkanoid",
    "create_date": "2022-09-12",
    "description": "Fun Arkanoid game in Neon Style. Use the paddle to bounce the ball and remove all neon bricks.",
    "url": "https://cdn.htmlgames.com/NeonArkanoid/",
    "youtube": "https://www.youtube.com/watch?v=GWvmcE8ndFo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=NeonArkanoid&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/NeonArkanoid/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/NeonArkanoid/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/NeonArkanoid/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/neonarkanoid200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/neonarkanoid500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/neonarkanoid800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/neonarkanoid1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/neonarkanoid1280720.webp"
    },
    {
    "name": "Soldier Attack 3",
    "category": "Shooting & War",
    "create_date": "2022-09-09",
    "description": "Extra levels: hit the aliens in as less shots as possible. Aim and click to shoot.",
    "url": "https://cdn.htmlgames.com/SoldierAttack3/index.html",
    "youtube": "https://www.youtube.com/watch?v=7WdXI2XntJY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SoldierAttack3&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SoldierAttack3/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SoldierAttack3/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SoldierAttack3/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/soldierattack3200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/soldierattack3500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/soldierattack3800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/soldierattack31024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/soldierattack31280720.webp"
    },
    {
    "name": "All Fives Domino",
    "category": "Board",
    "create_date": "2022-09-05",
    "description": "Play all Fives Domino game with your partner. Drag tiles on the board, try to get multiples of 5 on the ends of the domino chain to score.",
    "url": "https://cdn.htmlgames.com/AllFivesDomino/index.html",
    "youtube": "https://www.youtube.com/watch?v=R8Lw1OtQZBQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=AllFivesDomino&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/AllFivesDomino/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/AllFivesDomino/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/AllFivesDomino/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/allfivesdomino200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/allfivesdomino500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/allfivesdomino800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/allfivesdomino1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/allfivesdomino1280720.webp"
    },
    {
    "name": "Freecell",
    "category": "Freecell",
    "create_date": "2022-09-01",
    "description": "The classic Freecell game. Move all the cards to the foundations and use the Freecell rules.",
    "url": "https://cdn.htmlgames.com/Free-cell/index.html",
    "youtube": "https://www.youtube.com/watch?v=hiVrIAeGXMg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Free-cell&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Free-cell/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Free-cell/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Free-cell/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/free-cell-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/free-cell-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/free-cell-800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/free-cell-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/free-cell-1280.webp"
    },
    {
    "name": "Tägliches Lückenkreuzwort",
    "category": "Word Games",
    "create_date": "2018-11-29",
    "description": "Everday new letter logic puzzles in German. Fill in the given words on the grid using letters already given as hints. Click on a cell and then a word across or down. You cannot type letters. Use all words correctly to finish a puzzle.",
    "url": "https://cdn.htmlgames.com/TäglichesLückenkreuzwort/index.html",
    "youtube": "https://www.youtube.com/watch?v=VWkuw0wsSwI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TäglichesLückenkreuzwort&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TäglichesLückenkreuzwort/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TäglichesLückenkreuzwort/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TäglichesLückenkreuzwort/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/taegliches-luecken-kreuzwort-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/taegliches-luecken-kreuzwort-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/taegliches-luecken-kreuzwort-800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/taegliches-luecken-kreuzwort-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/taegliches-luecken-kreuzwort-1280.webp"
    },
    {
    "name": "Palabra de Lógico Del Dia",
    "category": "Word Games",
    "create_date": "2018-11-29",
    "description": "Everday new letter logic puzzles in Spanish. Fill in the given words on the grid using letters already given as hints. Click on a cell and then a word across or down. You cannot type letters. Use all words correctly to finish a puzzle.",
    "url": "https://cdn.htmlgames.com/PalabraDeLogicoDelDia/index.html",
    "youtube": "https://www.youtube.com/watch?v=VWkuw0wsSwI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PalabraDeLogicoDelDia&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PalabraDeLogicoDelDia/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PalabraDeLogicoDelDia/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PalabraDeLogicoDelDia/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/palabradelogicodeldia200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/palabradelogicodeldia500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/palabradelogicodeldia800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/palabradelogicodeldia1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/palabradelogicodeldia1280.webp"
    },
    {
    "name": "Dagelijkse Paspuzzel",
    "category": "Word Games",
    "create_date": "2018-11-29",
    "description": "Everday new letter logic puzzles in Dutch. Fill in the given words on the grid using letters already given as hints. Click on a cell and then a word across or down. You cannot type letters. Use all words correctly to finish a puzzle.",
    "url": "https://cdn.htmlgames.com/DagelijksePaspuzzel/index.html",
    "youtube": "https://www.youtube.com/watch?v=VWkuw0wsSwI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DagelijksePaspuzzel&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DagelijksePaspuzzel/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DagelijksePaspuzzel/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DagelijksePaspuzzel/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dagelijkse-paspuzzel-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dagelijkse-paspuzzel-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dagelijkse-paspuzzel-800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dagelijkse-paspuzzel-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dagelijkse-paspuzzel-1280.webp"
    },
    {
    "name": "Weave Lines",
    "category": "Puzzle Games",
    "create_date": "2022-08-29",
    "description": "Weave the lines in the correct way. The idea is to recreate the top image exactly.",
    "url": "https://cdn.htmlgames.com/WeaveLines/index.html",
    "youtube": "https://www.youtube.com/watch?v=rZswsx8B2gM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=WeaveLines&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/WeaveLines/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/WeaveLines/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/WeaveLines/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/weavelines200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/weavelines500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/weavelines800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/weavelines1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/weavelines1280720.webp"
    },
    {
    "name": "Waterfall - Hidden Stars",
    "category": "Hidden Object Games",
    "create_date": "2022-08-26",
    "description": "Find all the hidden stars on the waterfall images. Click on the spot where you found a star.",
    "url": "https://cdn.htmlgames.com/WaterfallHiddenStars/index.html",
    "youtube": "https://www.youtube.com/watch?v=KbOqft05cQI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=WaterfallHiddenStars&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/WaterfallHiddenStars/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/WaterfallHiddenStars/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/WaterfallHiddenStars/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/waterfallhiddenstars200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/waterfallhiddenstars500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/waterfallhiddenstars800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/waterfallhiddenstars1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/waterfallhiddenstars1280720.webp"
    },
    {
    "name": "Clear the Numbers",
    "category": "Mahjong Games",
    "create_date": "2022-08-15",
    "description": "Clear all the Numbers. Click to get a number on the bottom area and get 3 same numbers there and they are removed.",
    "url": "https://cdn.htmlgames.com/ClearTheNumbers/",
    "youtube": "https://www.youtube.com/watch?v=_Je_ALs7nq0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ClearTheNumbers&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ClearTheNumbers/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ClearTheNumbers/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ClearTheNumbers/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/clearthenumbers200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/clearthenumbers500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/clearthenumbers800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/clearthenumbers1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/clearthenumbers1280720.webp"
    },
    {
    "name": "Bubble Ball",
    "category": "Billiards",
    "create_date": "2022-08-11",
    "description": "Try to shoot all bubbles in the central hole. Drag the shooter bubble to shoot.",
    "url": "https://cdn.htmlgames.com/BubbleBall/index.html",
    "youtube": "https://www.youtube.com/watch?v=ZeW_Qxc9H9g",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BubbleBall&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BubbleBall/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BubbleBall/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BubbleBall/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bubbleball200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bubbleball500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bubbleball800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/bubbleball1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/bubbleball1280720.webp"
    },
    {
    "name": "Jewel Match3",
    "category": "Bejeweled",
    "create_date": "2022-08-08",
    "description": "Match3 game with Jewels. Swap 2 jewels to get 3 or more in a row.",
    "url": "https://cdn.htmlgames.com/JewelMatch3/",
    "youtube": "https://www.youtube.com/watch?v=3dwwgFTj-g8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JewelMatch3&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JewelMatch3/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JewelMatch3/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JewelMatch3/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jewelmatch3200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jewelmatch3500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/jewelmatch3800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/jewelmatch31024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/jewelmatch31280720.webp"
    },
    {
    "name": "City Fuse",
    "category": "2048 & Merge",
    "create_date": "2022-08-03",
    "description": "Drop and merge houses into a city. Click on empty spots to place the first house in the left column. 3 or more of the same connected houses will merge.",
    "url": "https://cdn.htmlgames.com/CityFuse/index.html",
    "youtube": "https://www.youtube.com/watch?v=XkqCSv17JR8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CityFuse&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CityFuse/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CityFuse/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CityFuse/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/cityfuse200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/cityfuse500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/cityfuse800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/cityfuse1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/cityfuse1280720.webp"
    },
    {
    "name": "Bingo",
    "category": "Board",
    "create_date": "2022-08-02",
    "description": "The ultimate Bingo game. Mark the balls on your bingo cards and click 'Bingo' when you have 5 in a row.",
    "url": "https://cdn.htmlgames.com/Bingo/index.html",
    "youtube": "https://www.youtube.com/watch?v=1P5fU6B01NM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Bingo&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Bingo/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Bingo/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Bingo/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bingo200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bingo500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bingo800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/bingo1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/bingo1280720.webp"
    },
    {
    "name": "Olko",
    "category": "Mahjong Games",
    "create_date": "2022-07-28",
    "description": "Remove all tiles from the game. You can only reach the tiles via the sides.",
    "url": "https://cdn.htmlgames.com/Olko/index.html",
    "youtube": "https://www.youtube.com/watch?v=T0twoQD__lo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Olko&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Olko/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Olko/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Olko/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/olko200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/olko500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/olko800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/olko1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/olko1280720.webp"
    },
    {
    "name": "Tripeaks Farm",
    "category": "Tripeaks & Golf",
    "create_date": "2022-07-26",
    "description": "Tripeaks Solitaire game on the farm. Remove all cards by selecting cards that are 1 higher or lower in value than the open card at the bottom.",
    "url": "https://cdn.htmlgames.com/TripeaksFarm/index.html",
    "youtube": "https://www.youtube.com/watch?v=kS6yA72KSl0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TripeaksFarm&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TripeaksFarm/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TripeaksFarm/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TripeaksFarm/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tripeaksfarm200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tripeaksfarm500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tripeaksfarm800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tripeaksfarm1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tripeaksfarm1280720.webp"
    },
    {
    "name": "Maya Golf 2",
    "category": "Golf",
    "create_date": "2022-07-08",
    "description": "New levels for the Maya Golf game. Try to putt the ball in as less shots as possible.",
    "url": "https://cdn.htmlgames.com/MayaGolf2/index.html",
    "youtube": "https://www.youtube.com/watch?v=FdzoNLlhqMc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MayaGolf2&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MayaGolf2/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MayaGolf2/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MayaGolf2/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mayagolf2200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mayagolf2500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mayagolf2800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mayagolf21024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mayagolf21280720.webp"
    },
    {
    "name": "Mahjong Blocks - Maya",
    "category": "Mahjong Solitaire",
    "create_date": "2022-07-04",
    "description": "Remove all the Maya Mahjong Blocks. Click on two of the same blocks to remove the blocks.",
    "url": "https://cdn.htmlgames.com/MahjongBlocksMaya/index.html",
    "youtube": "https://www.youtube.com/watch?v=oe-aqAHh1s8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongBlocksMaya&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongBlocksMaya/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongBlocksMaya/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongBlocksMaya/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongblocks200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongblocks500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongblocks800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongblocks1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongblocks1280720.webp"
    },
    {
    "name": "Castle Mysteries",
    "category": "Hidden Object Games",
    "create_date": "2022-07-01",
    "description": "Find all the hidden objects in the Castle. Click to find an object or differences.",
    "url": "https://cdn.htmlgames.com/CastleMysteries/index.html",
    "youtube": "https://www.youtube.com/watch?v=gc8CzC9iksM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CastleMysteries&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CastleMysteries/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CastleMysteries/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CastleMysteries/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/castlemysteries200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/castlemysteries500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/castlemysteries800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/castlemysteries1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/castlemysteries1280720.webp"
    },
    {
    "name": "Hashiwokakero",
    "category": "Puzzle Games",
    "create_date": "2022-06-27",
    "description": "Connect all the islands by bridges using the given numbers. Create as many bridges to/from an island as is indicated. Two islands can be connected with each other only vertically or horizontally and with no more then 2 bridges.",
    "url": "https://cdn.htmlgames.com/Hashiwokakero/index.html",
    "youtube": "https://www.youtube.com/watch?v=J_oEJCyHbgY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Hashiwokakero&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Hashiwokakero/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Hashiwokakero/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Hashiwokakero/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hashiwokakero200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hashiwokakero500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hashiwokakero800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hashiwokakero1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hashiwokakero1280.webp"
    },
    {
    "name": "Bubble Wheel",
    "category": "Bubble Shooter",
    "create_date": "2022-06-24",
    "description": "Shoot bubbles against the rotating wheel. If you create a group of 3 or more of the same bubbles, the bubbles will be removed, try to remove all bubbles.",
    "url": "https://cdn.htmlgames.com/BubbleWheel/index.html",
    "youtube": "https://www.youtube.com/watch?v=RavR0mnnOGQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BubbleWheel&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BubbleWheel/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BubbleWheel/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BubbleWheel/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bubblewheel200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bubblewheel500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bubblewheel800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/bubblewheel1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/bubblewheel1280720.webp"
    },
    {
    "name": "Ocean Puzzle",
    "category": "Match 3 Games",
    "create_date": "2022-06-21",
    "description": "Push ocean blocks inside and match 3 or more of the same to the delete the blocks and try to delete all ocean blocks.",
    "url": "https://cdn.htmlgames.com/OceanPuzzle/index.html",
    "youtube": "https://www.youtube.com/watch?v=1mRg2AW_wE4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=OceanPuzzle&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/OceanPuzzle/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/OceanPuzzle/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/OceanPuzzle/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/oceanpuzzle200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/oceanpuzzle500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/oceanpuzzle800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/oceanpuzzle1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/oceanpuzzle1280720.webp"
    },
    {
    "name": "Circus Solitaire",
    "category": "Pyramid",
    "create_date": "2022-06-16",
    "description": "A Pyramid Solitaire game in the Circus. Combine 2 free cards to a total value of 13. Value of the cards is the face value and a J=11, Q=12, A=1; the K=13 and can be removed as a single card.",
    "url": "https://cdn.htmlgames.com/CircusSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=5EnWSEnAKoE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CircusSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CircusSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CircusSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CircusSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/circussolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/circussolitaire500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/circussolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/circussolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/circussolitaire1280.webp"
    },
    {
    "name": "Sydney Hidden Objects",
    "category": "Hidden Object Games",
    "create_date": "2022-06-14",
    "description": "Find all the hidden objects in Sydney. Click on the objects. You can zoom in. Use the light bulb for a hint.",
    "url": "https://cdn.htmlgames.com/SydneyHiddenObjects/index.html",
    "youtube": "https://www.youtube.com/watch?v=dtEzT0UIJ4U",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SydneyHiddenObjects&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SydneyHiddenObjects/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SydneyHiddenObjects/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SydneyHiddenObjects/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/sydneyhiddenobjects200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/sydneyhiddenobjects500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/sydneyhiddenobjects800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/sydneyhiddenobjects1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/sydneyhiddenobjects1280720.webp"
    },
    {
    "name": "Neon Billiards",
    "category": "Billiards",
    "create_date": "2022-06-07",
    "description": "Single player Pool Billiards game. Pocket all the balls and the 8 ball as last ball. If you pocket the balls from low to high you get bonus points.",
    "url": "https://cdn.htmlgames.com/NeonBilliards/",
    "youtube": "https://www.youtube.com/watch?v=ie1Gin_p5FU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=NeonBilliards&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/NeonBilliards/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/NeonBilliards/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/NeonBilliards/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/neonbilliards200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/neonbilliards500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/neonbilliards800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/neonbilliards1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/neonbilliards1280720.webp"
    },
    {
    "name": "Hex Connect",
    "category": "Mahjong Connect",
    "create_date": "2022-06-03",
    "description": "Mahjong Connect game with Hex tiles. Connect two identical stones to a path with no more than 2 changes of direction.",
    "url": "https://cdn.htmlgames.com/HexConnect/index.html",
    "youtube": "https://www.youtube.com/watch?v=Mk_irH8H20Q",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HexConnect&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HexConnect/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HexConnect/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HexConnect/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hexconnect200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hexconnect500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hexconnect800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hexconnect1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hexconnect1280720.webp"
    },
    {
    "name": "Jewel Shop",
    "category": "Time management",
    "create_date": "2022-06-02",
    "description": "Run your flower shop and sell your flowers. Click on customers, deliver orders and order new jewels in time.",
    "url": "https://cdn.htmlgames.com/JewelShop/index.html",
    "youtube": "https://www.youtube.com/watch?v=sYQTxiCFjUE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JewelShop&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JewelShop/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JewelShop/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JewelShop/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jewelsshop200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jewelsshop500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/jewelsshop800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/jewelsshop1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/jewelsshop1280.webp"
    },
    {
    "name": "Chocolate Match",
    "category": "Match 3 Games",
    "create_date": "2022-05-30",
    "description": "Match 3 game with Chocolates. Swap 2 chocolates to get 3 or more of the same in a row. Remove all backgrounds.",
    "url": "https://cdn.htmlgames.com/ChocolateMatch/index.html",
    "youtube": "https://www.youtube.com/watch?v=Hg37Dxr8-M4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ChocolateMatch&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ChocolateMatch/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ChocolateMatch/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ChocolateMatch/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/chocolatematch200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/chocolatematch500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/chocolatematch800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/chocolatematch1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/chocolatematch1280.webp"
    },
    {
    "name": "Neon Ball",
    "category": "Billiards",
    "create_date": "2022-05-25",
    "description": "Shoot all the neon balls from your opponent off the table. Drag your balls to determine strengh and direction of your shot.",
    "url": "https://cdn.htmlgames.com/NeonBall/index.html",
    "youtube": "https://www.youtube.com/watch?v=eY6jvsy9Yhc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=NeonBall&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/NeonBall/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/NeonBall/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/NeonBall/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/neonball200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/neonball500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/neonball800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/neonball1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/neonball1280720.webp"
    },
    {
    "name": "Maze Roll",
    "category": "Maze Games",
    "create_date": "2022-05-23",
    "description": "Roll the ball through the maze and make sure you reach every square.",
    "url": "https://cdn.htmlgames.com/MazeRoll/index.html",
    "youtube": "https://www.youtube.com/watch?v=ASHEsF1rjNE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MazeRoll&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MazeRoll/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MazeRoll/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MazeRoll/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mazeroll200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mazeroll500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mazeroll800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mazeroll1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mazeroll1280720.webp"
    },
    {
    "name": "Tiles of Japan",
    "category": "Mahjong Games",
    "create_date": "2022-05-19",
    "description": "Combine three of the same Mahjong tiles in Japan. Remove all stones to advance to the next level.",
    "url": "https://cdn.htmlgames.com/TilesOfJapan/index.html",
    "youtube": "https://www.youtube.com/watch?v=XwsPRPSXsqU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TilesOfJapan&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TilesOfJapan/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TilesOfJapan/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TilesOfJapan/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tilesofjapan200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tilesofjapan500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tilesofjapan800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tilesofjapan1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tilesofjapan1280720.webp"
    },
    {
    "name": "Red and Green",
    "category": "Skill",
    "create_date": "2022-05-17",
    "description": "Shoot cannon balls and move the animals to their own sweets. Move to aim and click to shoot.",
    "url": "https://cdn.htmlgames.com/RedAndGreen/index.html",
    "youtube": "https://www.youtube.com/watch?v=aUS4LgOtb6Y",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=RedAndGreen&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/RedAndGreen/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/RedAndGreen/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/RedAndGreen/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/redandgreen200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/redandgreen500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/redandgreen800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/redandgreen1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/redandgreen1280720.webp"
    },
    {
    "name": "Space Bubbles",
    "category": "Bubble Shooter",
    "create_date": "2022-05-12",
    "description": "Shoot bubbles into space and match 3 or more of the same. Remove all bubbles to advance to the next level.",
    "url": "https://cdn.htmlgames.com/SpaceBubbles/",
    "youtube": "https://www.youtube.com/watch?v=Vw2Li99_rC8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SpaceBubbles&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SpaceBubbles/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SpaceBubbles/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SpaceBubbles/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/spacebubbles200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/spacebubbles500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/spacebubbles800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/spacebubbles1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/spacebubbles1280720.webp"
    },
    {
    "name": "Double Tower of Hanoi Solitaire",
    "category": "Card Games",
    "create_date": "2022-05-10",
    "description": "Build 2 towers in this Hanoi puzzle as card game. Move all cards in two columns in suit from 9 descending down to A. You can only move a card onto a higher ranked card or an empty column.",
    "url": "https://cdn.htmlgames.com/DoubleTowerOfHanoiSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=sJHptw6yzUE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DoubleTowerOfHanoiSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DoubleTowerOfHanoiSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DoubleTowerOfHanoiSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DoubleTowerOfHanoiSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/TowerofHanoiSolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/doubletowerofhanoisolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/doubletowerofhanoisolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/doubletowerofhanoisolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/doubletowerofhanoisolitaire1280720.webp"
    },
    {
    "name": "Ruins - Hidden Stars",
    "category": "Hidden Object Games",
    "create_date": "2022-05-06",
    "description": "Find all the hidden stars on the ruin images. Click on the spot where you found a star.",
    "url": "https://cdn.htmlgames.com/RuinsHiddenStars/index.html",
    "youtube": "https://www.youtube.com/watch?v=MGTfg3ippZU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=RuinsHiddenStars&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/RuinsHiddenStars/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/RuinsHiddenStars/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/RuinsHiddenStars/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/ruins-hidden-stars-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/ruins-hidden-stars-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/ruins-hidden-stars-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/ruinshiddenstars1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/ruins-hidden-stars-1280.webp"
    },
    {
    "name": "Shape Mahjong",
    "category": "Mahjong Games",
    "create_date": "2022-04-21",
    "description": "Mahjong Solitaire game with Shapes. Select different free tiles belonging to one shape. A tile is free if not covered and if it has at least 1 free side (left or right).",
    "url": "https://cdn.htmlgames.com/ShapeMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=JqPQejQDnz4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ShapeMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ShapeMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ShapeMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ShapeMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/shapesmahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/shapesmahjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/shapesmahjong800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/shapesmahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/shapesmahjong1280720.webp"
    },
    {
    "name": "Aerobatics",
    "category": "Skill",
    "create_date": "2022-04-21",
    "description": "Perform Aerobatic stunts. Use the arrows on screen or the arrow keys.",
    "url": "https://cdn.htmlgames.com/Aerobatics/index.html",
    "youtube": "https://www.youtube.com/watch?v=lzhvt_QF2go",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Aerobatics&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Aerobatics/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Aerobatics/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Aerobatics/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/aerobatics200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/aerobatics500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/aerobatics800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/aerobatics1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/aerobatics1280720.webp"
    },
    {
    "name": "Alhambra Solitaire",
    "category": "Card Games",
    "create_date": "2022-04-14",
    "description": "Move all cards to the foundations in Alhambra, the 4 left foundations up in suit and the 4 right foundations down in suit. You can only move cards from the tableau to the foundations or the open card at the bottom. Click on the stack to get a new open card.",
    "url": "https://cdn.htmlgames.com/AlhambraSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=w6thTDqiADw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=AlhambraSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/AlhambraSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/AlhambraSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/AlhambraSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/alhambrasolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/alhambrasolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/alhambrasolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/alhambrasolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/alhambrasolitaire1280720.webp"
    },
    {
    "name": "Triple Connect",
    "category": "Mahjong Connect",
    "create_date": "2022-04-12",
    "description": "Connect 3 of the same tiles and remove all tiles. There must be a possible path between tiles to be able to connect.",
    "url": "https://cdn.htmlgames.com/TripleConnect/index.html",
    "youtube": "https://www.youtube.com/watch?v=2ijQF44xVMs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TripleConnect&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TripleConnect/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TripleConnect/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TripleConnect/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tripleconnect200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tripleconnect500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tripleconnect800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tripleconnect1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tripleconnect1280720.webp"
    },
    {
    "name": "Blind Freecell",
    "category": "Freecell",
    "create_date": "2022-04-07",
    "description": "FreeCell game with non open cards. Move all the cards to the four foundations from Ace to King. On the tableau build down in alternating color. You can temporarily place a card on a Free Cell.",
    "url": "https://cdn.htmlgames.com/BlindFreecell/index.html",
    "youtube": "https://www.youtube.com/watch?v=h0XyDcmVBA0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BlindFreecell&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BlindFreecell/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BlindFreecell/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BlindFreecell/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/blindfreecell200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/blindfreecell500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/blindfreecell800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/blindfreecell1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/blindfreecell1280720.webp"
    },
    {
    "name": "Nonogram",
    "category": "Puzzle Games",
    "create_date": "2022-04-01",
    "description": "Play nonogram puzzles in different sizes. Use the hints in the rows and columns to solve the puzzle.",
    "url": "https://cdn.htmlgames.com/Nonogram/index.html",
    "youtube": "https://www.youtube.com/watch?v=mkHkXq83Xj0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Nonogram&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Nonogram/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Nonogram/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Nonogram/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/nonogram200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/nonogram500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/nonogram800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/nonogram1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/nonogram1280.webp"
    },
    {
    "name": "Medieval Castle Hidden Letters",
    "category": "Hidden Alphabet",
    "create_date": "2022-03-31",
    "description": "Find all the hidden Letters. Click on a letter when you have found it.",
    "url": "https://cdn.htmlgames.com/MedievalCastleHiddenLetters/index.html",
    "youtube": "https://www.youtube.com/watch?v=SsNxJ3lMf8M",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MedievalCastleHiddenLetters&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MedievalCastleHiddenLetters/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MedievalCastleHiddenLetters/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MedievalCastleHiddenLetters/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/medievalcastlehiddenletters200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/medievalcastlehiddenletters500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/medievalcastlehiddenletters800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/medievalcastlehiddenletters1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/medievalcastlehiddenletters1280.webp"
    },
    {
    "name": "Balloon Pop",
    "category": "Collapse Games",
    "create_date": "2022-03-24",
    "description": "Pop balloons and collect gifts. Click on groups of 2 or more of the same balloons to pop the balloons.",
    "url": "https://cdn.htmlgames.com/BalloonPop/index.html",
    "youtube": "https://www.youtube.com/watch?v=xq1le7if3fI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BalloonPop&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BalloonPop/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BalloonPop/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BalloonPop/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/balloonPop200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/balloonPop500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/balloonPop800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/balloonPop1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/balloonPop1280720.webp"
    },
    {
    "name": "Easter Puzzle",
    "category": "Puzzle Games",
    "create_date": "2022-03-21",
    "description": "Push down all the squares in this Easter Puzzle game. Click/tap on adjacent squares.",
    "url": "https://cdn.htmlgames.com/EasterPuzzle/index.html",
    "youtube": "https://www.youtube.com/watch?v=UBz6BUT1Qnk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=EasterPuzzle&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/EasterPuzzle/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/EasterPuzzle/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/EasterPuzzle/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/easterpuzzle200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/easterpuzzle500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/easterpuzzle800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/easterpuzzle1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/easterpuzzle1280720.webp"
    },
    {
    "name": "Bunny Solitaire",
    "category": "Tripeaks & Golf",
    "create_date": "2022-03-17",
    "description": "Tripeaks Solitaire game for Easter. Try to remove all cards by selecting cards that are 1 higher or lower in value than the open card at the bottom.",
    "url": "https://cdn.htmlgames.com/BunnySolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=Vr4a3yom7TY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BunnySolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BunnySolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BunnySolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BunnySolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bunnysolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bunnysolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bunnysolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/bunnysolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/bunnysolitaire1280720.webp"
    },
    {
    "name": "DropTris",
    "category": "Tetris",
    "create_date": "2022-03-14",
    "description": "Drop down blocks in this Tetris game. Remove full lines and reach the indicated goal.",
    "url": "https://cdn.htmlgames.com/DropTris/index.html",
    "youtube": "https://www.youtube.com/watch?v=r-68j5_7AYs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DropTris&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DropTris/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DropTris/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DropTris/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/droptris200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/droptris500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/droptris800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/droptris1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/droptris1280720.webp"
    },
    {
    "name": "Hunting Jack - In The City",
    "category": "Hidden Object Games",
    "create_date": "2022-03-10",
    "description": "Find all the hidden Dogs. Click/tap when you find a dog.",
    "url": "https://cdn.htmlgames.com/HuntingJackInTheCity/index.html",
    "youtube": "https://www.youtube.com/watch?v=fzy9N9hMtmg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HuntingJackInTheCity&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HuntingJackInTheCity/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HuntingJackInTheCity/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HuntingJackInTheCity/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/huntingjackinthecity200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/huntingjackinthecity500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/huntingjackinthecity800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/huntingjackinthecity1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/huntingjackinthecity1280720.webp"
    },
    {
    "name": "Mah Jong Connect II",
    "category": "Mahjong Connect",
    "create_date": "2022-03-07",
    "description": "The new version of the classic Mah Jong Connect II game. Connect two of the same tiles. The connecting path cannot have more than two 90 degree turns.",
    "url": "https://cdn.htmlgames.com/MahJongConnectII/index.html",
    "youtube": "https://www.youtube.com/watch?v=TLwP5wJ_4Jc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahJongConnectII&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahJongConnectII/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahJongConnectII/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahJongConnectII/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongconnectII200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongconnectII500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongconnectII800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongconnectII1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongconnectII1280720.webp"
    },
    {
    "name": "Mysterious Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2022-02-24",
    "description": "Enjoy a mysterious game of mahjong with 300 layouts. Combine two of the same free (=highlighted) tiles to remove the two tiles. Try to remove all tiles.",
    "url": "https://cdn.htmlgames.com/MysteriousMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=CF6sFV0h5hI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MysteriousMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MysteriousMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MysteriousMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MysteriousMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mysteriousmahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mysteriousmahjong500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mysteriousmahjong800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mysteriousmahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mysteriousmahjong1280.webp"
    },
    {
    "name": "Gold Coast Klondike",
    "category": "Klondike",
    "create_date": "2022-02-21",
    "description": "Play Klondike Solitaire on the Gold Coast. Try to move all cards to the foundations in suit from Ace to King. On the tableau build down in alternating color.",
    "url": "https://cdn.htmlgames.com/GoldCoastKlondike/index.html",
    "youtube": "https://www.youtube.com/watch?v=aKJMlIrnwtI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=GoldCoastKlondike&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/GoldCoastKlondike/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/GoldCoastKlondike/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/GoldCoastKlondike/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/goldcoastklondike200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/goldcoastklondike500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/goldcoastklondike800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/goldcoastklondike1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/goldcoastklondike1280.webp"
    },
    {
    "name": "Flower Burst",
    "category": "Puzzle Games",
    "create_date": "2022-02-18",
    "description": "Grow and connect Flowers and reach the goal. 3 connect flowers will grow. Drag to move flowers from the well. Click to rotate flowers in the well.",
    "url": "https://cdn.htmlgames.com/FlowerBurst/index.html",
    "youtube": "https://www.youtube.com/watch?v=Znk45a1jRnc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FlowerBurst&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FlowerBurst/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FlowerBurst/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FlowerBurst/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/flowerburst200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/flowerburst500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/flowerburst800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/flowerburst1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/flowerburst1280720.webp"
    },
    {
    "name": "Ski Slalom",
    "category": "Sports",
    "create_date": "2022-02-14",
    "description": "Ski Slalom winter game. Move left and right to turn and ski between the 2 gates everytime. Turning decreases your speed.",
    "url": "https://cdn.htmlgames.com/SkiSlalom/index.html",
    "youtube": "https://www.youtube.com/watch?v=h3D3sM_4-KM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SkiSlalom&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SkiSlalom/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SkiSlalom/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SkiSlalom/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/skislalom200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/skislalom500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/skislalom800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/skislalom1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/skislalom1280720.webp"
    },
    {
    "name": "Color Catch",
    "category": "Skill",
    "create_date": "2022-02-10",
    "description": "Catch the blocks with the right color. Move the catchers at the bottom to match it with the falling blocks.",
    "url": "https://cdn.htmlgames.com/ColorCatch/index.html",
    "youtube": "https://www.youtube.com/watch?v=elYjv4Y5KII",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ColorCatch&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ColorCatch/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ColorCatch/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ColorCatch/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/colorcatch200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/colorcatch500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/colorcatch800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/colorcatch1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/colorcatch1280720.webp"
    },
    {
    "name": "Muffin Fun",
    "category": "Bubble Shooter",
    "create_date": "2022-02-08",
    "description": "Shoot up and replace muffins. Click on a muffin to shoot your muffin there and create a group of 3 or more connected muffins.",
    "url": "https://cdn.htmlgames.com/MuffinFun/index.html",
    "youtube": "https://www.youtube.com/watch?v=4T7aVr3y-38",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MuffinFun&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MuffinFun/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MuffinFun/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MuffinFun/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/muffinfun200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/muffinfun500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/muffinfun800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/muffinfun1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/muffinfun1280720.webp"
    },
    {
    "name": "Flower Garden",
    "category": "Hidden Alphabet",
    "create_date": "2022-02-04",
    "description": "Find all the letters hidden in the Flower Garden. Click/tap on a letter when you found a letter. Find the complete alphabet.",
    "url": "https://cdn.htmlgames.com/FlowerGarden/index.html",
    "youtube": "https://www.youtube.com/watch?v=VoNaTVaR-ic",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FlowerGarden&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FlowerGarden/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FlowerGarden/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FlowerGarden/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/flowergarden200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/flowergarden500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/flowergarden800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/flowergarden1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/flowergarden1280720.webp"
    },
    {
    "name": "Secret Russian",
    "category": "Klondike",
    "create_date": "2022-01-31",
    "description": "Russian Solitaire with hidden cards. Try to move all cards to the 4 foundations. On the tableau build in suit and descending. All open cards and sequences can be moved.",
    "url": "https://cdn.htmlgames.com/SecretRussian/index.html",
    "youtube": "https://www.youtube.com/watch?v=c3LY3rXLj-w",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SecretRussian&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SecretRussian/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SecretRussian/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SecretRussian/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/secretrussian200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/secretrussian500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/secretrussian800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/secretrussian1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/secretrussian1280720.webp"
    },
    {
    "name": "Daily Traffic Jam",
    "category": "Daily Puzzles",
    "create_date": "2022-01-27",
    "description": "Everyday 8 different Traffic Jam Puzzles. Your goal is to slide the police car through the exit by moving other cars.",
    "url": "https://cdn.htmlgames.com/DailyTrafficJam/index.html",
    "youtube": "https://www.youtube.com/watch?v=MKuE7gHvZQM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyTrafficJam&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyTrafficJam/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyTrafficJam/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyTrafficJam/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dailytrafficjam200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dailytrafficjam500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dailytrafficjam800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dailytrafficjam1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dailytrafficjam1280.webp"
    },
    {
    "name": "Solitaire Connect",
    "category": "Card Games",
    "create_date": "2022-01-24",
    "description": "Connect the same cards. The connecting path cannot have more than 2 turns of 90 degrees.",
    "url": "https://cdn.htmlgames.com/SolitaireConnect/index.html",
    "youtube": "https://www.youtube.com/watch?v=JKSdOz0Nor4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SolitaireConnect&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SolitaireConnect/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SolitaireConnect/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SolitaireConnect/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/solitaireconnect200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/solitaireconnect500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/solitaireconnect800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/solitaireconnect1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/solitaireconnect1280720.webp"
    },
    {
    "name": "Push Puzzle",
    "category": "Puzzle Games",
    "create_date": "2022-01-20",
    "description": "Push and remove balls in this puzzle games. Click on the arrows to shoot a ball on the grid, a group of 4 or more of the same ball is removed. Remove all colored backgrounds.",
    "url": "https://cdn.htmlgames.com/PushPuzzle/index.html",
    "youtube": "https://www.youtube.com/watch?v=Ont1lViCB7w",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PushPuzzle&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PushPuzzle/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PushPuzzle/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PushPuzzle/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/pushpuzzle200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/pushpuzzle500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/pushpuzzle800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/pushpuzzle1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/pushpuzzle1280720.webp"
    },
    {
    "name": "Hidden Spots - Indonesia",
    "category": "Hidden Object Games",
    "create_date": "2022-01-17",
    "description": "Find all the hidden spots on the images from Indonesia. Click on the image where you have spotted a hidden spot.",
    "url": "https://cdn.htmlgames.com/HiddenSpotsIndonesia/index.html",
    "youtube": "https://www.youtube.com/watch?v=2Uxq4dNnLss",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HiddenSpotsIndonesia&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HiddenSpotsIndonesia/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HiddenSpotsIndonesia/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HiddenSpotsIndonesia/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hiddenspotsindonesia200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hiddenspotsindonesia500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hiddenspotsindonesia800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hiddenspotsindonesia1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hiddenspotsindonesia1280720.webp"
    },
    {
    "name": "Jolly Jong Butterfly",
    "category": "Mahjong Solitaire",
    "create_date": "2022-01-10",
    "description": "Fun Mahjong solitaire game with butterflies. Find 2 of the same butterfly tiles to remove the 2 tiles.",
    "url": "https://cdn.htmlgames.com/JollyJongButterfly/index.html",
    "youtube": "https://www.youtube.com/watch?v=tlZCgh7GZdQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JollyJongButterfly&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JollyJongButterfly/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JollyJongButterfly/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JollyJongButterfly/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jollyjongbutterfly200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jollyjongbutterfly500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/jollyjongbutterfly800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/jollyjongbutterfly1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/jollyjongbutterfly1280720.webp"
    },
    {
    "name": "Snow Balls",
    "category": "Retro",
    "create_date": "2021-12-30",
    "description": "Catch all the snow balls. Use the arrow keys or tap the arrows on your screen.",
    "url": "https://cdn.htmlgames.com/SnowBalls/index.html",
    "youtube": "https://www.youtube.com/watch?v=kILecJWOcl8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SnowBalls&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SnowBalls/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SnowBalls/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SnowBalls/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/snowballs200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/snowballs500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/snowballs800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/snowballs1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/snowballs1280720.webp"
    },
    {
    "name": "Emoji Match",
    "category": "Match 3 Games",
    "create_date": "2021-12-27",
    "description": "Collect the big Emoji by matching emojis. Swap two neighbouring emojis to get three or more of the same in a row/column.",
    "url": "https://cdn.htmlgames.com/EmojiMatch/index.html",
    "youtube": "https://www.youtube.com/watch?v=X8-3pQsT0tA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=EmojiMatch&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/EmojiMatch/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/EmojiMatch/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/EmojiMatch/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/emojimatch200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/emojimatch500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/emojimatch800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/emojimatch1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/emojimatch1280720.webp"
    },
    {
    "name": "Farm Girl",
    "category": "Match 3 Games",
    "create_date": "2021-12-23",
    "description": "Help the Farm Girl in this Match 3 Game. Swap two items to match three or more in a row.",
    "url": "https://cdn.htmlgames.com/FarmGirl/index.html",
    "youtube": "https://www.youtube.com/watch?v=SkAf4UFNyKU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FarmGirl&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FarmGirl/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FarmGirl/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FarmGirl/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/farmgirl200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/farmgirl500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/farmgirl800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/farmgirl1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/farmgirl1280.webp"
    },
    {
    "name": "Beijing Hidden Objects",
    "category": "Hidden Object Games",
    "create_date": "2021-12-20",
    "description": "Find all the hidden objects in Beijing. Click on the objects. You can zoom in. Use the light bulb for a hint.",
    "url": "https://cdn.htmlgames.com/BeijingHiddenObjects/index.html",
    "youtube": "https://www.youtube.com/watch?v=fVzzvqKA0H8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BeijingHiddenObjects&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BeijingHiddenObjects/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BeijingHiddenObjects/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BeijingHiddenObjects/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/beijinghiddenobjects200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/beijinghiddenobjects500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/beijinghiddenobjects800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/beijinghiddenobjects1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/beijinghiddenobjects1280720.webp"
    },
    {
    "name": "Mahjong Gardens 2",
    "category": "Mahjong Solitaire",
    "create_date": "2021-12-16",
    "description": "Remove all tiles from the garden and release the birds. Combine two of the same tiles.",
    "url": "https://cdn.htmlgames.com/MahjongGardens2/index.html",
    "youtube": "https://www.youtube.com/watch?v=EU-6RGKqqsE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongGardens2&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongGardens2/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongGardens2/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongGardens2/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjonggarden2200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjonggarden2500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjonggarden2800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjonggarden21024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjonggarden21280.webp"
    },
    {
    "name": "Hex Stream",
    "category": "Puzzle Games",
    "create_date": "2021-12-13",
    "description": "Flow Free Hex puzzles in different sizes. Connect the same colored hexes with a flow. Pair all and use all cells of the grid.",
    "url": "https://cdn.htmlgames.com/HexStream/index.html",
    "youtube": "https://www.youtube.com/watch?v=HoQnglWhXu8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HexStream&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HexStream/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HexStream/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HexStream/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hexstream200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hexstream500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hexstream800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hexstream1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hexstream1280.webp"
    },
    {
    "name": "Tower Solitaire",
    "category": "Tripeaks & Golf",
    "create_date": "2021-12-09",
    "description": "Remove all cards from the Tower. You can remove cards from it when the card is 1 higher or lower in value than the open card (top left). Click on the closed stack to get a new open card.",
    "url": "https://cdn.htmlgames.com/TowerSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=TQT4Xd4BN_Q",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TowerSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TowerSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TowerSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TowerSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/towersolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/towersolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/towersolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/towersolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/towersolitaire1280720.webp"
    },
    {
    "name": "Squirrel Bubble Shooter",
    "category": "Bubble Shooter",
    "create_date": "2021-12-06",
    "description": "Help the Squirrel to collect the Acorns. Shoot up bubbles and match three or more of the same. Collect all acorns to go to the next level.",
    "url": "https://cdn.htmlgames.com/SquirrelBubbleShooter/index.html",
    "youtube": "https://www.youtube.com/watch?v=_TysqbX7oew",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SquirrelBubbleShooter&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SquirrelBubbleShooter/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SquirrelBubbleShooter/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SquirrelBubbleShooter/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/squirrelbubbleshooter200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/squirrelbubbleshooter500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/squirrelbubbleshooter800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/squirrelbubbleshooter1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/squirrelbubbleshooter1280.webp"
    },
    {
    "name": "Unlock The Lock",
    "category": "Skill",
    "create_date": "2021-12-02",
    "description": "Try to unlock the lock. Tap when the moving line hits the yellow dot.",
    "url": "https://cdn.htmlgames.com/UnlockTheLock/index.html",
    "youtube": "https://www.youtube.com/watch?v=fG3anV58DmM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=UnlockTheLock&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/UnlockTheLock/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/UnlockTheLock/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/UnlockTheLock/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/unlockthelock200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/unlockthelock500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/unlockthelock800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/unlockthelock1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/unlockthelock1280720.webp"
    },
    {
    "name": "Illustrations 2",
    "category": "Difference Games",
    "create_date": "2021-11-29",
    "description": "Find all the differences on the Illustrations (2). Click on a difference.",
    "url": "https://cdn.htmlgames.com/Illustrations2/index.html",
    "youtube": "https://www.youtube.com/watch?v=3Rq7BfnFUos",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Illustrations2&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Illustrations2/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Illustrations2/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Illustrations2/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/illustrations2200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/illustrations2500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/illustrations2800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/illustrations21024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/illustrations21280720.webp"
    },
    {
    "name": "Country Labyrinth 2",
    "category": "Maze Games",
    "create_date": "2021-11-26",
    "description": "Find your way in the Country Labyrinth (2). Make a path from the Flag to the Country.",
    "url": "https://cdn.htmlgames.com/CountryLabyrinth2/index.html",
    "youtube": "https://www.youtube.com/watch?v=3HW8MzMubIo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CountryLabyrinth2&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CountryLabyrinth2/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CountryLabyrinth2/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CountryLabyrinth2/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/countrylabyrinth2200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/countrylabyrinth2500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/countrylabyrinth2800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/countrylabyrinth21024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/countrylabyrinth21280720.webp"
    },
    {
    "name": "Four Seasons Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2021-11-24",
    "description": "Play Mahjong in Spring, Summer, Autumn and Winter. Remove all tiles by selecting free tiles in pairs of the same.",
    "url": "https://cdn.htmlgames.com/FourSeasonsMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=JnUxCUI8RHg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FourSeasonsMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FourSeasonsMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FourSeasonsMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FourSeasonsMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/fourseasonsmahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/fourseasonsmahjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/fourseasonsmahjong800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/fourseasonsmahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/fourseasonsmahjong1280720.webp"
    },
    {
    "name": "Spring Grabbers",
    "category": "Mahjong Games",
    "create_date": "2021-11-22",
    "description": "Fun Mahjong Grab game. Grab two of the same to remove the tiles.",
    "url": "https://cdn.htmlgames.com/SpringGrabbers/index.html",
    "youtube": "https://www.youtube.com/watch?v=K9y_-wZg0Ek",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SpringGrabbers&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SpringGrabbers/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SpringGrabbers/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SpringGrabbers/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/springgrabbers200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/springgrabbers500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/springgrabbers800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/springgrabbers1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/springgrabbers1280.webp"
    },
    {
    "name": "The Royal Game of Ur",
    "category": "Board",
    "create_date": "2021-11-18",
    "description": "Play the board game The Royal Game of Ur. Throw the dice and race to the end and be the first to get all your stones over the finish.",
    "url": "https://cdn.htmlgames.com/TheRoyalGameOfUr/index.html",
    "youtube": "https://www.youtube.com/watch?v=6ux8kalny2w",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TheRoyalGameOfUr&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TheRoyalGameOfUr/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TheRoyalGameOfUr/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TheRoyalGameOfUr/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/TheRoyalgameofUr200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/TheRoyalgameofUr500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/TheRoyalgameofUr800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/TheRoyalgameofUr1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/TheRoyalgameofUr1280720.webp"
    },
    {
    "name": "BlackJack Chain",
    "category": "Card Games",
    "create_date": "2021-11-15",
    "description": "Connect cards on the table to a sum of 21. An Ace is 1 or 11 points.",
    "url": "https://cdn.htmlgames.com/BlackJackChain/index.html",
    "youtube": "https://www.youtube.com/watch?v=jkYpxw8Njk0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BlackJackChain&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BlackJackChain/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BlackJackChain/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BlackJackChain/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/blackjackchain200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/blackjackchain500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/blackjackchain800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/blackjackchain1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/blackjackchain1280720.webp"
    },
    {
    "name": "Farm Flip Mahjongg",
    "category": "Mahjong Solitaire",
    "create_date": "2021-11-11",
    "description": "Mahjong Solitaire game with 2 sides. Pair up all tiles with the same front image. After a tile has been paired, it flips. If both sides have been paired, a tile is removed.",
    "url": "https://cdn.htmlgames.com/FarmFlipMahjongg/index.html",
    "youtube": "https://www.youtube.com/watch?v=tMM3UKejFdQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FarmFlipMahjongg&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FarmFlipMahjongg/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FarmFlipMahjongg/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FarmFlipMahjongg/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/farmflipmahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/farmflipmahjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/farmflipmahjong800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/farmflipmahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/farmflipmahjong1280720.webp"
    },
    {
    "name": "Tangrams",
    "category": "Puzzle Games",
    "create_date": "2021-11-08",
    "description": "Solve all the Tangram Puzzles. Drag to move a tangram piece to the correct spot. Tap/click to rotate and double tap/click to mirror a piece.",
    "url": "https://cdn.htmlgames.com/Tangrams/index.html",
    "youtube": "https://www.youtube.com/watch?v=DK2CMIUQKHw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Tangrams&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Tangrams/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Tangrams/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Tangrams/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tangrams200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tangrams500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tangrams800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tangrams1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tangrams1280720.webp"
    },
    {
    "name": "Hidden Spots - Jewelry",
    "category": "Hidden Object Games",
    "create_date": "2021-11-04",
    "description": "Find all the hidden spots on the Jewelry images. Click on the image where you have spotted a hidden spot.",
    "url": "https://cdn.htmlgames.com/HiddenSpotsJewelry/index.html",
    "youtube": "https://www.youtube.com/watch?v=cXhpU_4Nuec",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HiddenSpotsJewelry&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HiddenSpotsJewelry/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HiddenSpotsJewelry/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HiddenSpotsJewelry/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hiddenspots-jewelry200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hiddenspots-jewelry500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hiddenspots-jewelry800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hiddenspots-jewelry1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hiddenspots-jewelry1280720.webp"
    },
    {
    "name": "Color Circle",
    "category": "Skill",
    "create_date": "2021-11-01",
    "description": "Stop the pointer at the correct color. Click to stop. The pointer indicated the color where you need to stop.",
    "url": "https://cdn.htmlgames.com/ColorCircle/index.html",
    "youtube": "https://www.youtube.com/watch?v=nmOZ1xUDIzs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ColorCircle&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ColorCircle/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ColorCircle/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ColorCircle/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/colorcircle200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/colorcircle500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/colorcircle800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/colorcircle1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/colorcircle1280.webp"
    },
    {
    "name": "Ice Blocks",
    "category": "Bubble Shooter",
    "create_date": "2021-10-28",
    "description": "Shoot up Ice Blocks and match 3 or more of the same.",
    "url": "https://cdn.htmlgames.com/IceBlocks/index.html",
    "youtube": "https://www.youtube.com/watch?v=YLxbrqWDjMo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=IceBlocks&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/IceBlocks/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/IceBlocks/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/IceBlocks/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/iceblock200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/iceblock500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/iceblock800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/iceblock1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/iceblock1280.webp"
    },
    {
    "name": "Haunted House Hidden Objects",
    "category": "Hidden Object Games",
    "create_date": "2021-10-25",
    "description": "Find all objects in the Haunted House. Click on found objects.",
    "url": "https://cdn.htmlgames.com/HauntedHouseHiddenObjects/index.html",
    "youtube": "https://www.youtube.com/watch?v=HXsotX0rAf0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HauntedHouseHiddenObjects&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HauntedHouseHiddenObjects/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HauntedHouseHiddenObjects/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HauntedHouseHiddenObjects/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hauntedhousehiddenobjects200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hauntedhousehiddenobjects500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hauntedhousehiddenobjects800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hauntedhousehiddenobjects1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hauntedhousehiddenobjects1280720.webp"
    },
    {
    "name": "Palabra de Código Del Dia",
    "category": "Crosswords",
    "create_date": "2021-10-14",
    "description": "2 new Spanish code words puzzles everyday. Solve the crossword puzzle. Each letter has been replaced by a number.",
    "url": "https://cdn.htmlgames.com/PalabraDeCodigoDelDia/index.html",
    "youtube": "https://www.youtube.com/watch?v=sw1DEq5T1aM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PalabraDeCodigoDelDia&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PalabraDeCodigoDelDia/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PalabraDeCodigoDelDia/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PalabraDeCodigoDelDia/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/palabra-codigo-dia-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/palabra-codigo-dia-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/palabra-codigo-dia-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/palabra-codigo-dia-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/palabra-codigo-dia-1280.webp"
    },
    {
    "name": "Tägliches Codewort",
    "category": "Crosswords",
    "create_date": "2021-10-14",
    "description": "2 new German code words puzzles everyday. Solve the crossword puzzle. Each letter has been replaced by a number.",
    "url": "https://cdn.htmlgames.com/TäglichesCodewort/index.html",
    "youtube": "https://www.youtube.com/watch?v=sw1DEq5T1aM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TäglichesCodewort&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TäglichesCodewort/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TäglichesCodewort/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TäglichesCodewort/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tagliches-codewort-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tagliches-codewort-300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tagliches-codewort-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tagliches-codewort-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tagliches-codewort-1280.webp"
    },
    {
    "name": "Dagelijks Codewoord",
    "category": "Crosswords",
    "create_date": "2021-10-14",
    "description": "2 new Dutch code words puzzles everyday. Solve the crossword puzzle. Each letter has been replaced by a number.",
    "url": "https://cdn.htmlgames.com/DagelijksCodewoord/index.html",
    "youtube": "https://www.youtube.com/watch?v=sw1DEq5T1aM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DagelijksCodewoord&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DagelijksCodewoord/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DagelijksCodewoord/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DagelijksCodewoord/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dagelijkscodewoord-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dagelijkscodewoord-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dagelijkscodewoord-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dagelijkscodewoord-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dagelijkscodewoord-1280.webp"
    },
    {
    "name": "Daily Code Words",
    "category": "Crosswords",
    "create_date": "2021-10-14",
    "description": "2 new English code words puzzles everyday. Solve the crossword puzzle. Each letter has been replaced by a number.",
    "url": "https://cdn.htmlgames.com/DailyCodeWords/index.html",
    "youtube": "https://www.youtube.com/watch?v=sw1DEq5T1aM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyCodeWords&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyCodeWords/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyCodeWords/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyCodeWords/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/daily-codewords-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/daily-codewords-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/daily-codewords-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/daily-codewords-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/daily-codewords-1280.webp"
    },
    {
    "name": "Tiles of Egypt",
    "category": "Mahjong Games",
    "create_date": "2021-10-12",
    "description": "Combine three of the same Mahjong stones in the bottom bar to remove the three stones. Remove all stones to advance to the next level.",
    "url": "https://cdn.htmlgames.com/TilesOfEgypt/index.html",
    "youtube": "https://www.youtube.com/watch?v=j8y3xRHO-Tw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TilesOfEgypt&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TilesOfEgypt/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TilesOfEgypt/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TilesOfEgypt/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tilesofegypt200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tilesofegypt500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tilesofegypt800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tilesofegypt1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tilesofegypt1280720.webp"
    },
    {
    "name": "House of Potions",
    "category": "Bejeweled",
    "create_date": "2021-10-07",
    "description": "Collect all potions in this Match 3 game. Swap 2 bubbles to match 3 or more in a row. Collect potions by moving the potions to the bottom row.",
    "url": "https://cdn.htmlgames.com/HouseOfPotions/index.html",
    "youtube": "https://www.youtube.com/watch?v=hSeQ4qv31RQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HouseOfPotions&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HouseOfPotions/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HouseOfPotions/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HouseOfPotions/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/houseofpotion200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/houseofpotion500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/houseofpotion800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/houseofpotion1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/houseofpotion1280720.webp"
    },
    {
    "name": "Country Labyrinth 1",
    "category": "Maze Games",
    "create_date": "2021-10-04",
    "description": "Find your way in the Country Labyrinth. Make a path from the Flag to the Country.",
    "url": "https://cdn.htmlgames.com/CountryLabyrinth1/index.html",
    "youtube": "https://www.youtube.com/watch?v=eCNrSw1GggU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CountryLabyrinth1&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CountryLabyrinth1/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CountryLabyrinth1/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CountryLabyrinth1/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/countrylabyrinth1200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/countrylabyrinth1500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/countrylabyrinth1800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/countrylabyrinth11024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/countrylabyrinth11280720.webp"
    },
    {
    "name": "Hidden Food",
    "category": "Hidden Object Games",
    "create_date": "2021-09-30",
    "description": "Find the Hidden Food. Click on the objects you need to find.",
    "url": "https://cdn.htmlgames.com/HiddenFood/index.html",
    "youtube": "https://www.youtube.com/watch?v=_fT-yJyojAc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HiddenFood&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HiddenFood/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HiddenFood/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HiddenFood/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hiddenfood200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hiddenfood500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hiddenfood800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hiddenfood1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hiddenfood1280720.webp"
    },
    {
    "name": "Saratoga Solitaire",
    "category": "Klondike",
    "create_date": "2021-09-27",
    "description": "Klondike Solitaire Variation with open cards. Move all cards to the foundations from Ace to King. On the game area you can build down in ascending order and alternate color.",
    "url": "https://cdn.htmlgames.com/SaratogaSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=GXfZCR-pkb4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SaratogaSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SaratogaSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SaratogaSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SaratogaSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/saratogasolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/saratogasolitaire500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/saratogasolitaire800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/saratogasolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/saratogasolitaire1280.webp"
    },
    {
    "name": "Berlin Hidden Objects",
    "category": "Hidden Object Games",
    "create_date": "2021-09-23",
    "description": "Find all the hidden objects in Berlin. Click on the objects. You can zoom in. Use the light bulb for a hint.",
    "url": "https://cdn.htmlgames.com/BerlinHiddenObjects/index.html",
    "youtube": "https://www.youtube.com/watch?v=7R6kGwLB90c",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BerlinHiddenObjects&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BerlinHiddenObjects/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BerlinHiddenObjects/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BerlinHiddenObjects/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/berlinhiddenobjects200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/berlinhiddenobjects500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/berlinhiddenobjects800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/berlinhiddenobjects1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/berlinhiddenobjects1280720.webp"
    },
    {
    "name": "Pet Link",
    "category": "Mahjong Connect",
    "create_date": "2021-09-20",
    "description": "Fun Mahjong Link game with Pets. Connect two of the same pets to remove the pets from the game. You can only use a connecting path with a maximum of 2 turns.",
    "url": "https://cdn.htmlgames.com/PetLink/index.html",
    "youtube": "https://www.youtube.com/watch?v=tXsHI1i7itk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PetLink&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PetLink/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PetLink/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PetLink/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/petlink200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/petlink500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/petlink-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/petlink1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/petlink1280.webp"
    },
    {
    "name": "Tetris Slider",
    "category": "Tetris",
    "create_date": "2021-09-17",
    "description": "Slide blocks into place and complete horizontal lines. Drag a block and let blocks fall into empty spots.",
    "url": "https://cdn.htmlgames.com/TetrisSlider/index.html",
    "youtube": "https://www.youtube.com/watch?v=PgoayasMInM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TetrisSlider&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TetrisSlider/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TetrisSlider/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TetrisSlider/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tetrisslider200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tetrisslider500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tetrisslider800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tetrisslider1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tetrisslider1280720.webp"
    },
    {
    "name": "Tägliches Anagramm Kreuzworträtsel",
    "category": "Crosswords",
    "create_date": "2021-09-15",
    "description": "Everyday a new Anagram Crossword to solve in the German language. The solution of every word is an anagram of the given clue.",
    "url": "https://cdn.htmlgames.com/TäglichesAnagrammKreuzworträtsel/index.html",
    "youtube": "https://www.youtube.com/watch?v=H4LjKWc20kU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TäglichesAnagrammKreuzworträtsel&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TäglichesAnagrammKreuzworträtsel/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TäglichesAnagrammKreuzworträtsel/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TäglichesAnagrammKreuzworträtsel/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tagliches-anagramm-kreuzwort-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tagliches-anagramm-kreuzwort-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tagliches-anagramm-kreuzwort-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tagliches-anagramm-kreuzwort-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tagliches-anagramm-kreuzwort-1280.webp"
    },
    {
    "name": "Dagelijkse Anagram Kruiswoord",
    "category": "Crosswords",
    "create_date": "2021-09-15",
    "description": "Everyday a new Anagram Crossword to solve in the Dutch language. The solution of every word is an anagram of the given clue.",
    "url": "https://cdn.htmlgames.com/DagelijkseAnagramKruiswoord/index.html",
    "youtube": "https://www.youtube.com/watch?v=H4LjKWc20kU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DagelijkseAnagramKruiswoord&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DagelijkseAnagramKruiswoord/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DagelijkseAnagramKruiswoord/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DagelijkseAnagramKruiswoord/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dagelijkse-anagram-kruiswoord-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dagelijkse-anagram-kruiswoord-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dagelijkse-anagram-kruiswoord-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dagelijkse-anagram-kruiswoord-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dagelijkse-anagram-kruiswoord-1280.webp"
    },
    {
    "name": "Crucigrama de Anagramas Diario",
    "category": "Crosswords",
    "create_date": "2021-09-15",
    "description": "Everyday a new Anagram Crossword to solve in the Spanish language. The solution of every word is an anagram of the given clue.",
    "url": "https://cdn.htmlgames.com/CrucigramaDeAnagramasDiario/index.html",
    "youtube": "https://www.youtube.com/watch?v=H4LjKWc20kU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CrucigramaDeAnagramasDiario&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CrucigramaDeAnagramasDiario/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CrucigramaDeAnagramasDiario/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CrucigramaDeAnagramasDiario/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/crucigrama-de-anagrames-diario-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/crucigrama-de-anagrames-diario-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/crucigrama-de-anagrames-diario-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/crucigrama-de-anagrames-diario-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/crucigrama-de-anagrames-diario-1280.webp"
    },
    {
    "name": "Daily Anagram Crossword",
    "category": "Crosswords",
    "create_date": "2021-09-15",
    "description": "Everyday a new Anagram Crossword to solve. The solution of every word is an anagram of the given clue.",
    "url": "https://cdn.htmlgames.com/DailyAnagramCrossword/index.html",
    "youtube": "https://www.youtube.com/watch?v=H4LjKWc20kU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyAnagramCrossword&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyAnagramCrossword/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyAnagramCrossword/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyAnagramCrossword/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/daily-anagram-crossword-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/daily-anagram-crossword-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/daily-anagram-crossword-800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/daily-anagram-crossword-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/daily-anagram-crossword-1280.webp"
    },
    {
    "name": "Neon Dots",
    "category": "Puzzle Games",
    "create_date": "2021-09-13",
    "description": "Connect all the Neon dots in ascending order. The connecting path cannot cross itself. Start with 1.",
    "url": "https://cdn.htmlgames.com/NeonDots/index.html",
    "youtube": "https://www.youtube.com/watch?v=BT_m1DvCnpM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=NeonDots&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/NeonDots/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/NeonDots/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/NeonDots/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/neondots200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/neondots500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/neondots800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/neondots1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/neondots1280720.webp"
    },
    {
    "name": "Number Maze",
    "category": "Maze Games",
    "create_date": "2021-09-09",
    "description": "Connect all numbers in the Maze. Start at 0 go up and use all dots.",
    "url": "https://cdn.htmlgames.com/NumberMaze/index.html",
    "youtube": "https://www.youtube.com/watch?v=i95ru5dOtts",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=NumberMaze&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/NumberMaze/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/NumberMaze/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/NumberMaze/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/numbermaze200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/numbermaze500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/numbermaze800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/numbermaze1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/numbermaze1280720.webp"
    },
    {
    "name": "Illustrations 1",
    "category": "Difference Games",
    "create_date": "2021-09-07",
    "description": "Find all the differences on the Illustrations. Click on a difference.",
    "url": "https://cdn.htmlgames.com/Illustrations1/index.html",
    "youtube": "https://www.youtube.com/watch?v=J-zQrPBHIA0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Illustrations1&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Illustrations1/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Illustrations1/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Illustrations1/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/illustrations1200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/illustrations1500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/illustrations1800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/illustrations11024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/illustrations11280720.webp"
    },
    {
    "name": "Spades",
    "category": "Card Games",
    "create_date": "2021-09-02",
    "description": "Play the card game Spades with computer opponents. Bid how many tricks you expect to get an beat the computer opponents.",
    "url": "https://cdn.htmlgames.com/Spades/index.html",
    "youtube": "https://www.youtube.com/watch?v=MvXzu7AtAi4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Spades&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Spades/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Spades/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Spades/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/spades200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/spades500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/spades800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/spades1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/spades1280720.webp"
    },
    {
    "name": "Dark Mahjong Solitaire",
    "category": "Mahjong Solitaire",
    "create_date": "2021-08-30",
    "description": "Mahjong Solitaire games with Dark tiles. Combine two of the same free tiles to remove those. Try to remove all tiles from the layout.",
    "url": "https://cdn.htmlgames.com/DarkMahjongSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=f70lc8PrgfQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DarkMahjongSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DarkMahjongSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DarkMahjongSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DarkMahjongSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/darkmahjongsolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/darkmahjongsolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/darkmahjongsolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/darkmahjongsolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/darkmahjongsolitaire1280720.webp"
    },
    {
    "name": "Avoider",
    "category": "Platform",
    "create_date": "2021-08-26",
    "description": "Jump up and avoid the platforms. Tap or use the space bar to jump.",
    "url": "https://cdn.htmlgames.com/Avoider/index.html",
    "youtube": "https://www.youtube.com/watch?v=EG2NZf-PyX0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Avoider&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Avoider/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Avoider/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Avoider/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/avoider200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/avoider500x300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/avoider800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/avoider1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/avoider1280.webp"
    },
    {
    "name": "Triple Coins",
    "category": "Match 3 Games",
    "create_date": "2021-08-24",
    "description": "Rotate the screen and try to match 3 of the same coins. Use the arrow keys or the arrows on the screen to rotate.",
    "url": "https://cdn.htmlgames.com/TripleCoins/index.html",
    "youtube": "https://www.youtube.com/watch?v=ov6wXFaJX0s",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TripleCoins&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TripleCoins/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TripleCoins/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TripleCoins/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/triplecoins200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/triplecoins500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/triplecoins800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/triplecoins1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/triplecoins1280.webp"
    },
    {
    "name": "Mah Jong Connect I",
    "category": "Mahjong Connect",
    "create_date": "2021-08-18",
    "description": "The new version of the classic Mah Jong Connect game. Connect two of the same tiles. The connecting path cannot have more than two 90 degree turns.",
    "url": "https://cdn.htmlgames.com/MahJongConnectI/index.html",
    "youtube": "https://www.youtube.com/watch?v=X07Y-Kb6AtE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahJongConnectI&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahJongConnectI/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahJongConnectI/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahJongConnectI/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongconnect1200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongconnect1500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongconnect1800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongconnect11024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongconnect11280720.webp"
    },
    {
    "name": "Letter Scramble",
    "category": "Word Games",
    "create_date": "2021-08-17",
    "description": "Find and type words with the scrambled letters. Type valid English words with the letters on the screen.",
    "url": "https://cdn.htmlgames.com/LetterScramble/index.html",
    "youtube": "https://www.youtube.com/watch?v=2bsN-Ax6K_k",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=LetterScramble&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/LetterScramble/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/LetterScramble/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/LetterScramble/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/letterscramble200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/letterscramble500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/letterscramble800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/letterscramble1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/letterscramble1280720.webp"
    },
    {
    "name": "Beetle Solitaire",
    "category": "Spider",
    "create_date": "2021-08-13",
    "description": "Spider solitaire game with open cards. Try to make columns from King to Ace on the tableau. Build down regardless of suit. You can only move group of cards that are down in suit. Click on the stock (top left) to deal new cards.",
    "url": "https://cdn.htmlgames.com/BeetleSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=zR9RacxQpMc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BeetleSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BeetleSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BeetleSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BeetleSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/beetlesolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/beetlesolitaire500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/beetlesolitaire800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/beetlesolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/beetlesolitaire1280.webp"
    },
    {
    "name": "Monkey Island",
    "category": "Bubble Shooter",
    "create_date": "2021-08-10",
    "description": "Help the Monkeys to collect the bananas. Shoot up bubbles to get a connected group of 3 or more of the same colored bubbles. Try to collect all bubbles and bananas.",
    "url": "https://cdn.htmlgames.com/MonkeyIsland/index.html",
    "youtube": "https://www.youtube.com/watch?v=mSL-71-ZIcU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MonkeyIsland&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MonkeyIsland/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MonkeyIsland/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MonkeyIsland/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/monkeyisland200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/monkeyisland500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/monkeyisland800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/monkeyisland1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/monkeyisland1280720.webp"
    },
    {
    "name": "Finish the Proverbs",
    "category": "Word Games",
    "create_date": "2021-07-22",
    "description": "Test your knowledge of English proverbs and enter the missing word by selecting letters.",
    "url": "https://cdn.htmlgames.com/FinishTheProverbs/index.html",
    "youtube": "https://www.youtube.com/watch?v=96bk1G57PB8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FinishTheProverbs&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FinishTheProverbs/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FinishTheProverbs/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FinishTheProverbs/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/finishtheproverbs200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/finishtheproverbs500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/finishtheproverbs800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/finishtheproverbs1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/finishtheproverbs1280720.webp"
    },
    {
    "name": "Hidden Spots - Castles",
    "category": "Hidden Object Games",
    "create_date": "2021-07-19",
    "description": "Find all the hidden spots on the Castle images. Click on the image where you have spotted a hidden spot.",
    "url": "https://cdn.htmlgames.com/HiddenSpotsCastles/index.html",
    "youtube": "https://www.youtube.com/watch?v=e5r2p06D4UE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HiddenSpotsCastles&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HiddenSpotsCastles/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HiddenSpotsCastles/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HiddenSpotsCastles/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hiddenspotscastles200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hiddenspotscastles500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hiddenspotscastles800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hiddenspotscastles1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hiddenspotscastles1280720.webp"
    },
    {
    "name": "Panda Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2021-07-14",
    "description": "Help the Panda in this Mahjong solitaire game. Combine two of the same tiles. You can only select a tile that is free.",
    "url": "https://cdn.htmlgames.com/PandaMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=OLgg66tgQW0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PandaMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PandaMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PandaMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PandaMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/pandamahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/pandamahjong500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/pandamahjong800-450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/pandamahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/pandamahjong1280.webp"
    },
    {
    "name": "Upside Down",
    "category": "Tetris",
    "create_date": "2021-07-07",
    "description": "A tetris game but then Upside Down. Move up bricks and complete full horizontal lines.",
    "url": "https://cdn.htmlgames.com/UpsideDown/index.html",
    "youtube": "https://www.youtube.com/watch?v=Q9aAlbCF02U",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=UpsideDown&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/UpsideDown/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/UpsideDown/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/UpsideDown/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/upsidedown200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/upsidedown500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/upsidedown800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/upsidedown1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/upsidedown1280720.webp"
    },
    {
    "name": "Mahjong 2048",
    "category": "2048 & Merge",
    "create_date": "2021-07-05",
    "description": "Mahjong and 2048 crossover game. Play a 2048 game with Mahjong Tiles. Move and merge to reach higher numbers.",
    "url": "https://cdn.htmlgames.com/Mahjong2048/index.html",
    "youtube": "https://www.youtube.com/watch?v=nH4Xv1yc2M4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Mahjong2048&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Mahjong2048/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Mahjong2048/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Mahjong2048/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjong2048200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjong2048500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjong2048800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjong20481024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjong20481280720.webp"
    },
    {
    "name": "Zoo Mysteries",
    "category": "Hidden Object Games",
    "create_date": "2021-07-01",
    "description": "Find all the hidden objects in this Zoo Mystery game. Click to find an object or differences.",
    "url": "https://cdn.htmlgames.com/ZooMysteries/index.html",
    "youtube": "https://www.youtube.com/watch?v=VJHzmwIAomk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ZooMysteries&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ZooMysteries/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ZooMysteries/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ZooMysteries/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/zoomysteries200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/zoomysteries500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/zoomysteries800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/zoomysteries1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/zoomysteries1280720.webp"
    },
    {
    "name": "Daily StoStone",
    "category": "Daily Puzzles",
    "create_date": "2021-06-29",
    "description": "Every day 6 new StoStone puzzles. Solve the puzzle by following the rules. The game area is divided in regions. All black cells in a region must be connected horizontally or vertically. A cell with a number indicates how many cells in the region must be blackened. In regions without a number any amount of cells may be blackened (at least one).  Black cells cannot touch across regions horizontally or vertically and if all stones \"fall down\", they must cover exactly the bottom half of the grid.",
    "url": "https://cdn.htmlgames.com/DailyStoStone/index.html",
    "youtube": "https://www.youtube.com/watch?v=LowOKzCnhis",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyStoStone&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyStoStone/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyStoStone/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyStoStone/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/daily-stostone-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/daily-stostone-500-300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/daily-stostone-800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/daily-stostone1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/daily-stostone-1280-720.webp"
    },
    {
    "name": "Three Cell",
    "category": "Freecell",
    "create_date": "2021-06-24",
    "description": "Freecell game with only three Free cells. Try to move all cards to the foundations. On the tableau build down on alternating color.",
    "url": "https://cdn.htmlgames.com/ThreeCell/index.html",
    "youtube": "https://www.youtube.com/watch?v=iHjKXOA0dtk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ThreeCell&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ThreeCell/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ThreeCell/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ThreeCell/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/threecell200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/threecell500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/threecell800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/threecell1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/threecell1280720.webp"
    },
    {
    "name": "Mahjong Sequence",
    "category": "Mahjong Solitaire",
    "create_date": "2021-06-21",
    "description": "Pair tiles in the given sequence in this Mahjong game. Combine 2 of the same tiles to remove those tiles. To win the game you have to pair tiles according to the given sequence at the bottom of the screen.",
    "url": "https://cdn.htmlgames.com/MahjongSequence/index.html",
    "youtube": "https://www.youtube.com/watch?v=JFl6LwIzMpY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongSequence&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongSequence/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongSequence/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongSequence/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongsequence200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongsequence500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongsequence800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongsequence1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongsequence1280720.webp"
    },
    {
    "name": "Forest Bubble Shooter",
    "category": "Bubble Shooter",
    "create_date": "2021-06-17",
    "description": "Bubble Shooter game in the Forest. Shoot up bubbles and make groups of 3 or more of the same. Try to remove all bubbles.",
    "url": "https://cdn.htmlgames.com/ForestBubbleShooter/index.html",
    "youtube": "https://www.youtube.com/watch?v=gYI1K4Py_oE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ForestBubbleShooter&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ForestBubbleShooter/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ForestBubbleShooter/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ForestBubbleShooter/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/forestbubbleshooter200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/forestbubbleshooter500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/forestbubbleshooter800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/forestbubbleshooter1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/forestbubbleshooter1280.webp"
    },
    {
    "name": "Mahjong 3D Time",
    "category": "3D Mahjong",
    "create_date": "2021-06-03",
    "description": "3D Mahjong game with special time cubes. Remove all cubes in pairs of the same free cubes. Remove the same time cubes for extra time.",
    "url": "https://cdn.htmlgames.com/Mahjong3DTime/index.html",
    "youtube": "https://www.youtube.com/watch?v=JR_KB_iTjJo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Mahjong3DTime&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Mahjong3DTime/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Mahjong3DTime/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Mahjong3DTime/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjong3dtime200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjong3dtime500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjong3dtime800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjong3dtime1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjong3dtime1280.webp"
    },
    {
    "name": "5 Stack Blackjack",
    "category": "Card Games",
    "create_date": "2021-05-31",
    "description": "Try to get 5x Black Jack. Move a card to one of the 5 stacks. Try to get as close to BlackJack in all 5 stacks.",
    "url": "https://cdn.htmlgames.com/5StackBlackjack/index.html",
    "youtube": "https://www.youtube.com/watch?v=uwkuCR4VmPA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=5StackBlackjack&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/5StackBlackjack/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/5StackBlackjack/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/5StackBlackjack/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/5stackblackjack200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/5stackblackjack500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/5stackblackjack800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/5stackblackjack1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/5stackblackjack1280.webp"
    },
    {
    "name": "Jigsaw Cities 2",
    "category": "Puzzle Games",
    "create_date": "2021-05-27",
    "description": "10 new great cities to complete in this jigsaw puzzle game. Move all jigsaw puzzles to the correct spot and complete the image of the cities.",
    "url": "https://cdn.htmlgames.com/JigsawCities2/index.html",
    "youtube": "https://www.youtube.com/watch?v=Mncdi9O7_Xc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JigsawCities2&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JigsawCities2/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JigsawCities2/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JigsawCities2/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jigsawcities2200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jigsawcities2500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/jigsawcities2800480.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/jigsawcities21024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/jigsawcities21280720.webp"
    },
    {
    "name": "Basketball Legend",
    "category": "Sports",
    "create_date": "2021-05-20",
    "description": "Fun basketball game. Shoot the ball in the basket. Drag on the screen to determine your shot.",
    "url": "https://cdn.htmlgames.com/BasketballLegend/index.html",
    "youtube": "https://www.youtube.com/watch?v=SfTEpdG0qy4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BasketballLegend&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BasketballLegend/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BasketballLegend/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BasketballLegend/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/basketballlegend200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/basketballlegend500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/basketballlegend800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/basketballlegend1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/basketballlegend1280720.webp"
    },
    {
    "name": "Hidden Library",
    "category": "Hidden Object Games",
    "create_date": "2021-05-17",
    "description": "Find all the objects in the Library. You have a limited amount of clicks. To earn more clicks you need to have found all objects of one category first.",
    "url": "https://cdn.htmlgames.com/HiddenLibrary/index.html",
    "youtube": "https://www.youtube.com/watch?v=-9seJPDnE8s",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HiddenLibrary&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HiddenLibrary/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HiddenLibrary/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HiddenLibrary/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hiddenlibrary200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hiddenlibrary500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hiddenlibrary800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hiddenlibrary1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hiddenlibrary1280720.webp"
    },
    {
    "name": "Mysterious Pirate Jewels 2",
    "category": "Connect 3",
    "create_date": "2021-05-11",
    "description": "Remove all colored backgrounds in this Pirate game with 45 new levels. Connect three or more of the same colored jewels to remove the jewels and the backgrounds.",
    "url": "https://cdn.htmlgames.com/MysteriousPirateJewels2/index.html",
    "youtube": "https://www.youtube.com/watch?v=uwlxd3Sucdc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MysteriousPirateJewels2&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MysteriousPirateJewels2/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MysteriousPirateJewels2/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MysteriousPirateJewels2/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mysteriouspiratejewels2200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mysteriouspiratejewels2500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mysteriouspiratejewels2800x450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mysteriouspiratejewels21024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mysteriouspiratejewels21280.webp"
    },
    {
    "name": "Bumper Car",
    "category": "Puzzle Games",
    "create_date": "2021-05-06",
    "description": "Try to remove all cars by bumping the same cars with each other. Drag a car to remove it. Remove all cars.",
    "url": "https://cdn.htmlgames.com/BumperCar/index.html",
    "youtube": "https://www.youtube.com/watch?v=JwNxnNRDBQc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BumperCar&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BumperCar/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BumperCar/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BumperCar/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bumpercar200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bumpercar500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bumpercar800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/bumpercar1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/bumpercar1280720.webp"
    },
    {
    "name": "Tokio Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2021-05-04",
    "description": "Enjoy this Japan style Mahjong Game. Combine two of the same Japan style tiles to remove the pair.",
    "url": "https://cdn.htmlgames.com/TokioMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=5_32nTY2y7U",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TokioMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TokioMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TokioMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TokioMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tokiomahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tokiomahjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tokiomahjong800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tokiomahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tokiomahjong1280720.webp"
    },
    {
    "name": "Tic Tac Toe",
    "category": "Brain Games",
    "create_date": "2021-05-03",
    "description": "Play the classic Tic Tac Toe game in 3 rounds. Be the first to get 3 of your symbols in a row.",
    "url": "https://cdn.htmlgames.com/TicTacToe/index.html",
    "youtube": "https://www.youtube.com/watch?v=IXuf_U1v1bA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TicTacToe&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TicTacToe/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TicTacToe/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TicTacToe/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tictactoe200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tictactoe500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tictactoe800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tictactoe1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tictactoe1280720.webp"
    },
    {
    "name": "Letter Train",
    "category": "Word Games",
    "create_date": "2021-04-22",
    "description": "Drop letters on the train to make valid English words. Click on a letter and on a wagon.",
    "url": "https://cdn.htmlgames.com/LetterTrain/index.html",
    "youtube": "https://www.youtube.com/watch?v=1JbAOUj7ZUM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=LetterTrain&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/LetterTrain/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/LetterTrain/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/LetterTrain/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/lettertrain200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/lettertrain500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/lettertrain800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/lettertrain1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/lettertrain1280720.webp"
    },
    {
    "name": "Thirty One",
    "category": "Card Games",
    "create_date": "2021-04-15",
    "description": "Play the German card game Thirty One (31). You can only swap cards with the central cards in the middle. Try to get as close to 31 as you can.",
    "url": "https://cdn.htmlgames.com/ThirtyOne/index.html",
    "youtube": "https://www.youtube.com/watch?v=fV4J7ozGJ6s",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ThirtyOne&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ThirtyOne/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ThirtyOne/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ThirtyOne/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/thirtyone200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/thirtyone500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/thirtyone800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/thirtyone1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/thirtyone1280720.webp"
    },
    {
    "name": "Ludo",
    "category": "Board",
    "create_date": "2021-04-15",
    "description": "The board game Ludo against 3 AI opponents. Be the first to get all your tokens to the finish.",
    "url": "https://cdn.htmlgames.com/Ludo/index.html",
    "youtube": "https://www.youtube.com/watch?v=Ht4GUtgSfPc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Ludo&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Ludo/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Ludo/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Ludo/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/ludo200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/ludo500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/ludo800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/ludo1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/ludo1280720.webp"
    },
    {
    "name": "Discover Egypt",
    "category": "Mahjong Solitaire",
    "create_date": "2021-04-08",
    "description": "Discover Egypt in this Mahjong and Difference Game. In the mahjong game you have to pair the same free tiles to remove those tiles. In the difference game you have to find the differences between 2 images.",
    "url": "https://cdn.htmlgames.com/DiscoverEgypt/index.html",
    "youtube": "https://www.youtube.com/watch?v=v8anhtAm2qY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DiscoverEgypt&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DiscoverEgypt/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DiscoverEgypt/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DiscoverEgypt/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/discoveregypt200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/discoveregypt500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/discoveregypt800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/discoveregypt1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/discoveregypt1280720.webp"
    },
    {
    "name": "Bubble Spinner",
    "category": "Bubble Shooter",
    "create_date": "2021-04-08",
    "description": "Shoot bubbles at the wheel and match 3 or more of the same color. Try to remove all bubbles.",
    "url": "https://cdn.htmlgames.com/BubbleSpinner/index.html",
    "youtube": "https://www.youtube.com/watch?v=qoh1FDhLd9g",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BubbleSpinner&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BubbleSpinner/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BubbleSpinner/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BubbleSpinner/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bubblespinner200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bubblespinner500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bubblespinner800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/bubblespinner1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/bubblespinner1280720.webp"
    },
    {
    "name": "Saloon Shootout",
    "category": "Shooting & War",
    "create_date": "2021-04-01",
    "description": "Shoot the bad guys before they shoot at you. Click/tap to shoot. Click the bullets (top left) to reload. Do not hit the innocent.",
    "url": "https://cdn.htmlgames.com/SaloonShootout/index.html",
    "youtube": "https://www.youtube.com/watch?v=bdPOXUt7_A4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SaloonShootout&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SaloonShootout/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SaloonShootout/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SaloonShootout/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/saloonshootout200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/saloonshootout500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/saloonshootout800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/saloonshootout1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/saloonshootout1280.webp"
    },
    {
    "name": "European Cities",
    "category": "Difference Games",
    "create_date": "2021-04-01",
    "description": "Find all the differences in the European Cities. Click on a difference when you did locate it. Use the hint button (bottom right) for a hint.",
    "url": "https://cdn.htmlgames.com/EuropeanCities/index.html",
    "youtube": "https://www.youtube.com/watch?v=nvj8mT4p60s",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=EuropeanCities&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/EuropeanCities/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/EuropeanCities/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/EuropeanCities/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/europeancities200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/europeancities500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/europeancities800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/europeancities1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/europeancities1280.webp"
    },
    {
    "name": "Gargantua Double Klondike",
    "category": "Klondike",
    "create_date": "2021-03-25",
    "description": "A fun Double Klondike Solitaire game. Try to move all cards to the 8 foundations (top). On the tableau you can build down on alternating color. Click on the stack to turn over new cards.",
    "url": "https://cdn.htmlgames.com/GargantuaDoubleKlondike/index.html",
    "youtube": "https://www.youtube.com/watch?v=ejWUNIUk774",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=GargantuaDoubleKlondike&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/GargantuaDoubleKlondike/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/GargantuaDoubleKlondike/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/GargantuaDoubleKlondike/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/gargantuadoubleklondike200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/gargantuadoubleklondike500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/gargantuadoubleklondike800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/gargantuadoubleklondike1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/gargantuadoubleklondike1280720.webp"
    },
    {
    "name": "Tägliche Wortsuche",
    "category": "Daily Puzzles",
    "create_date": "2021-03-22",
    "description": "Everyday a new word search puzzle level  in German. Find all the indicated words.",
    "url": "https://cdn.htmlgames.com/TäglicheWortsuche/index.html",
    "youtube": "https://www.youtube.com/watch?v=3n3S5FW1pSA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TäglicheWortsuche&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TäglicheWortsuche/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TäglicheWortsuche/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TäglicheWortsuche/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/taglichewortsuche200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/taglichewortsuche500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/taglichewortsuche800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/taglichewortsuche1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/taglichewortsuche1280.webp"
    },
    {
    "name": "Dagelijkse Woordzoeker",
    "category": "Daily Puzzles",
    "create_date": "2021-03-22",
    "description": "Everyday a new word search puzzle level  in Dutch. Find all the indicated words.",
    "url": "https://cdn.htmlgames.com/DagelijkseWoordzoeker/index.html",
    "youtube": "https://www.youtube.com/watch?v=3n3S5FW1pSA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DagelijkseWoordzoeker&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DagelijkseWoordzoeker/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DagelijkseWoordzoeker/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DagelijkseWoordzoeker/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dagelijksewoordzoeker200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dagelijksewoordzoeker500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dagelijksewoordzoeker800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dagelijksewoordzoeker1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dagelijksewoordzoeker1280.webp"
    },
    {
    "name": "Búsqueda de Palabras Diaria",
    "category": "Daily Puzzles",
    "create_date": "2021-03-22",
    "description": "Everyday a new word search puzzle level  in Spanish. Find all the indicated words.",
    "url": "https://cdn.htmlgames.com/BusquedaDePalabrasDiara/index.html",
    "youtube": "https://www.youtube.com/watch?v=3n3S5FW1pSA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BusquedaDePalabrasDiara&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BusquedaDePalabrasDiara/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BusquedaDePalabrasDiara/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BusquedaDePalabrasDiara/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/busquedadepalabras200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/busquedadepalabras500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/busquedadepalabras800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/busquedadepalabras1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/busquedadepalabras1280.webp"
    },
    {
    "name": "Indian Solitaire",
    "category": "Solitaire Games",
    "create_date": "2021-03-22",
    "description": "Play Indian: a challenging Solitaire game. Move all cards to the 8 foundations. On the tableau you can build down on any color except the same suit. Click on the stock to get a new card.",
    "url": "https://cdn.htmlgames.com/IndianSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=NmKANn1D7H4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=IndianSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/IndianSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/IndianSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/IndianSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/indiansolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/indiansolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/indiansolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/indiansolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/indiansolitaire1280720.webp"
    },
    {
    "name": "Daily Line Game",
    "category": "Daily Puzzles",
    "create_date": "2021-03-19",
    "description": "Everyday 3 different Line Game puzzles to solve. Draw a horizontal or vertical line in each white cell. Each black number in a cell represents the total number of cells occupied by the lines from that number. Lines cannot enter other numbered cells or intersect with other lines.",
    "url": "https://cdn.htmlgames.com/DailyLineGame/index.html",
    "youtube": "https://www.youtube.com/watch?v=oLlUhhTytFg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyLineGame&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyLineGame/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyLineGame/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyLineGame/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dailylinegame200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dailylinegame500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dailylinegame800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dailylinegame1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dailylinegame1280.webp"
    },
    {
    "name": "Candy Crunch",
    "category": "Match 3 Games",
    "create_date": "2021-03-18",
    "description": "Crunch Candy by matching 3 or more in a row. Every move gets you closer to the goal, but you don't have enough moves. To get more match the wrapped candies.",
    "url": "https://cdn.htmlgames.com/CandyCrunch/index.html",
    "youtube": "https://www.youtube.com/watch?v=8zRkgAhmdBw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CandyCrunch&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CandyCrunch/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CandyCrunch/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CandyCrunch/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/candycrunch200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/candycrunch500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/candycrunch800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/candycrunch1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/candycrunch1280.webp"
    },
    {
    "name": "Easter Pile",
    "category": "Mahjong Games",
    "create_date": "2021-03-18",
    "description": "Mahjong game for Easter. Combine two of the same free tiles and try to remove all.",
    "url": "https://cdn.htmlgames.com/EasterPile/index.html",
    "youtube": "https://www.youtube.com/watch?v=yFsuxGWaaDU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=EasterPile&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/EasterPile/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/EasterPile/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/EasterPile/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/easterpile200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/easterpile500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/easterpile800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/easterpile1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/easterpile1280.webp"
    },
    {
    "name": "Lucky Clover",
    "category": "Puzzle Games",
    "create_date": "2021-03-11",
    "description": "Remove all the clover from the field. Click on clover to let it grow. When you grow a 4 leaf clover it shoots leafs that grows other clover. Use as less moves as possible to remove all your clover.",
    "url": "https://cdn.htmlgames.com/LuckyClover/index.html",
    "youtube": "https://www.youtube.com/watch?v=9YxncRBYWYo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=LuckyClover&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/LuckyClover/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/LuckyClover/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/LuckyClover/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/luckyclover200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/luckyclover500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/luckyclover800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/luckyclover1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/luckyclover1280720.webp"
    },
    {
    "name": "Euchre",
    "category": "Card Games",
    "create_date": "2021-03-11",
    "description": "Play North American Euchre or British Euchre. Try to win a Euchre game with 3 computer opponents. If you play you need to win 3 hands or more.",
    "url": "https://cdn.htmlgames.com/Euchre/index.html",
    "youtube": "https://www.youtube.com/watch?v=HaurGHSz5nc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Euchre&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Euchre/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Euchre/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Euchre/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/euchre200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/euchre500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/euchre800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/euchre1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/euchre1280720.webp"
    },
    {
    "name": "Original Mahjongg",
    "category": "Mahjong Solitaire",
    "create_date": "2021-03-04",
    "description": "The original Mahjong Solitaire game. Combine two of the same free tiles and try to remove all tiles.",
    "url": "https://cdn.htmlgames.com/OriginalMahjongg/index.html",
    "youtube": "https://www.youtube.com/watch?v=1coLusy5Jec",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=OriginalMahjongg&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/OriginalMahjongg/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/OriginalMahjongg/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/OriginalMahjongg/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/originalmahjongg200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/originalmahjongg500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/originalmahjongg800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/originalmahjongg1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/originalmahjongg1280720.webp"
    },
    {
    "name": "Color Puzzle",
    "category": "Puzzle Games",
    "create_date": "2021-02-25",
    "description": "Move colored tiles to mix the colors and reach the indicated color.",
    "url": "https://cdn.htmlgames.com/ColorPuzzle/index.html",
    "youtube": "https://www.youtube.com/watch?v=_xBQz3H8G-g",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ColorPuzzle&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ColorPuzzle/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ColorPuzzle/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ColorPuzzle/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/colorpuzzle200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/colorpuzzle500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/colorpuzzle800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/colorpuzzle1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/colorpuzzle1280720.webp"
    },
    {
    "name": "Match 3D",
    "category": "3D Mahjong",
    "create_date": "2021-02-25",
    "description": "Match two of the same 3D objects and remove all objects as fast as possible.",
    "url": "https://cdn.htmlgames.com/Match3D/index.html",
    "youtube": "https://www.youtube.com/watch?v=L1d3RJJ_2I4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Match3D&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Match3D/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Match3D/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Match3D/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/match3D200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/match3D500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/match3D800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/match3D1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/match3D1280.webp"
    },
    {
    "name": "Endless Break Out",
    "category": "Arkanoid",
    "create_date": "2021-02-18",
    "description": "Remove the bricks in this endless Break Out Game. Bounce the ball with the paddle and try stay alive.",
    "url": "https://cdn.htmlgames.com/EndlessBreakOut/index.html",
    "youtube": "https://www.youtube.com/watch?v=fytEQy03fEM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=EndlessBreakOut&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/EndlessBreakOut/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/EndlessBreakOut/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/EndlessBreakOut/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/endlessbreakout200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/endlessbreakout500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/endlessbreakout800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/endlessbreakout1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/endlessbreakout1280720.webp"
    },
    {
    "name": "Jungle Pyramid Solitaire",
    "category": "Pyramid",
    "create_date": "2021-02-18",
    "description": "A Pyramid Solitaire in the Jungle. Remove all cards by selecting 2 free cards that have a total value of 13 (K=13, Q=12, J=11, A=1).",
    "url": "https://cdn.htmlgames.com/JunglePyramidSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=jw2BE1fKbtA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JunglePyramidSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JunglePyramidSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JunglePyramidSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JunglePyramidSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/junglepyramidsolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/junglepyramidsolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/junglepyramidsolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/junglepyramidsolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/junglepyramidsolitaire1280720.webp"
    },
    {
    "name": "Alien Slide",
    "category": "Mahjong Slide",
    "create_date": "2021-02-17",
    "description": "Let the same aliens collide and remove all aliens. Drag an alien to move it and slide two aliens next to each other to collide and remove the aliens.",
    "url": "https://cdn.htmlgames.com/AlienSlide/index.html",
    "youtube": "https://www.youtube.com/watch?v=cS_HydHNNDc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=AlienSlide&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/AlienSlide/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/AlienSlide/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/AlienSlide/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/alienslide200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/alienslide500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/alienslide800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/alienslide1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/alienslide1280.webp"
    },
    {
    "name": "Hidden Flowers",
    "category": "Match 3 Games",
    "create_date": "2021-02-15",
    "description": "Match 3 and hidden object game.  First find all the hidden flowers and then match 3 or more of the same flowers.",
    "url": "https://cdn.htmlgames.com/HiddenFlowers/index.html",
    "youtube": "https://www.youtube.com/watch?v=tHfOxIEr1ig",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HiddenFlowers&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HiddenFlowers/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HiddenFlowers/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HiddenFlowers/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hiddenflowers200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hiddenflowers500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hiddenflowers800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hiddenflowers1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hiddenflowers1280720.webp"
    },
    {
    "name": "Color Pixel Link",
    "category": "Puzzle Games",
    "create_date": "2021-02-11",
    "description": "Connect the numbers and paint a pix. Connect two of the same numbers with a path of the length of that number and solve the puzzle to paint a picture.",
    "url": "https://cdn.htmlgames.com/ColorPixelLink/index.html",
    "youtube": "https://www.youtube.com/watch?v=TKFNgRZ6pow",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ColorPixelLink&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ColorPixelLink/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ColorPixelLink/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ColorPixelLink/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/colorpixellink200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/colorpixellink500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/colorpixellink800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/colorpixellink1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/colorpixellink1280720.webp"
    },
    {
    "name": "Cupid Bubble",
    "category": "Bubble Shooter",
    "create_date": "2021-02-05",
    "description": "Top down Bubble Shooter game for Valentine. Drop bubbles down and create groups of 3 or more the same connected bubbles. Remove all bubbles to advance to the next level.",
    "url": "https://cdn.htmlgames.com/CupidBubble/index.html",
    "youtube": "https://www.youtube.com/watch?v=LV0JfkfpJYo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CupidBubble&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CupidBubble/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CupidBubble/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CupidBubble/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/cupidbubble200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/cupidbubble500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/cupidbubble800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/cupidbubble1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/cupidbubble1280.webp"
    },
    {
    "name": "Bee English",
    "category": "Word Games",
    "create_date": "2021-02-03",
    "description": "Create valid English Words in this Bee word game. Drag over letters and connect letters to create words.",
    "url": "https://cdn.htmlgames.com/BeeEnglish/index.html",
    "youtube": "https://www.youtube.com/watch?v=GnJmzLHzu4Y",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BeeEnglish&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BeeEnglish/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BeeEnglish/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BeeEnglish/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/beeenglish200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/beeenglish500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/beeenglish800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/beeenglish1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/beeenglish1280720.webp"
    },
    {
    "name": "Xiangqi",
    "category": "Board",
    "create_date": "2021-01-28",
    "description": "Chinese Chess board game against a computer opponent. To win you need to capture the general of your opponent.",
    "url": "https://cdn.htmlgames.com/Xiangqi/index.html",
    "youtube": "https://www.youtube.com/watch?v=jgFFnyKCu-c",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Xiangqi&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Xiangqi/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Xiangqi/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Xiangqi/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/xiangqi200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/xiangqi500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/xiangqi800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/xiangqi1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/xiangqi1280720.webp"
    },
    {
    "name": "Farm Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2021-01-26",
    "description": "Match the same tiles in this Farm Mahjong Solitaire game. You can combine two of the same free tiles. Remove all tiles to advance to the next level.",
    "url": "https://cdn.htmlgames.com/FarmMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=El2IOrINM_M",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FarmMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FarmMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FarmMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FarmMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/farmmahjongg200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/farmmahjong500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/farmmahjong800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/farmmahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/farmmahjong1280.webp"
    },
    {
    "name": "Mahjongg Titans",
    "category": "Mahjong Solitaire",
    "create_date": "2021-01-21",
    "description": "Classic Mahjongg Titans game with the exploding vulcano. Combine two of the same free tiles to remove the tiles.",
    "url": "https://cdn.htmlgames.com/MahjonggTitans/index.html",
    "youtube": "https://www.youtube.com/watch?v=rHLXvh2CThU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjonggTitans&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjonggTitans/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjonggTitans/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjonggTitans/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongtitans200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongtitans500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongg-titans.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjonggtitans1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjonggtitans1280720.webp"
    },
    {
    "name": "La Belle Lucie",
    "category": "Klondike",
    "create_date": "2021-01-20",
    "description": "Move all cards to the foundations in this classic Fan Solitaire variation. On the tableau you can move a top card onto another top card if it's the same color and in descending order.",
    "url": "https://cdn.htmlgames.com/LaBelleLucie/index.html",
    "youtube": "https://www.youtube.com/watch?v=yf6CRBZD43w",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=LaBelleLucie&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/LaBelleLucie/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/LaBelleLucie/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/LaBelleLucie/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/labellelucie200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/labellelucie500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/labellelucie800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/labellelucie1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/labellelucie1280720.webp"
    },
    {
    "name": "Giant Slalom",
    "category": "Sports",
    "create_date": "2021-01-18",
    "description": "Giant Slalom winter game. Move left and right and ski right of the red gate and left of the blue gate.",
    "url": "https://cdn.htmlgames.com/GiantSlalom/index.html",
    "youtube": "https://www.youtube.com/watch?v=p-iOAyVQ5rw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=GiantSlalom&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/GiantSlalom/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/GiantSlalom/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/GiantSlalom/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/giantslalom200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/giantslalom500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/giantslalom800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/giantslalom1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/giantslalom1280.webp"
    },
    {
    "name": "Word Mahjong",
    "category": "Word Games",
    "create_date": "2021-01-18",
    "description": "Make valid English words in this Word Mahjong game. Select free tiles to make valid words.",
    "url": "https://cdn.htmlgames.com/WordMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=3uNNWgbRZqM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=WordMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/WordMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/WordMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/WordMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/wordmahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/wordmahjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/wordmahjong800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/wordmahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/wordmahjong1280720.webp"
    },
    {
    "name": "Office Hidden Objects",
    "category": "Hidden Object Games",
    "create_date": "2021-01-13",
    "description": "Find all the objects hidden in the Office. Click on an object when you find it.",
    "url": "https://cdn.htmlgames.com/OfficeHiddenObjects/index.html",
    "youtube": "https://www.youtube.com/watch?v=k2Vp1SiUX4g",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=OfficeHiddenObjects&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/OfficeHiddenObjects/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/OfficeHiddenObjects/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/OfficeHiddenObjects/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/officehiddenobjects200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/officehiddenobjects300200.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/officehiddenobjects800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/officehiddenobjects1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/officehiddenobjects1280720.webp"
    },
    {
    "name": "3 Pyramid Tripeaks",
    "category": "Tripeaks & Golf",
    "create_date": "2021-01-13",
    "description": "Fun scrolling Tripeaks Solitaire game in Ancient Egypt. Remove all cards by playing cards that are 1 higher or 1 lower in value then the open card (bottom right).",
    "url": "https://cdn.htmlgames.com/3PyramidTripeaks/index.html",
    "youtube": "https://www.youtube.com/watch?v=OtJ8_PZ5B4Q",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=3PyramidTripeaks&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/3PyramidTripeaks/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/3PyramidTripeaks/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/3PyramidTripeaks/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/3pyramidtripeaks200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/3pyramidtripeaks500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/3pyramidtripeaks800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/3pyramidtripeaks1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/3pyramidtripeaks1280720.webp"
    },
    {
    "name": "Tangram Bird",
    "category": "Puzzle Games",
    "create_date": "2021-01-11",
    "description": "Solve the Tangram Puzzles. Move pieces on the screen and try to recreate the image.",
    "url": "https://cdn.htmlgames.com/TangramBird/index.html",
    "youtube": "https://www.youtube.com/watch?v=tA7hr8Q2Rr8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TangramBird&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TangramBird/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TangramBird/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TangramBird/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tangrambird200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tangrambird500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tangrambird800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tangrambird1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tangrambird1280720.webp"
    },
    {
    "name": "Discover Istanbul",
    "category": "Connect 3",
    "create_date": "2021-01-06",
    "description": "Discover Istanbul in this beautiful Match 3 Game. You can swap between 3 game modes: click, swap and connect. In the first stage you have to remove all backgrounds and in the second stage you must guide the key to the hole. You can rotate the board to help you.",
    "url": "https://cdn.htmlgames.com/DiscoverIstanbul/index.html",
    "youtube": "https://www.youtube.com/watch?v=_HF1QYhwx6o",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DiscoverIstanbul&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DiscoverIstanbul/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DiscoverIstanbul/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DiscoverIstanbul/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/discoveristanbul200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/discoveristanbul500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/discoveristanbul800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/discoveristanbul1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/discoveristanbul1280.webp"
    },
    {
    "name": "Life Sudoku",
    "category": "Sudoku",
    "create_date": "2021-01-05",
    "description": "Everyday 4 new Sudoku puzzles and an archive of a month. Solve the puzzles using the Sudoku rules. Every number of 1-9 can only occur once in every row, column and 9x9 box.",
    "url": "https://cdn.htmlgames.com/LifeSudoku/index.html",
    "youtube": "https://www.youtube.com/watch?v=MD5PKzgk6fk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=LifeSudoku&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/LifeSudoku/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/LifeSudoku/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/LifeSudoku/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/lifesudoku200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/lifesudoku500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/lifesudoku800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/lifesudoku1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/lifesudoku1280720.webp"
    },
    {
    "name": "Tower of Hanoi Solitaire",
    "category": "Card Games",
    "create_date": "2020-12-21",
    "description": "Tower of Hanoi puzzle as card game. Move all cards in a single column from 9 descending down to A. You can only move a card onto a higher ranked card or an empty column.",
    "url": "https://cdn.htmlgames.com/TowerOfHanoiSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=2JoekdXod-k",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TowerOfHanoiSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TowerOfHanoiSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TowerOfHanoiSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TowerOfHanoiSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/TowerofHanoiSolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/TowerofHanoiSolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/TowerofHanoiSolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/towerofhanoisolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/TowerofHanoiSolitaire1280720.webp"
    },
    {
    "name": "Christmas Mysteries",
    "category": "Hidden Object Games",
    "create_date": "2020-12-17",
    "description": "Find all the hidden objects in this Christmas Mystery game. Click to find an object or differences.",
    "url": "https://cdn.htmlgames.com/ChristmasMysteries/index.html",
    "youtube": "https://www.youtube.com/watch?v=E_l7L-ylxyA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ChristmasMysteries&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ChristmasMysteries/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ChristmasMysteries/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ChristmasMysteries/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/christmasmysteries200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/christmasmysteries500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/christmasmysteries800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/christmasmysteries1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/christmasmysteries1280720.webp"
    },
    {
    "name": "Mahjong Connect",
    "category": "Mahjong Connect",
    "create_date": "2020-12-15",
    "description": "The classic Mahjong Connect game now in html5. Connect two of the same free tiles to remove the tiles. The connecting path cannot change direction more than 5 times.",
    "url": "https://cdn.htmlgames.com/MahjongConnect/index.html",
    "youtube": "https://www.youtube.com/watch?v=jNPg_xg4tYA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongConnect&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongConnect/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongConnect/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongConnect/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongconnect200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongconnect500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongconnect800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongconnect1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongconnect1280.webp"
    },
    {
    "name": "Daily Str8ts",
    "category": "Daily Puzzles",
    "create_date": "2020-12-10",
    "description": "Everyday a new Str8ts puzzle game. A 9x9 grid is divided by black cells into compartments. Each compartment, vertically or horizontally, must contain a straight, a set of consecutive numbers, but in any order. For example: 7, 6, 4, 5 is valid, but 1, 3, 8, 7 is not. Fill the remaining white cells with numbers 1 to 9 such that each row and column contains unique digits. Additional clues are set in some of the black cells, these numbers remove that digit as an option in the row and column. Such digits do not form part of any straight.",
    "url": "https://cdn.htmlgames.com/DailyStr8ts/index.html",
    "youtube": "https://www.youtube.com/watch?v=QrjQrcQkjgc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyStr8ts&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyStr8ts/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyStr8ts/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyStr8ts/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dailystr8ts200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dailystr8ts500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dailystr8ts800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dailystr8ts1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dailystr8ts1280.webp"
    },
    {
    "name": "Coloring for Kids",
    "category": "Retro",
    "create_date": "2020-12-09",
    "description": "Fun Coloring game for kids. Select a drawing and start coloring. Save or print your work.",
    "url": "https://cdn.htmlgames.com/ColoringForKids/index.html",
    "youtube": "https://www.youtube.com/watch?v=_JF4THudH8Y",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ColoringForKids&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ColoringForKids/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ColoringForKids/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ColoringForKids/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/coloringforkids200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/coloringforkids500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/coloringforkids800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/coloringforkids1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/coloringforkids1280.webp"
    },
    {
    "name": "Mahjong Connect Jungle",
    "category": "Mahjong Connect",
    "create_date": "2020-12-09",
    "description": "Remove all tiles from the Jungle. Connect two of the same tiles with a path that has no more than two 90 degree angles to remove the tiles.",
    "url": "https://cdn.htmlgames.com/MahjongConnectJungle/index.html",
    "youtube": "https://www.youtube.com/watch?v=DbVubuhnoUk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongConnectJungle&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongConnectJungle/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongConnectJungle/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongConnectJungle/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongconnectjungle200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongconnectjungle500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongconnectjungle800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongconnectjungle1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongconnectjungle1280720.webp"
    },
    {
    "name": "Pizza Shop",
    "category": "Time management",
    "create_date": "2020-12-07",
    "description": "Serve the best pizzas in your shop. Take and serve the correct orders to your customers.",
    "url": "https://cdn.htmlgames.com/PizzaShop/index.html",
    "youtube": "https://www.youtube.com/watch?v=aCbY893L_VU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PizzaShop&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PizzaShop/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PizzaShop/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PizzaShop/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/pizzashop200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/pizzashop500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/pizzashop800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/pizzashop1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/pizzashop1280.webp"
    },
    {
    "name": "Winter Bubble",
    "category": "Bubble Shooter",
    "create_date": "2020-12-03",
    "description": "Ice cold bubble shooter game. Shoot up bubbles and match 3 or more of the same bubbles.",
    "url": "https://cdn.htmlgames.com/WinterBubble/index.html",
    "youtube": "https://www.youtube.com/watch?v=nVd-VtJzh1U",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=WinterBubble&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/WinterBubble/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/WinterBubble/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/WinterBubble/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/winterbubble200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/winterbubble500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/winterbubble800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/winterbubble1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/winterbubble1280720.webp"
    },
    {
    "name": "Mahjongg Journey",
    "category": "Mahjong Solitaire",
    "create_date": "2020-12-01",
    "description": "Embark in your Journey around the world in this Mahjongg game. Combine two of the same free tiles to remove the tiles. In the Challenge game you have to collide two of the same tiles.",
    "url": "https://cdn.htmlgames.com/MahjonggJourney/index.html",
    "youtube": "https://www.youtube.com/watch?v=pvkkY7dBk0I",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjonggJourney&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjonggJourney/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjonggJourney/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjonggJourney/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjonggjourney200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjonggjourney500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjonggjourney800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjonggjourney1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjonggjourney1280720.webp"
    },
    {
    "name": "Paris Hidden Objects",
    "category": "Hidden Object Games",
    "create_date": "2020-11-25",
    "description": "Find all the hidden objects in Paris. Click on the objects. You can zoom in. Use the light bulb for a hint.",
    "url": "https://cdn.htmlgames.com/ParisHiddenObjects/index.html",
    "youtube": "https://www.youtube.com/watch?v=JpVzrLWZZYE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ParisHiddenObjects&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ParisHiddenObjects/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ParisHiddenObjects/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ParisHiddenObjects/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/parishiddenobjects200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/parishiddenobjects500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/parishiddenobjects800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/parishiddenobjects1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/parishiddenobjects1280720.webp"
    },
    {
    "name": "Math Search",
    "category": "Math Games",
    "create_date": "2020-11-24",
    "description": "Solve the Math and search the answer. Solve the math equasions and find the answer on the grid. Drag to mark the answer.",
    "url": "https://cdn.htmlgames.com/MathSearch/index.html",
    "youtube": "https://www.youtube.com/watch?v=XTpEtPLv2Cs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MathSearch&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MathSearch/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MathSearch/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MathSearch/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mathsearch200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mathsearch500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mathsearch800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mathsearch1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mathsearch1280.webp"
    },
    {
    "name": "Stone Symbols",
    "category": "Collapse Games",
    "create_date": "2020-11-19",
    "description": "Reveal all stones in this Match 3 game. Click on groups of 3 or more of the same stones",
    "url": "https://cdn.htmlgames.com/StoneSymbols/index.html",
    "youtube": "https://www.youtube.com/watch?v=X8gIl9VLKyI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=StoneSymbols&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/StoneSymbols/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/StoneSymbols/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/StoneSymbols/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/stonesymbols200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/stonesymbols500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/stonesymbols800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/stonesymbols1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/stonesymbols1280720.webp"
    },
    {
    "name": "Yukon Freecell",
    "category": "Klondike",
    "create_date": "2018-09-17",
    "description": "Move all cards to the four foundations in this Yukon Freecell cross over game. On the tableau you can move cards and groups of cards. A group does not need to have an order, except that the starting and target cards must be built in sequence and in alternate color. Use the Freecells to temporarily park a card.",
    "url": "https://cdn.htmlgames.com/YukonFreecell/index.html",
    "youtube": "https://www.youtube.com/watch?v=9xfIN9FSfe0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=YukonFreecell&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/YukonFreecell/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/YukonFreecell/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/YukonFreecell/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/yukonfreecell200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/yukonfreecell500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/yukonfreecell800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/yukonfreecell1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/yukonfreecell1280720.webp"
    },
    {
    "name": "Mahjong Tower",
    "category": "Mahjong Tower",
    "create_date": "2020-11-10",
    "description": "Mahjong Solitaire with tower levels. Remove all tiles from the Mahjong Tower by selecting two of the same free tiles.",
    "url": "https://cdn.htmlgames.com/MahjongTower/index.html",
    "youtube": "https://www.youtube.com/watch?v=LXHOVMl7hkI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongTower&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongTower/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongTower/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongTower/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongtower200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongtower500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongtower800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongtower1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongtower1280720.webp"
    },
    {
    "name": "Retro Bricks",
    "category": "Tetris",
    "create_date": "2020-11-05",
    "description": "Tetris game in retro style. Drop down bricks and complete full horizontal lines.",
    "url": "https://cdn.htmlgames.com/RetroBricks/",
    "youtube": "https://www.youtube.com/watch?v=4J4-QEpZr8I",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=RetroBricks&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/RetroBricks/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/RetroBricks/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/RetroBricks/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/retrobricks200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/retrobricks500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/retrobricks800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/retrobricks1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/retrobricks1280720.webp"
    },
    {
    "name": "The Lost World",
    "category": "Mahjong Games",
    "create_date": "2020-11-02",
    "description": "Discover the Lost World in this Mahjong Solitaire game. Combine two of the same free tiles to remove the tiles from the game. Free tiles are highlighted.",
    "url": "https://cdn.htmlgames.com/TheLostWorld/",
    "youtube": "https://www.youtube.com/watch?v=c_8CkYb_YKQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TheLostWorld&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TheLostWorld/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TheLostWorld/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TheLostWorld/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/thelostworld200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/thelostworld500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/thelostworld800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/thelostworld1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/thelostworld200120.webp"
    },
    {
    "name": "Spot the UFO",
    "category": "Hidden Object Games",
    "create_date": "2020-10-29",
    "description": "Spot new UFOs appearing in the sky. Click on newly added UFOs.",
    "url": "https://cdn.htmlgames.com/SpotTheUFO/",
    "youtube": "https://www.youtube.com/watch?v=Lm6fQre-LlA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SpotTheUFO&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SpotTheUFO/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SpotTheUFO/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SpotTheUFO/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/spottheufo200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/spottheufo500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/spottheufo800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/spottheUFO1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/spottheufo1280.webp"
    },
    {
    "name": "Block Town",
    "category": "Puzzle Games",
    "create_date": "2020-10-26",
    "description": "Move all the wooden blocks to fill a shape. Click to rotate a block and drag to move a block.",
    "url": "https://cdn.htmlgames.com/BlockTown/index.html",
    "youtube": "https://www.youtube.com/watch?v=BWxAzYwSp1Q",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BlockTown&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BlockTown/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BlockTown/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BlockTown/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/blocktown200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tetristown500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tetristown800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/blocktown1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tetristown1280.webp"
    },
    {
    "name": "Mahjong Cubes",
    "category": "3D Mahjong",
    "create_date": "2020-10-15",
    "description": "Mahjong game with 3D Cubes. Remove all cubes by pairing the same cubes. A free cube as no cube on top.",
    "url": "https://cdn.htmlgames.com/MahjongCubes/",
    "youtube": "https://www.youtube.com/watch?v=alKyQ1u14g0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongCubes&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongCubes/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongCubes/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongCubes/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongcubes200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongcubes500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongcubes800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongcubes1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongcubes1280720.webp"
    },
    {
    "name": "Halloween Bubble Shooter",
    "category": "Bubble Shooter",
    "create_date": "2020-10-13",
    "description": "100 bubble shooter levels for Halloween. Shoot up bubbles and match three or more. Remove all bubbles.",
    "url": "https://cdn.htmlgames.com/HalloweenBubbleShooter/index.html",
    "youtube": "https://www.youtube.com/watch?v=eE9dbJXgkJs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HalloweenBubbleShooter&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HalloweenBubbleShooter/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HalloweenBubbleShooter/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HalloweenBubbleShooter/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/halloweenbubbleshooter200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/halloweenbubbleshooter500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/halloweenbubbleshooter800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/halloweenbubbleshooter1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/halloweenbubbleshooter1280720.webp"
    },
    {
    "name": "Rocket Tap",
    "category": "Shooting & War",
    "create_date": "2020-10-06",
    "description": "Tap to shoot rockets and hit the target. Hit the stars for extra points.",
    "url": "https://cdn.htmlgames.com/RocketTap/index.html",
    "youtube": "https://www.youtube.com/watch?v=EHk-NG12Xdo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=RocketTap&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/RocketTap/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/RocketTap/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/RocketTap/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/rockettap200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/rockettap500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/rockettap800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/rockettap1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/rockettap1280.webp"
    },
    {
    "name": "Sea Tower Solitaire",
    "category": "Freecell",
    "create_date": "2020-10-05",
    "description": "Seahaven Towers Solitaire, move all cards to the foundations from Ace to King. On the tableau you must build down in suit. Every new level is more difficult restricting the number of cards you can move or what the starting card of an empty column must be.",
    "url": "https://cdn.htmlgames.com/SeaTowerSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=oYISGaoUU-E",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SeaTowerSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SeaTowerSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SeaTowerSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SeaTowerSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/seatowersolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/seatowersolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/seatowersolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/seatowersolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/seatowersolitaire1280720.webp"
    },
    {
    "name": "Daily Shirokuro",
    "category": "Daily Puzzles",
    "create_date": "2020-09-24",
    "description": "Play Shirokuro puzzles everyday in 3 different sizes. Connect all dots by connecting one white dot with one black dot. Paths cannot cross.",
    "url": "https://cdn.htmlgames.com/DailyShirokuro/index.html",
    "youtube": "https://www.youtube.com/watch?v=jBdFIp1qrNU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyShirokuro&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyShirokuro/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyShirokuro/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyShirokuro/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dailyshirokuro200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dailyshirokuro500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dailyshirokuro800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dailyshirokuro1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dailyshirokuro1280.webp"
    },
    {
    "name": "Caribbean Slide",
    "category": "Mahjong Slide",
    "create_date": "2020-09-21",
    "description": "Enjoy a Caribbean Adventure in this Mahjong Game. Slide two of the same Mahjong tiles next to each other to make them dissappear. Remove all tiles to advance to the next level.",
    "url": "https://cdn.htmlgames.com/CaribbeanSlide/index.html",
    "youtube": "https://www.youtube.com/watch?v=TJDnkH6bS8s",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CaribbeanSlide&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CaribbeanSlide/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CaribbeanSlide/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CaribbeanSlide/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/caribbeanslide200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/caribbeanslide500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/caribbeanslide800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/caribbeanslide1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/caribbeanslide1280.webp"
    },
    {
    "name": "Kings Gold",
    "category": "Match 3 Games",
    "create_date": "2020-09-18",
    "description": "Fun and Fast Match 3 game, match the Kings Gold. Swap two neighbouring items to get three or more in a row.",
    "url": "https://cdn.htmlgames.com/KingsGold/index.html",
    "youtube": "https://www.youtube.com/watch?v=fbkwyhX67O8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=KingsGold&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/KingsGold/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/KingsGold/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/KingsGold/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/kingsgold200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/kingsgold500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/kingsgold800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/kingsgold1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/kingsgold1280.webp"
    },
    {
    "name": "Break Tris",
    "category": "Tetris",
    "create_date": "2020-09-15",
    "description": "Break Out and Tetris combined into one game. First complete horizontal lines and in the next stage shoot at the remaining bricks.",
    "url": "https://cdn.htmlgames.com/BreakTris/index.html",
    "youtube": "https://www.youtube.com/watch?v=jo5xJQivqgw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BreakTris&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BreakTris/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BreakTris/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BreakTris/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bricktetris200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bricktetris500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bricktetris800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/bricktetris1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/bricktetris1280720.webp"
    },
    {
    "name": "Flower World",
    "category": "Match 3 Games",
    "create_date": "2020-09-08",
    "description": "Match 3 or more of the same flowers. Swap 2 flower and match 3 or more in a row. Reach the indicated goals.",
    "url": "https://cdn.htmlgames.com/FlowerWorld/index.html",
    "youtube": "https://www.youtube.com/watch?v=6fNPB9az7Zk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FlowerWorld&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FlowerWorld/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FlowerWorld/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FlowerWorld/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/flowerworld200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/flowerworld500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/flowerworld800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/flowerworld1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/flowerworld1280.webp"
    },
    {
    "name": "Bug Connect",
    "category": "Mahjong Connect",
    "create_date": "2020-09-03",
    "description": "Terminate all bugs in this Mahjong Connect Game. Connect two of the same bugs to remove the bugs. Connect the pesticide to remove all bugs at once.",
    "url": "https://cdn.htmlgames.com/BugConnect/index.html",
    "youtube": "https://www.youtube.com/watch?v=R1IYd_BxA58",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BugConnect&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BugConnect/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BugConnect/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BugConnect/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bugconnect200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bugconnect500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bugconnect800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/bugconnect1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/bugconnect1280720.webp"
    },
    {
    "name": "Rummi",
    "category": "Card Games",
    "create_date": "2020-08-31",
    "description": "Tile-based Rummy game for 2 players. Try to get all your tiles on the board creating groups and runs.",
    "url": "https://cdn.htmlgames.com/Rummi/index.html",
    "youtube": "https://www.youtube.com/watch?v=FyCGC5GHnO8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Rummi&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Rummi/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Rummi/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Rummi/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/rummi200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/rummi500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/rummi800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/rummi1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/rummi1280720.webp"
    },
    {
    "name": "Simon Says",
    "category": "Memory",
    "create_date": "2020-08-27",
    "description": "Repeat the pattern played by the Computer. Click on the colored buttons in the same order as the computer plays it for you.",
    "url": "https://cdn.htmlgames.com/SimonSays/index.html",
    "youtube": "https://www.youtube.com/watch?v=lqfOdN5iRMc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SimonSays&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SimonSays/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SimonSays/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SimonSays/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/simonsays200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/simonsays500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/simonsays800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/simonsays1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/simonsays1280720.webp"
    },
    {
    "name": "Bugs Kyodai",
    "category": "Mahjong Connect",
    "create_date": "2020-08-27",
    "description": "Mahjong Connect game with Bugs. Connect two of the same Bugs. The connecting path cannot change direction more than two times.",
    "url": "https://cdn.htmlgames.com/BugsKyodai/index.html",
    "youtube": "https://www.youtube.com/watch?v=J7vzJJMhhLY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BugsKyodai&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BugsKyodai/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BugsKyodai/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BugsKyodai/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bugskyodai200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bugskyodai500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bugskyodai800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/bugskyodai1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/bugskyodai1280720.webp"
    },
    {
    "name": "Stolen Art",
    "category": "Puzzle Games",
    "create_date": "2020-08-24",
    "description": "Guess the code and steal the art. Use the hints to guess the code as fast as possible.",
    "url": "https://cdn.htmlgames.com/StolenArt/index.html",
    "youtube": "https://www.youtube.com/watch?v=1C5Mpi-Pi0c",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=StolenArt&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/StolenArt/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/StolenArt/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/StolenArt/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/stolenart200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/stolenart500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/stolenart800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/stolenart1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/stolenart1280.webp"
    },
    {
    "name": "Railway Mysteries",
    "category": "Hidden Object Games",
    "create_date": "2020-08-20",
    "description": "Find all the hidden objects in this Railway Mystery game. Click to find an object or difference.",
    "url": "https://cdn.htmlgames.com/RailwayMysteries/index.html",
    "youtube": "https://www.youtube.com/watch?v=o6TGHyCj6ps",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=RailwayMysteries&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/RailwayMysteries/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/RailwayMysteries/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/RailwayMysteries/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/railwaymysteries200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/railwaymysteries500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/railwaymysteries800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/railwaymysteries1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/railwaymysteries1280720.webp"
    },
    {
    "name": "Tetris Fun",
    "category": "Tetris",
    "create_date": "2020-08-17",
    "description": "80 levels of Tetris Fun. Remove the shapes by completing lines with the falling tetris shapes.",
    "url": "https://cdn.htmlgames.com/TetrisFun/index.html",
    "youtube": "https://www.youtube.com/watch?v=cUWqc3SYBGg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TetrisFun&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TetrisFun/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TetrisFun/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TetrisFun/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tetrisfun200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tetrisfun500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tetrisfun800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tetrisfun1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tetrisfun1280720.webp"
    },
    {
    "name": "Two Cell",
    "category": "Freecell",
    "create_date": "2020-08-12",
    "description": "Freecell game with only two Free cells. Try to move all cards to the foundations. On the tableau build down on alternating color.",
    "url": "https://cdn.htmlgames.com/TwoCell/index.html",
    "youtube": "https://www.youtube.com/watch?v=OMYlf3kz-Ro",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TwoCell&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TwoCell/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TwoCell/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TwoCell/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/twocell200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/twocell500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/twocell800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/twocell1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/twocell1280720.webp"
    },
    {
    "name": "Monochrome Mahjongg",
    "category": "Mahjong Solitaire",
    "create_date": "2020-07-20",
    "description": "Mahjongg Solitaire with black and white tiles. Combine the same black tile with the same white tile to remove the tiles. Clear all tiles to advance to the next level.",
    "url": "https://cdn.htmlgames.com/MonochromeMahjongg/index.html",
    "youtube": "https://www.youtube.com/watch?v=O8LVGMvYkyM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MonochromeMahjongg&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MonochromeMahjongg/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MonochromeMahjongg/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MonochromeMahjongg/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/monochromemahjongg200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/monochromemahjongg500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/monochromemahjongg800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/monochromemahjongg1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/monochromemahjongg1280.webp"
    },
    {
    "name": "Paris Tripeaks",
    "category": "Tripeaks & Golf",
    "create_date": "2020-07-27",
    "description": "Enjoy Paris in this fun Tripeaks Solitaire game. Remove all cards from the game. You can remove a card that is 1 higher or 1 lower in value than the open card.",
    "url": "https://cdn.htmlgames.com/ParisTripeaks/index.html",
    "youtube": "https://www.youtube.com/watch?v=4R92vXxM838",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ParisTripeaks&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ParisTripeaks/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ParisTripeaks/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ParisTripeaks/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/paristripeaks200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/paristripeaks500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/paristripeaks800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/paristripeaks1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/paristripeaks1280.webp"
    },
    {
    "name": "1010 Classic",
    "category": "Puzzle Games",
    "create_date": "2020-07-20",
    "description": "The classic 1010 game. Move shapes on the grid and complete rows and columns.",
    "url": "https://cdn.htmlgames.com/1010Classic/index.html",
    "youtube": "https://www.youtube.com/watch?v=Jf57kHIA0nQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=1010Classic&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/1010Classic/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/1010Classic/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/1010Classic/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/1010classic200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/1010classic500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/1010classic800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/1010classic1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/1010classic1280.webp"
    },
    {
    "name": "Flower Triple Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2020-07-15",
    "description": "Triple Mahjong with Flowers. Combine three of the same free tiles to remove the tiles.",
    "url": "https://cdn.htmlgames.com/FlowerTripleMahjong/index.html",
    "youtube": null,
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FlowerTripleMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FlowerTripleMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FlowerTripleMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FlowerTripleMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/flowertriplemahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/flowertriplemahjong500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/flowertriplemahjong800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/flowertriplemahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/flowertriplemahjong1280.webp"
    },
    {
    "name": "Crucigramas Del Dia",
    "category": "Crosswords",
    "create_date": "2020-07-09",
    "description": "Daily Crossword Puzzle in the Spanish language. Solve a crossword by entering the solutions of the given horizontal and vertical clues.",
    "url": "https://cdn.htmlgames.com/CrucigramasDelDia/index.html",
    "youtube": "https://www.youtube.com/watch?v=JVMlquVoJ48",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CrucigramasDelDia&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CrucigramasDelDia/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CrucigramasDelDia/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CrucigramasDelDia/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/crucigramasdeldia200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/crucigramasdeldia500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/crucigramasdeldia800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/crucigramasdeldia1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/crucigramasdeldia1280.webp"
    },
    {
    "name": "Dagelijkse Kruiswoord",
    "category": "Crosswords",
    "create_date": "2020-07-09",
    "description": "Daily Crossword Puzzle in the Dutch language. Solve a crossword by entering the solutions of the given horizontal and vertical clues.",
    "url": "https://cdn.htmlgames.com/DagelijkseKruiswoord/index.html",
    "youtube": "https://www.youtube.com/watch?v=JVMlquVoJ48",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DagelijkseKruiswoord&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DagelijkseKruiswoord/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DagelijkseKruiswoord/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DagelijkseKruiswoord/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dagelijksekruiswoord200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dagelijksekruiswoord500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dagelijksekruiswoord800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dagelijksekruiswoord1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dagelijksekruiswoord1280.webp"
    },
    {
    "name": "Tägliches Kreuzwort Rätsel",
    "category": "Crosswords",
    "create_date": "2020-07-09",
    "description": "Daily Crossword Puzzle in the German language. Solve a crossword by entering the solutions of the given horizontal and vertical clues.",
    "url": "https://cdn.htmlgames.com/TäglichesKreuzwortRätsel/index.html",
    "youtube": "https://www.youtube.com/watch?v=JVMlquVoJ48",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TäglichesKreuzwortRätsel&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TäglichesKreuzwortRätsel/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TäglichesKreuzwortRätsel/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TäglichesKreuzwortRätsel/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/taglicheskreuzwort200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/taglicheskreuzwort500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/taglicheskreuzwort800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/taglicheskreuzwort1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/taglicheskreuzwort1280.webp"
    },
    {
    "name": "World Voyage",
    "category": "Match 3 Games",
    "create_date": "2020-07-06",
    "description": "Voyage around the world Match3 game. Remove all square tiles from the game by matching 3 or more of the same items next to the tiles.",
    "url": "https://cdn.htmlgames.com/WorldVoyage/index.html",
    "youtube": "https://www.youtube.com/watch?v=ULURQ7SC4NI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=WorldVoyage&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/WorldVoyage/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/WorldVoyage/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/WorldVoyage/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/worldvoyage200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/worldvoyage500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/worldvoyage800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/worldvoyage1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/worldvoyage1280.webp"
    },
    {
    "name": "Stay in the Dark",
    "category": "Platform",
    "create_date": "2020-07-02",
    "description": "Stay in the dark and kill all the lights. Move around and hit the lights before you are exposed.",
    "url": "https://cdn.htmlgames.com/StayInTheDark/index.html",
    "youtube": "https://www.youtube.com/watch?v=szkdryUNQbE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=StayInTheDark&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/StayInTheDark/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/StayInTheDark/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/StayInTheDark/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/stayinthedark200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/stayinthedark500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/stayinthedark800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/stayinthedark1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/stayinthedark1280720.webp"
    },
    {
    "name": "Mahjong Colors",
    "category": "Mahjong Solitaire",
    "create_date": "2020-06-29",
    "description": "Colorful Mahjong Solitaire game. Combine two of the same free highlighted tiles to remove the tiles. Remove all tiles to advance to the next level.",
    "url": "https://cdn.htmlgames.com/MahjongColors/index.html",
    "youtube": "https://www.youtube.com/watch?v=NgyLIQjrVTg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongColors&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongColors/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongColors/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongColors/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongcolors200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongcolors500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongcolors800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongcolors1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongcolors1280.webp"
    },
    {
    "name": "Double Solitaire",
    "category": "Klondike",
    "create_date": "2020-06-25",
    "description": "Play a Klondike Solitaire game against a computer opponent. Get rid of your cards before your opponent does. Move cards to the foundations from Ace to King. On your tableau you can build down on alternate color.",
    "url": "https://cdn.htmlgames.com/DoubleSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=x8YzSG1P6DQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DoubleSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DoubleSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DoubleSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DoubleSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/doublesolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/doublesolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/doublesolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/doublesolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/doublesolitaire1280720.webp"
    },
    {
    "name": "Whack the Moles",
    "category": "Word Games",
    "create_date": "2020-06-22",
    "description": "Fun desktop only typing game: Whack the Moles. Type the words below a mole to whack it.",
    "url": "https://cdn.htmlgames.com/WhackTheMoles/index.html",
    "youtube": "https://www.youtube.com/watch?v=mz82956xkSo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=WhackTheMoles&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/WhackTheMoles/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/WhackTheMoles/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/WhackTheMoles/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/whackthemoles200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/whackthemoles500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/whackthemoles800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/whackthemoles1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/whackthemoles1280.webp"
    },
    {
    "name": "New York Hidden Objects",
    "category": "Hidden Object Games",
    "create_date": "2020-06-15",
    "description": "Find all the hidden objects in New York. Click on the objects. You can zoom in. Use the light bulb for a hint.",
    "url": "https://cdn.htmlgames.com/NewYorkHiddenObjects/index.html",
    "youtube": "https://www.youtube.com/watch?v=cybLKUpDERM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=NewYorkHiddenObjects&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/NewYorkHiddenObjects/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/NewYorkHiddenObjects/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/NewYorkHiddenObjects/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/newyorkhiddenobjects200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/newyorkhiddenobjects500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/newyorkhiddenobjects800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/newyorkhiddenobjects1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/newyorkhiddenobjects1280720.webp"
    },
    {
    "name": "Jigsaw Jam Cars",
    "category": "Puzzle Games",
    "create_date": "2020-06-11",
    "description": "Complete the Jigsaw Puzzles with cars. Drag the puzzle piece to the right spot. A piece always is attached to an existing piece.",
    "url": "https://cdn.htmlgames.com/JigsawJamCars/index.html",
    "youtube": "https://www.youtube.com/watch?v=m6I5P5033xA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JigsawJamCars&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JigsawJamCars/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JigsawJamCars/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JigsawJamCars/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jigsawjamcars200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jigsawjamcars500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/jigsawjamcars800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/jigsawjamcars1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/jigsawjamcars1280.webp"
    },
    {
    "name": "Daily Numbrix",
    "category": "Daily Puzzles",
    "create_date": "2020-06-08",
    "description": "Everyday 9 new Numbrix levels. Connect the numbers by typing the numbers on the grid. You need to make one chain of numbers connecting horizontally or vertically.",
    "url": "https://cdn.htmlgames.com/DailyNumbrix/index.html",
    "youtube": "https://www.youtube.com/watch?v=TXiwoyR82gQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyNumbrix&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyNumbrix/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyNumbrix/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyNumbrix/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dailynumbrix200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dailynumbrix500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dailynumbrix800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dailynumbrix1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dailynumbrix1280.webp"
    },
    {
    "name": "One Cell",
    "category": "Freecell",
    "create_date": "2020-06-02",
    "description": "Freecell game with only one Free cell. Try to move all cards to the foundations. On the tableau build down on alternating color.",
    "url": "https://cdn.htmlgames.com/OneCell/index.html",
    "youtube": "https://www.youtube.com/watch?v=ieSTqEcx1DI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=OneCell&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/OneCell/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/OneCell/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/OneCell/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/onecell200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/onecell500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/onecell800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/onecell1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/onecell1280720.webp"
    },
    {
    "name": "Hex Mahjong",
    "category": "Mahjong Games",
    "create_date": "2020-06-02",
    "description": "Mahjong Solitaire with hex tiles. Combine two of the same free tiles. Free tiles are highlighted.",
    "url": "https://cdn.htmlgames.com/HexMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=OrEbeF8WRNc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HexMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HexMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HexMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HexMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hexmahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hexmahjong500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hexmahjong800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hexmahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hexmahjong1280.webp"
    },
    {
    "name": "Forest Queen",
    "category": "Bejeweled",
    "create_date": "2020-05-28",
    "description": "Magical match3 game in the forest. Remove all magical items from the game by matching 3 or more of the same jewels below the items.",
    "url": "https://cdn.htmlgames.com/ForestQueen/index.html",
    "youtube": "https://www.youtube.com/watch?v=xq5TME9p670",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ForestQueen&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ForestQueen/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ForestQueen/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ForestQueen/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/forestqueen200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/forestqueen500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/forestqueen800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/forestqueen1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/forestqueen1280.webp"
    },
    {
    "name": "Circus Hidden Numbers",
    "category": "Hidden Alphabet",
    "create_date": "2020-05-25",
    "description": "Find all the hidden numbers as fast as possible. Click on the location of a number.",
    "url": "https://cdn.htmlgames.com/CircusHiddenNumbers/index.html",
    "youtube": "https://www.youtube.com/watch?v=HGYzbkT76cY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CircusHiddenNumbers&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CircusHiddenNumbers/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CircusHiddenNumbers/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CircusHiddenNumbers/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/circushiddennumbers200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/circushiddennumbers500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/circushiddennumbers800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/circushiddennumbers1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/circushiddennumbers1280.webp"
    },
    {
    "name": "Farm Town",
    "category": "Time management",
    "create_date": "2020-05-20",
    "description": "Grow your Farm and your crops. Grow and harvest your crops and research new machines. Click on the truck to sell your crops.",
    "url": "https://cdn.htmlgames.com/FarmTown/index.html",
    "youtube": "https://www.youtube.com/watch?v=ENA-WjjcgN4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FarmTown&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FarmTown/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FarmTown/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FarmTown/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/farmtown200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/farmtown500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/farmtown800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/farmtown1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/farmtown1280.webp"
    },
    {
    "name": "Sweet Candy",
    "category": "Bubble Shooter",
    "create_date": "2020-05-15",
    "description": "Fun Candy Bubble shooter game. Shoot up candy and match 3 or more of the same color.",
    "url": "https://cdn.htmlgames.com/SweetCandy/index.html",
    "youtube": "https://www.youtube.com/watch?v=wFZ3o5pHDPs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SweetCandy&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SweetCandy/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SweetCandy/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SweetCandy/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/sweetcandy200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/sweetcandy500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/sweetcandy800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/sweetcandy1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/sweetcandy1280.webp"
    },
    {
    "name": "Daily Mahjongg",
    "category": "Mahjong Solitaire",
    "create_date": "2020-05-11",
    "description": "Everyday a new Mahjong game. Try to clear the layout by pairing up free tiles.",
    "url": "https://cdn.htmlgames.com/DailyMahjongg/index.html",
    "youtube": "https://www.youtube.com/watch?v=Kp6p2sMzTQE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyMahjongg&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyMahjongg/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyMahjongg/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyMahjongg/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dailymahjongg200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dailymahjongg500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dailymahjongg800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dailymahjongg1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dailymahjongg1280.webp"
    },
    {
    "name": "Tetris Slide",
    "category": "Tetris",
    "create_date": "2020-05-08",
    "description": "Fun Tetris puzzle where you have to slide the rows to fit the pieces. Use the arrows on screen or the arrow keys to move the bottom row and fit in the dropping tetris blocks.",
    "url": "https://cdn.htmlgames.com/TetrisSlide/index.html",
    "youtube": "https://www.youtube.com/watch?v=_U0mh_ZxlIc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TetrisSlide&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TetrisSlide/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TetrisSlide/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TetrisSlide/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tetrisslide200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tetrisslide500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tetrisslide800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tetrisslide1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tetrisslide1280.webp"
    },
    {
    "name": "Algerian Patience",
    "category": "Solitaire Games",
    "create_date": "2020-05-04",
    "description": "Algerian or Algerian Patience is a difficult Solitaire game. Move all the cards to the 8 foundations: 4 down in suit from King and 4 up in suit from Ace. On the tableau build up or down in suit. Click on the closed stack to get new cards.",
    "url": "https://cdn.htmlgames.com/AlgerianPatience/index.html",
    "youtube": "https://www.youtube.com/watch?v=df6NvtrjGyo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=AlgerianPatience&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/AlgerianPatience/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/AlgerianPatience/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/AlgerianPatience/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/algerianpatience200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/algerianpatience500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/algerianpatience800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/algerianpatience1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/algerianpatience1280.webp"
    },
    {
    "name": "Mouse and Cheese",
    "category": "Puzzle Games",
    "create_date": "2020-04-30",
    "description": "Guide the mouse to the cheese. Move objects and create a path to the cheese.",
    "url": "https://cdn.htmlgames.com/MouseAndCheese/index.html",
    "youtube": "https://www.youtube.com/watch?v=-uCTK9AaeBM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MouseAndCheese&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MouseAndCheese/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MouseAndCheese/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MouseAndCheese/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mouseandcheese200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mouseandcheese500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mouseandcheese800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mouseandcheese1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mouseandcheese1280.webp"
    },
    {
    "name": "Black Hole Solitaire",
    "category": "Tripeaks & Golf",
    "create_date": "2020-04-22",
    "description": "Move all the cards away to the black hole in this solitaire game. You can move cards to the black hole if the value of a card is 1 higher or 1 lower than the card in the black hole.",
    "url": "https://cdn.htmlgames.com/BlackHoleSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=rssebRCx7EQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BlackHoleSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BlackHoleSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BlackHoleSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BlackHoleSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/blackholesolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/blackholesolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/blackholesolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/blackholesolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/blackholesolitaire1280720.webp"
    },
    {
    "name": "Daily ZNumbers",
    "category": "Daily Puzzles",
    "create_date": "2020-04-21",
    "description": "The classic zNumbers puzzle game. Move every box with a number on it only once. The box may only be moved by the amount on the box (horizontal, vertical or diagonal).",
    "url": "https://cdn.htmlgames.com/DailyZNumbers/index.html",
    "youtube": "https://www.youtube.com/watch?v=SFfAlGnqm8w",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyZNumbers&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyZNumbers/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyZNumbers/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyZNumbers/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dailyznumbers200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dailyznumbers500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dailyznumbers800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dailyznumbers1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dailyznumbers1280.webp"
    },
    {
    "name": "Kids Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2020-04-15",
    "description": "Enjoy a simple and fun game of Mahjong for Kids. Combine two of the same free tiles to remove the two tiles. A tile is free if it has no neighbour left or right.",
    "url": "https://cdn.htmlgames.com/KidsMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=XkiZ3CqlSAY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=KidsMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/KidsMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/KidsMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/KidsMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/kidsmahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/kidsmahjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/kidsmahjong800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/kidsmahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/kidsmahjong1280720.webp"
    },
    {
    "name": "Mahjong Battle",
    "category": "Mahjong Games",
    "create_date": "2020-04-09",
    "description": "A Mahjong game for two players. Combine two of the same free tiles and get the highest score. Free tiles have a free side at the top or bottom.",
    "url": "https://cdn.htmlgames.com/MahjongBattle/index.html",
    "youtube": "https://www.youtube.com/watch?v=dX2xbaovof8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongBattle&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongBattle/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongBattle/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongBattle/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongbattle200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongbattle500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongbattle800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongbattle1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongbattle1280.webp"
    },
    {
    "name": "Egg Age",
    "category": "Connect 3",
    "create_date": "2020-04-06",
    "description": "Connect three or more of the same eggs. Connect as many Eggs as indicated on the left.",
    "url": "https://cdn.htmlgames.com/EggAge/index.html",
    "youtube": "https://www.youtube.com/watch?v=LCHAhDZtZGc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=EggAge&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/EggAge/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/EggAge/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/EggAge/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/eggage200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/eggage500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/eggage800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/eggage1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/eggage1280.webp"
    },
    {
    "name": "Bounce Ball",
    "category": "Skill",
    "create_date": "2020-03-30",
    "description": "Bounce the ball and collect the stars. Click/tap to move the ball down.",
    "url": "https://cdn.htmlgames.com/BounceBall/index.html",
    "youtube": "https://www.youtube.com/watch?v=7_BkgmgZAvU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BounceBall&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BounceBall/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BounceBall/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BounceBall/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bounceball200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bounceball500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bounceball800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/bounceball1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/bounceball1280.webp"
    },
    {
    "name": "Trick Ball",
    "category": "Billiards",
    "create_date": "2020-03-27",
    "description": "Remove all balls from the table by colliding the same balls.",
    "url": "https://cdn.htmlgames.com/TrickBall/index.html",
    "youtube": "https://www.youtube.com/watch?v=bl1d6E0Raro",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TrickBall&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TrickBall/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TrickBall/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TrickBall/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/trickball200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/trickball500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/trickball800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/trickball1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/trickball1280720.webp"
    },
    {
    "name": "Planet Solitaire",
    "category": "Pyramid",
    "create_date": "2020-03-24",
    "description": "Planet solitaire is the most difficult Pyramid Solitaire game. Combine two cards to a total value of thirteen (13) to remove the cards from the field. A Jack (J) is 11 points, a Queen (Q) is 12 points and a King (K) is 13 points. A King can be removed on its own.",
    "url": "https://cdn.htmlgames.com/PlanetSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=PRu7mqVzSSY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PlanetSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PlanetSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PlanetSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PlanetSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/planetsolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/planetsolitaire500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/planetsolitaire800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/planetsolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/planetsolitaire1280.webp"
    },
    {
    "name": "Slidon",
    "category": "Mahjong Slide",
    "create_date": "2020-03-18",
    "description": "Slide the tiles and solve the puzzle. Let two of the same tiles collide to remove the tiles. Remove all tiles within a limited amount of turns.",
    "url": "https://cdn.htmlgames.com/Slidon/index.html",
    "youtube": "https://www.youtube.com/watch?v=HPRGqmDKPUc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Slidon&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Slidon/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Slidon/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Slidon/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/slidon200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/slidon500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/slidon800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/slideon1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/slidon1280720.webp"
    },
    {
    "name": "Daily Futoshiki",
    "category": "Daily Puzzles",
    "create_date": "2020-03-12",
    "description": "Everyday 6 new different Futoshiki puzzles to solve. Fill in the grid so that in every row and column no number appears more than once. Use the inequality constraints to solve the puzzle.",
    "url": "https://cdn.htmlgames.com/DailyFutoshiki/index.html",
    "youtube": "https://www.youtube.com/watch?v=DMNC-4xHoqU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyFutoshiki&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyFutoshiki/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyFutoshiki/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyFutoshiki/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dailyfutoshiki200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dailyfutoshiki500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dailyfutoshiki800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dailyfutoshiki1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dailyfutoshiki1280.webp"
    },
    {
    "name": "Alien Invaders 2",
    "category": "Retro",
    "create_date": "2020-03-05",
    "description": "Shoot the aliens and save the planet. Drag your space ship and shoot the aliens.",
    "url": "https://cdn.htmlgames.com/AlienInvaders2/index.html",
    "youtube": "https://www.youtube.com/watch?v=vxqu_7UBo6Q",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=AlienInvaders2&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/AlienInvaders2/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/AlienInvaders2/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/AlienInvaders2/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/alieninvaders2-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/alieninvaders2500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/alieninvaders2800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/alieninvaders21024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/alieninvaders21280720.webp"
    },
    {
    "name": "Happy Farm",
    "category": "Collapse Games",
    "create_date": "2020-02-27",
    "description": "150 levels of matching fun. Match two or more of the same tiles to remove the tiles. You need to reach at least one star to complete a level.",
    "url": "https://cdn.htmlgames.com/HappyFarm/index.html",
    "youtube": "https://www.youtube.com/watch?v=hPnxtxKqJCs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HappyFarm&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HappyFarm/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HappyFarm/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HappyFarm/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/happyfarm200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/happyfarm500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/happyfarm800480.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/happyfarm1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/happyfarm1280.webp"
    },
    {
    "name": "Treasure Chests",
    "category": "Memory",
    "create_date": "2020-02-20",
    "description": "Combine the same treasures in this Memory Game. Click on the chests with the same treasure to remove the chests.",
    "url": "https://cdn.htmlgames.com/TreasureChests/index.html",
    "youtube": "https://www.youtube.com/watch?v=5fU7KyGYwxs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TreasureChests&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TreasureChests/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TreasureChests/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TreasureChests/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/treasurechests200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/treasurechests500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/treasurechests800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/treasurechests1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/treasurechests1280.webp"
    },
    {
    "name": "Free Cell",
    "category": "Freecell",
    "create_date": "2020-02-13",
    "description": "The well known Free Cell card game. Move all the cards to the four foundations from Ace to King. On the tableau build down in alternating color. You can temporarily place a card on a Free Cell.",
    "url": "https://cdn.htmlgames.com/FreeCell/index.html",
    "youtube": "https://www.youtube.com/watch?v=5vV0pyVtFLM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FreeCell&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FreeCell/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FreeCell/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FreeCell/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/freecell200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/freecell500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/freecell800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/freecell1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/freecell1280720.webp"
    },
    {
    "name": "Mahjong Mix",
    "category": "Mahjong Games",
    "create_date": "2020-02-10",
    "description": "A mix of 5 different Mahjong games: Mahjong Solitaire, Mahjong Connect, Triple Mahjong, Mahjong Slide and 10 Mahjong.",
    "url": "https://cdn.htmlgames.com/MahjongMix/index.html",
    "youtube": "https://www.youtube.com/watch?v=zdZswyVDuhw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongMix&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongMix/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongMix/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongMix/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongmix200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongmix500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mahjongmix800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mahjongmix1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mahjongmix1280600.webp"
    },
    {
    "name": "Pizza Chief",
    "category": "Time management",
    "create_date": "2020-02-06",
    "description": "Try to recreate the pizza and become the Pizza Chief. Drag the ingredients on the pizza and try to make your pizza the same as the example pizza.",
    "url": "https://cdn.htmlgames.com/PizzaChief/index.html",
    "youtube": "https://www.youtube.com/watch?v=XG_FIMb1jfo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PizzaChief&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PizzaChief/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PizzaChief/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PizzaChief/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/pizzachief200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/pizzachief500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/pizzachief800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/pizzachief1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/pizzachief1280.webp"
    },
    {
    "name": "Flappy Colors",
    "category": "Skill",
    "create_date": "2020-02-03",
    "description": "Tap to fly and hit the correct colors. Your ball changes color when it hits the correct wall.",
    "url": "https://cdn.htmlgames.com/FlappyColors/index.html",
    "youtube": "https://www.youtube.com/watch?v=UqKXR2fjBao",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FlappyColors&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FlappyColors/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FlappyColors/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FlappyColors/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/flappycolors200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/flappycolors500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/flappycolors800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/flappycolors1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/flappycolors1280.webp"
    },
    {
    "name": "Wasp Solitaire",
    "category": "Solitaire Games",
    "create_date": "2020-01-30",
    "description": "Place all cards in order from King to Ace. Each open card may be moved to another column if it is the same colour and one higher in value.",
    "url": "https://cdn.htmlgames.com/WaspSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=ls1wFs4mvUI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=WaspSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/WaspSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/WaspSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/WaspSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/waspsolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/waspsolitaire500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/waspsolitaire800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/waspsolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/waspsolitaire1280.webp"
    },
    {
    "name": "Amsterdam Hidden Objects",
    "category": "Hidden Object Games",
    "create_date": "2020-01-27",
    "description": "Find all the hidden objects in Amsterdam. Click on the objects. You can zoom in. Use the light bulb for a hint.",
    "url": "https://cdn.htmlgames.com/AmsterdamHiddenObjects/index.html",
    "youtube": "https://www.youtube.com/watch?v=ImxFX22OrAg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=AmsterdamHiddenObjects&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/AmsterdamHiddenObjects/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/AmsterdamHiddenObjects/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/AmsterdamHiddenObjects/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/amsterdamhiddenobjects200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/amsterdamhiddenobjects500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/amsterdamhiddenobjects800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/amsterdamhiddenobjects1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/amsterdamhiddenobjects1280720.webp"
    },
    {
    "name": "Deck of Cards Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2020-01-23",
    "description": "Play Mahjong with Cards. Combine two of the same free tiles and try to remove all tiles.",
    "url": "https://cdn.htmlgames.com/DeckOfCardsMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=IIx6j1DMBAo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DeckOfCardsMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DeckOfCardsMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DeckOfCardsMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DeckOfCardsMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/deckofcardsmahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/deckofcardsmahjong500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/deckofcardsmahjong800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/deckofcardsmahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/deckofcardsmahjong1280.webp"
    },
    {
    "name": "Zuma Legend",
    "category": "Zuma Games",
    "create_date": "2020-01-20",
    "description": "A Zuma match3 game with 100 levels. Tap to shoot a ball into the chain and match 3 or more of the same balls. Tap the shooter to change the color.",
    "url": "https://cdn.htmlgames.com/ZumaLegend/index.html",
    "youtube": "https://www.youtube.com/watch?v=wxsDfLCAaEc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ZumaLegend&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ZumaLegend/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ZumaLegend/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ZumaLegend/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/zumalegend200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/zumalegend500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/zumalegend800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/zumalegend1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/zumalegend1280600.webp"
    },
    {
    "name": "Daily Suguru",
    "category": "Daily Puzzles",
    "create_date": "2020-01-17",
    "description": "Every three new Suguru puzzles to solve. Fill in the cages on the grid so that every cage contains unique numbers from 1 to the size of the cage. The same numbers cannot touch, not even diagonally.",
    "url": "https://cdn.htmlgames.com/DailySuguru/index.html",
    "youtube": "https://www.youtube.com/watch?v=WkAS6gtKmi0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailySuguru&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailySuguru/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailySuguru/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailySuguru/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dailysuguru200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dailysuguru500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dailysuguru800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dailysuguru1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dailysuguru1280.webp"
    },
    {
    "name": "Dragon Bubble",
    "category": "Bubble Shooter",
    "create_date": "2020-01-13",
    "description": "Help the dragon to shoot all bubbles. Shoot the bubbles and try to make groups of three or more of the same.",
    "url": "https://cdn.htmlgames.com/DragonBubble/index.html",
    "youtube": "https://www.youtube.com/watch?v=vpNLWEpxj0Q",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DragonBubble&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DragonBubble/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DragonBubble/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DragonBubble/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dragonbubble200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dragonbubble500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dragonbubble800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/dragonbubble1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/dragonbubble1280.webp"
    },
    {
    "name": "Reach 10",
    "category": "Puzzle Games",
    "create_date": "2020-01-03",
    "description": "Combine numbers and try to reach 10. Click on a group of two or more of the same numbers to combine the numbers and let it grow and try to get 10.",
    "url": "https://cdn.htmlgames.com/Reach10/index.html",
    "youtube": "https://www.youtube.com/watch?v=TTtf8OJHexo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Reach10&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Reach10/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Reach10/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Reach10/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/reach10200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/reach10-500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/reach10800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/reach10-1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/reach101280.webp"
    },
    {
    "name": "Jungle Mysteries",
    "category": "Hidden Object Games",
    "create_date": "2019-12-30",
    "description": "Find all the hidden objects in the jungle. Click to select an object.",
    "url": "https://cdn.htmlgames.com/JungleMysteries/index.html",
    "youtube": "https://www.youtube.com/watch?v=ufhWnmy95J8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JungleMysteries&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JungleMysteries/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JungleMysteries/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JungleMysteries/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/junglemysteries200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/junglemysteries500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/junglemysteries800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/junglemysteries1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/junglemysteries1280720.webp"
    },
    {
    "name": "Downhill Ski",
    "category": "Sports",
    "create_date": "2019-12-23",
    "description": "Downhill ski winter game. Move left and right and avoid all obstacles.",
    "url": "https://cdn.htmlgames.com/DownhillSki/index.html",
    "youtube": "https://www.youtube.com/watch?v=8fZdY4xO1bs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DownhillSki&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DownhillSki/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DownhillSki/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DownhillSki/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/downhillski200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/downhillski500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/downhillski800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/downhillski1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/downhillski1280.webp"
    },
    {
    "name": "Senet",
    "category": "Puzzle Games",
    "create_date": "2019-12-19",
    "description": "Senet: the board game from ancient Egypt. Be the first to move all your pieces of the board.",
    "url": "https://cdn.htmlgames.com/Senet/index.html",
    "youtube": "https://www.youtube.com/watch?v=xxAPoHBuB7c",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Senet&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Senet/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Senet/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Senet/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/senet200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/senet500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/senet800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/senet1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/senet1280.webp"
    },
    {
    "name": "Penguin Solitaire",
    "category": "Freecell",
    "create_date": "2019-12-16",
    "description": "Help the Penguins to solve this freecell like game. Similar game as Eight off and Freecell. In contrast to Freecell, build sequences on suit instead of alternating color. Move all cards to theÂ 4 foundations (top right) on suit, ascending starting with the given card. Use theÂ free cells to temporarily park cards. If needed wrap from King to Ace on the foundations and tableau.",
    "url": "https://cdn.htmlgames.com/PenguinSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=HHQ_EEQrkLw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PenguinSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PenguinSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PenguinSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PenguinSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/penguinsolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/penguinsolitaire500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/penguinsolitaire800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/penguinsolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/penguinsolitaire1280.webp"
    },
    {
    "name": "Penguin Cubes",
    "category": "Connect 3",
    "create_date": "2019-12-12",
    "description": "Slide cubes and connect 3 or more of the same cubes. Click or tap to slide a new cube.",
    "url": "https://cdn.htmlgames.com/PenguinCubes/index.html",
    "youtube": "https://www.youtube.com/watch?v=J6VQOWMuMqY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PenguinCubes&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PenguinCubes/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PenguinCubes/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PenguinCubes/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/penguincubes200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/penguincubes500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/penguincubes800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/penguincubes1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/penguincubes1280.webp"
    },
    {
    "name": "Santa Solitaire",
    "category": "Klondike",
    "create_date": "2019-12-09",
    "description": "Help Santa in this Christmas Solitaire game. Move all cards to the four foundations on the tableau you can build down on alternate color.",
    "url": "https://cdn.htmlgames.com/SantaSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=1elIHDMGhd0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SantaSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SantaSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SantaSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SantaSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/santasolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/santasolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/santasolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/santasolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/santasolitaire1280720.webp"
    },
    {
    "name": "Christmas Triple Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2019-12-05",
    "description": "Triple Mahjong for Christmas. Combine three of the same free tiles to remove the tiles. Try to remove all tiles before time runs out.",
    "url": "https://cdn.htmlgames.com/ChristmasTripleMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=F0F9pOK1Na8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ChristmasTripleMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ChristmasTripleMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ChristmasTripleMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ChristmasTripleMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/christmastriplemahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/christmastriplemahjong500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/christmastriplemahjong800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/christmastriplemahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/christmastriplemahjong1280.webp"
    },
    {
    "name": "Happy Christmas",
    "category": "Connect 3",
    "create_date": "2019-12-02",
    "description": "Drag the Christmas items and get 3 or more connected items. Collect as many Christmas items as indicated.",
    "url": "https://cdn.htmlgames.com/HappyChristmas/index.html",
    "youtube": "https://www.youtube.com/watch?v=P0bg7_ETOd4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HappyChristmas&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HappyChristmas/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HappyChristmas/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HappyChristmas/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/happychristmas200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/happychristmas500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/happychristmas800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/happychristmas1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/happychristmas1280.webp"
    },
    {
    "name": "Quick Arithmetic",
    "category": "Math Games",
    "create_date": "2019-11-28",
    "description": "Test your Arithmetic skills. Determine if the given sum is correct.",
    "url": "https://cdn.htmlgames.com/QuickArithmetic/index.html",
    "youtube": "https://www.youtube.com/watch?v=w9NGUC2CiHs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=QuickArithmetic&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/QuickArithmetic/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/QuickArithmetic/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/QuickArithmetic/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/quickarithmetic200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/quickarithmetic500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/quickarithmetic800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/quickarithmetic1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/quickarithmetic1280720.webp"
    },
    {
    "name": "Sword Hit",
    "category": "Shooting & War",
    "create_date": "2019-11-25",
    "description": "Throw your sword and hit the target. Click to throw. Do not hit other swords.",
    "url": "https://cdn.htmlgames.com/SwordHit/index.html",
    "youtube": "https://www.youtube.com/watch?v=J6iNgkHenNo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SwordHit&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SwordHit/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SwordHit/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SwordHit/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/swordhit200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/swordhit500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/swordhit800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/swordhit1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/swordhit1280.webp"
    },
    {
    "name": "Frozen Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2019-11-21",
    "description": "Everything is frozen, can you unfreeze the mahjong tiles? Combine two of the same unfrozen tiles to remove the tiles and unfreeze other tiles.",
    "url": "https://cdn.htmlgames.com/FrozenMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=8PBtwPi6o1s",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FrozenMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FrozenMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FrozenMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FrozenMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/frozenmahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/frozenmahjong500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/frozenmahjong800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/frozenmahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/frozenmahjong1280.webp"
    },
    {
    "name": "Hidden Icons",
    "category": "Hidden Object Games",
    "create_date": "2019-11-18",
    "description": "Find all the hidden icons. Find the objects that are indicated on top of the game.",
    "url": "https://cdn.htmlgames.com/HiddenIcons/index.html",
    "youtube": "https://www.youtube.com/watch?v=_1FZoBk-Tm0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HiddenIcons&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HiddenIcons/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HiddenIcons/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HiddenIcons/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hiddenicon200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hiddenicon500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hiddenicon800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hiddenicons1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hiddenicon1280720.webp"
    },
    {
    "name": "Train Switch",
    "category": "Puzzle Games",
    "create_date": "2019-11-14",
    "description": "Move wagons and complete trains. Connect the same wagons by moving a wagon. Click on a wagon and a destination to move a wagon.",
    "url": "https://cdn.htmlgames.com/TrainSwitch/index.html",
    "youtube": "https://www.youtube.com/watch?v=tLErupzGOl0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TrainSwitch&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TrainSwitch/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TrainSwitch/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TrainSwitch/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/trainswitch200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/trainswitch500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/trainswitch800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/trainswitch1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/trainswitch1280.webp"
    },
    {
    "name": "1010 Deluxe",
    "category": "Puzzle Games",
    "create_date": "2019-11-11",
    "description": "Deluxe 1010 game with Jewels. Drag the shapes on the grid and try to complete rows and columns. Completed rows and columns are remove from the grid.",
    "url": "https://cdn.htmlgames.com/1010Deluxe/index.html",
    "youtube": "https://www.youtube.com/watch?v=Nc2XmDDNECI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=1010Deluxe&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/1010Deluxe/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/1010Deluxe/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/1010Deluxe/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/1010deluxe200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/1010deluxe500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/1010deluxe800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/1010deluxe1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/1010deluxe1280720.webp"
    },
    {
    "name": "Pile of Tiles Connect",
    "category": "Mahjong Connect",
    "create_date": "2019-11-07",
    "description": "Connect Mahjong tiles and remove all tiles. You can connect when the connecting path has no more than two bends.",
    "url": "https://cdn.htmlgames.com/PileOfTilesConnect/index.html",
    "youtube": "https://www.youtube.com/watch?v=Wc85jQFe0fo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PileOfTilesConnect&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PileOfTilesConnect/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PileOfTilesConnect/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PileOfTilesConnect/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/pileoftilesconnect200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/pileoftilesconnect500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/pileoftilesconnect800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/pileoftilesconnect1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/pileoftilesconnect1280720.webp"
    },
    {
    "name": "Candy Match",
    "category": "Bejeweled",
    "create_date": "2019-10-31",
    "description": "Fresh and Fun Candy Match 3 game. Switch two Candies to match three or more in a row.",
    "url": "https://cdn.htmlgames.com/CandyMatch/index.html",
    "youtube": "https://www.youtube.com/watch?v=snt7CZEK6P0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CandyMatch&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CandyMatch/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CandyMatch/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CandyMatch/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/candymatch200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/candymatch500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/candymatch800.webp"
    },
    {
    "name": "Luxor Tri Peaks",
    "category": "Tripeaks & Golf",
    "create_date": "2019-10-24",
    "description": "100 different Tri Peaks levels. Remove all cards, you can select cards that are one higher or one lower in value than the open card.",
    "url": "https://cdn.htmlgames.com/LuxorTriPeaks/index.html",
    "youtube": "https://www.youtube.com/watch?v=TzwUb4QGoT4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=LuxorTriPeaks&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/LuxorTriPeaks/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/LuxorTriPeaks/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/LuxorTriPeaks/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/luxortripeaks200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/luxortripeaks500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/luxortripeaks800.webp"
    },
    {
    "name": "Neon Tracks",
    "category": "Puzzle Games",
    "create_date": "2019-10-24",
    "description": "Complete a track from start to finish using the given hints on the left and top of the grid. The hints indicate how many track parts are in the given row or column.",
    "url": "https://cdn.htmlgames.com/NeonTracks/index.html",
    "youtube": "https://www.youtube.com/watch?v=eTRBrgxlBZs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=NeonTracks&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/NeonTracks/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/NeonTracks/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/NeonTracks/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/neontracks200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/neontracks500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/neontracks800.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/neontracks1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/neontracks1280720.webp"
    },
    {
    "name": "Crushed Tiles",
    "category": "Collapse Games",
    "create_date": "2019-10-21",
    "description": "Crush tiles in groups of three or more and reach the indicated goal. Click on a connected group.",
    "url": "https://cdn.htmlgames.com/CrushedTiles/index.html",
    "youtube": "https://www.youtube.com/watch?v=JEzCTOF40jY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CrushedTiles&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CrushedTiles/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CrushedTiles/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CrushedTiles/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/crushedtiles200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/crushedtiles500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/crushedtiles800.webp"
    },
    {
    "name": "Airport Management 3",
    "category": "Time management",
    "create_date": "2019-10-17",
    "description": "Land all planes and helicopters safely. Draw a safe path to land a plane or helicopter.",
    "url": "https://cdn.htmlgames.com/AirportManagement3/index.html",
    "youtube": "https://www.youtube.com/watch?v=ZOUs7_u7o10",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=AirportManagement3&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/AirportManagement3/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/AirportManagement3/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/AirportManagement3/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/airportmanagement3200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/airportmanagement3500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/airportmanagement3800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/airportmanagement31024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/airportmanagement31280720.webp"
    },
    {
    "name": "Zodiac Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2019-10-14",
    "description": "Mahjong Zodiac with the 12 zodiac signs. Combine two of the same free tiles to remove the tiles. Remove all tiles to advance to the next level.",
    "url": "https://cdn.htmlgames.com/ZodiacMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=AawiW0Bav8M",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ZodiacMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ZodiacMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ZodiacMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ZodiacMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/zodiacmahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/zodiacmahjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/zodiacmahjong800450.webp"
    },
    {
    "name": "Halloween Tripeaks",
    "category": "Tripeaks & Golf",
    "create_date": "2019-10-10",
    "description": "Tripeaks Solitaire game for Halloween. Remove all cards from the tableau by selecting cards that are 1 higher or 1 lower in value than the open card at the bottom (right). Click on 'Next' to get a new card.",
    "url": "https://cdn.htmlgames.com/HalloweenTripeaks/index.html",
    "youtube": "https://www.youtube.com/watch?v=VGwD5bQX-9o",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HalloweenTripeaks&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HalloweenTripeaks/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HalloweenTripeaks/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HalloweenTripeaks/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/halloweentripeaks200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/halloweentripeaks500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/halloweentripeaks800.webp"
    },
    {
    "name": "Bubble Shooter",
    "category": "Bubble Shooter",
    "create_date": "2019-10-07",
    "description": "9 levels Bubble Shooter fun. Shoot up the bubbles and match 3 or more of the same color.",
    "url": "https://cdn.htmlgames.com/BubbleShooter/index.html",
    "youtube": "https://www.youtube.com/watch?v=8GxQYIQffrs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BubbleShooter&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BubbleShooter/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BubbleShooter/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BubbleShooter/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bubbleshooter200120.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bubbleshooter500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/bubbleshooter800.webp"
    },
    {
    "name": "Letter Dimensions",
    "category": "3D Mahjong",
    "create_date": "2019-10-03",
    "description": "3D Mahjong game with Letters. Combine two free cubes with the same letters. Free cubes have at least two free adjacent sides.",
    "url": "https://cdn.htmlgames.com/LetterDimensions/index.html",
    "youtube": "https://www.youtube.com/watch?v=9oeZMmY05WM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=LetterDimensions&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/LetterDimensions/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/LetterDimensions/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/LetterDimensions/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/letterdimensions200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/letterdimensions500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/letterdimensions800.webp"
    },
    {
    "name": "Daily Maze",
    "category": "Daily Puzzles",
    "create_date": "2019-09-30",
    "description": "Solve everyday new Maze levels. Find the correct path, drag the brown dot.",
    "url": "https://cdn.htmlgames.com/DailyMaze/index.html",
    "youtube": "https://www.youtube.com/watch?v=5heyaVDIhks",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyMaze&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyMaze/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyMaze/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyMaze/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dailymaze200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dailymaze500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/dailymaze800.webp"
    },
    {
    "name": "Cats 1010",
    "category": "Puzzle Games",
    "create_date": "2019-09-26",
    "description": "1010 game with cats. Drag and rotate shapes and reach the indicated goal.",
    "url": "https://cdn.htmlgames.com/Cats1010/index.html",
    "youtube": "https://www.youtube.com/watch?v=yQHSZxAGWws",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Cats1010&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Cats1010/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Cats1010/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Cats1010/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/cats1010-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/cats1010500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/cats1010800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/cats10101024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/cats10101280720.webp"
    },
    {
    "name": "Search the Sands",
    "category": "Word Games",
    "create_date": "2019-09-23",
    "description": "Find all words hidden on the beach. Drag on the grid to select a word and try to find all.",
    "url": "https://cdn.htmlgames.com/SearchTheSands/index.html",
    "youtube": "https://www.youtube.com/watch?v=Qd55bleL8hE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SearchTheSands&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SearchTheSands/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SearchTheSands/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SearchTheSands/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/searchthesands200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/searchthesands500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/searchthesands800.webp"
    },
    {
    "name": "Queenie Solitaire",
    "category": "Klondike",
    "create_date": "2019-09-19",
    "description": "Move all cards to the four foundations in this Queenie Solitaire game. On the tableau you can move cards and groups of cards. A group does not need to have an order, except that the starting and target cards must be built in sequence and in alternate color. Click on the closed stack to deal new cards to the tableau piles.",
    "url": "https://cdn.htmlgames.com/QueenieSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=P-op-dMEpq8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=QueenieSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/QueenieSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/QueenieSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/QueenieSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/queeniesolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/queeniesolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/queeniesolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/queeniesolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/queeniesolitaire1280720.webp"
    },
    {
    "name": "ET Brain",
    "category": "Brain Games",
    "create_date": "2019-09-13",
    "description": "Train your brain. Follow the instructions for every mini game.",
    "url": "https://cdn.htmlgames.com/ETBrain/index.html",
    "youtube": "https://www.youtube.com/watch?v=aDy9-avUAqs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ETBrain&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ETBrain/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ETBrain/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ETBrain/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/etbrain-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/etbrain500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/etbrain800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/etbrain1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/etbrain1280720.webp"
    },
    {
    "name": "Circus Shooter",
    "category": "Shooting & War",
    "create_date": "2019-09-09",
    "description": "Shoot the targets at the Circus. Try to hit the round targets and not the other ones. Click 'Reload' when you need new shots.",
    "url": "https://cdn.htmlgames.com/CircusShooter/index.html",
    "youtube": "https://www.youtube.com/watch?v=D6M-4cTM0gU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CircusShooter&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CircusShooter/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CircusShooter/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CircusShooter/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/circusshooter200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/circusshooter500.webp"
    },
    {
    "name": "A day in the Museum",
    "category": "Difference Games",
    "create_date": "2019-09-02",
    "description": "Find all the differences in the Museum. Click on a difference.",
    "url": "https://cdn.htmlgames.com/ADayInTheMuseum/index.html",
    "youtube": "https://www.youtube.com/watch?v=sBuQ1Ks68jY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ADayInTheMuseum&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ADayInTheMuseum/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ADayInTheMuseum/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ADayInTheMuseum/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/adayinthemuseum200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/adayinthemuseum500.webp"
    },
    {
    "name": "Killer Sudoku",
    "category": "Sudoku",
    "create_date": "2019-08-29",
    "description": "Play Killer Sudoku or Sumdoku. Fill the grid with the numbers 1 to 9, such that each row, column and box contains each number only once. Additionally, a Killer Sudoku grid is divided into cages, each cage has its own background color. The values of the cells in a cage must sum up to the total for that cage, shown in the cage's top left corner. The same number cannot appear in a cage more than once.",
    "url": "https://cdn.htmlgames.com/KillerSudoku/index.html",
    "youtube": "https://www.youtube.com/watch?v=R_3e4rsEG9s",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=KillerSudoku&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/KillerSudoku/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/KillerSudoku/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/KillerSudoku/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/killersudoku200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/killersudoku500300.webp"
    },
    {
    "name": "Flower Dimensions",
    "category": "3D Mahjong",
    "create_date": "2019-08-26",
    "description": "3D Mahjong game with flowers. Combine two of the same free flowers.",
    "url": "https://cdn.htmlgames.com/FlowerDimensions/index.html",
    "youtube": "https://www.youtube.com/watch?v=tb3vCOnlkzw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FlowerDimensions&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FlowerDimensions/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FlowerDimensions/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FlowerDimensions/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/flowerdimensions200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/flowerdimensions500.webp"
    },
    {
    "name": "Circus Bubbles",
    "category": "Bubble Shooter",
    "create_date": "2019-08-22",
    "description": "Bubble Shooter in the Circus. Remove all bubbles by shooting bubbles up and matching three or more of the same color.",
    "url": "https://cdn.htmlgames.com/CircusBubbles/index.html",
    "youtube": "https://www.youtube.com/watch?v=0oFa6peh97w",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CircusBubbles&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CircusBubbles/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CircusBubbles/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CircusBubbles/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/circusbubbles200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/circusbubbles500.webp"
    },
    {
    "name": "Freecell Duplex",
    "category": "Freecell",
    "create_date": "2019-08-15",
    "description": "Freecell solitaire game with two decks of cards and 8 foundations. Move all the cards to the foundations from Ace to King. On the tableau you can build down on alternating color. Use the FreeCells to temporarily park a card.",
    "url": "https://cdn.htmlgames.com/FreecellDuplex/index.html",
    "youtube": "https://www.youtube.com/watch?v=rliaH6-EFII",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FreecellDuplex&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FreecellDuplex/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FreecellDuplex/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FreecellDuplex/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/freecellduplex200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/freecellduplex500.webp"
    },
    {
    "name": "Daily Sudoku X",
    "category": "Sudoku",
    "create_date": "2019-08-15",
    "description": "Daily Sudoku game with an additional rule for the diagonals. Every day new sudoku games with the following rules: each row, column, 3x3 box and diagonal contains the numbers 1 to 9 only once.",
    "url": "https://cdn.htmlgames.com/DailySudokuX/index.html",
    "youtube": "https://www.youtube.com/watch?v=HA-V1OXlcP8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailySudokuX&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailySudokuX/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailySudokuX/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailySudokuX/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dailysudokux200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dailysudokux500.webp"
    },
    {
    "name": "Forest Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2019-08-12",
    "description": "Discover all the forest animals in this Mahjong game. Combine tiles in pairs of the same and remove all tiles.",
    "url": "https://cdn.htmlgames.com/ForestMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=_q133i-N1Fo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ForestMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ForestMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ForestMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ForestMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/forestmahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/forestmahjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/forestmahjong800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/forestmahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/forestmahjong1280720.webp"
    },
    {
    "name": "Tiki Totems Quartet",
    "category": "Puzzle Games",
    "create_date": "2019-08-05",
    "description": "Complete the Tiki Totem faces. Move the face parts in the center to an adjacent window and try to complete a face.",
    "url": "https://cdn.htmlgames.com/TikiTotemsQuartet/index.html",
    "youtube": "https://www.youtube.com/watch?v=yf7fiZDfAUk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TikiTotemsQuartet&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TikiTotemsQuartet/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TikiTotemsQuartet/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TikiTotemsQuartet/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/tikitotemsquartet-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/tikitotemsquartet500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/tikitotemsquartet800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/tikitotemsquartet1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/tikitotemsquartet1280720.webp"
    },
    {
    "name": "Space Trip",
    "category": "Skill",
    "create_date": "2019-07-29",
    "description": "Collect coins on your space trip. Avoid the obstacles and collect air and coins.",
    "url": "https://cdn.htmlgames.com/SpaceTrip/index.html",
    "youtube": "https://www.youtube.com/watch?v=yDHZSIqmPTI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SpaceTrip&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SpaceTrip/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SpaceTrip/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SpaceTrip/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/spacetrip200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/spacetrip500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/spacetrip800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/spacetrip1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/spacetrip1280720.webp"
    },
    {
    "name": "Jewels of the Princess",
    "category": "Collapse Games",
    "create_date": "2019-07-18",
    "description": "Collect the jewels of the princess. Click on groups of three or more of the same jewels.",
    "url": "https://cdn.htmlgames.com/JewelsOfThePrincess/index.html",
    "youtube": "https://www.youtube.com/watch?v=Y0i0rmfd_K8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JewelsOfThePrincess&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JewelsOfThePrincess/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JewelsOfThePrincess/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JewelsOfThePrincess/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jewelsofTheprincess200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jewelsofTheprincess500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/jewelsofTheprincess800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/jewelsofTheprincess1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/jewelsofTheprincess1280720.webp"
    },
    {
    "name": "Neon Nibblet",
    "category": "Snake",
    "create_date": "2019-07-15",
    "description": "Eat all the blue stars in the Snake-like game, but do not bit your own tail. Use the arrow keys or swipe on the screen.",
    "url": "https://cdn.htmlgames.com/NeonNibblet/index.html",
    "youtube": "https://www.youtube.com/watch?v=sZfHMFC1KOg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=NeonNibblet&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/NeonNibblet/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/NeonNibblet/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/NeonNibblet/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/neonnibblet200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/neonnibblet500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/neonnibblet800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/neonnibblet1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/neonnibblet1280720.webp"
    },
    {
    "name": "Japan Castle Mahjong",
    "category": "Mahjong Tower",
    "create_date": "2019-07-11",
    "description": "Mahjong Tower game set in ancient Japan. Combine two of the same free and highlighted tiles to remove the two tiles. Remove all tiles from the layout.",
    "url": "https://cdn.htmlgames.com/JapanCastleMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=3UTzjfsL4LY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JapanCastleMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JapanCastleMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JapanCastleMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JapanCastleMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/japancastlemahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/japancastlemahjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/japancastlemahjong800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/japancastlemahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/japancastlemahjong1280720.webp"
    },
    {
    "name": "Castles in Spain",
    "category": "Solitaire Games",
    "create_date": "2019-07-08",
    "description": "Move all cards to the foundations from Ace to King. On the tableau build down in alternating color.",
    "url": "https://cdn.htmlgames.com/CastlesInSpain/index.html",
    "youtube": "https://www.youtube.com/watch?v=O1ywLv-5nLw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CastlesInSpain&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CastlesInSpain/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CastlesInSpain/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CastlesInSpain/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/castlesinspain200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/castlesinspain500.webp"
    },
    {
    "name": "Daily Crossword",
    "category": "Crosswords",
    "create_date": "2019-07-04",
    "description": "Play a new crossword puzzle everyday. Solve a crossword by entering the solutions of the given horizontal and vertical clues.",
    "url": "https://cdn.htmlgames.com/DailyCrossword/index.html",
    "youtube": "https://www.youtube.com/watch?v=JVMlquVoJ48",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyCrossword&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyCrossword/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyCrossword/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyCrossword/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/daily-crossword-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/daily-crossword-500.webp"
    },
    {
    "name": "Jelly Trip",
    "category": "Match 3 Games",
    "create_date": "2019-07-01",
    "description": "Remove as many jellyfish as indicated. Drag rows and create groups of 3 or more of the same connected jellyfish. Reach the goal on the right.",
    "url": "https://cdn.htmlgames.com/JellyTrip/index.html",
    "youtube": "https://www.youtube.com/watch?v=6AgHQzNClSE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JellyTrip&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JellyTrip/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JellyTrip/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JellyTrip/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jellytrip200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jellytrip500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/jellytrip800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/jellytrip1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/jellytrip1280720.webp"
    },
    {
    "name": "Jolly Jong Math",
    "category": "Math Games",
    "create_date": "2019-06-27",
    "description": "Test your Math skills. Click on 2 tiles that make the given number with the given operator.",
    "url": "https://cdn.htmlgames.com/JollyJongMath/index.html",
    "youtube": "https://www.youtube.com/watch?v=VcpYWJEAtwY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JollyJongMath&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JollyJongMath/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JollyJongMath/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JollyJongMath/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jollyjongmath-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jollyjongmath500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/jollyjongmath800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/jollyjongmath1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/jollyjongmath1280720.webp"
    },
    {
    "name": "Animal Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2019-06-24",
    "description": "Mahjong Solitaire game with Animals. Remove all tiles in pairs of the same free tiles.",
    "url": "https://cdn.htmlgames.com/AnimalMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=lq2-drf6Cpw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=AnimalMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/AnimalMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/AnimalMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/AnimalMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/animalmahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/animalmahjong500.webp"
    },
    {
    "name": "Word Fishing",
    "category": "Word Games",
    "create_date": "2019-06-20",
    "description": "Find all the letters of a word. Click on the letters in the correct order from left to right.",
    "url": "https://cdn.htmlgames.com/WordFishing/index.html",
    "youtube": "https://www.youtube.com/watch?v=HHiYIPxdFd8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=WordFishing&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/WordFishing/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/WordFishing/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/WordFishing/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/wordfishing-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/wordfishing500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/wordfishing800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/wordfishing1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/wordfishing1280720.webp"
    },
    {
    "name": "Mysterious Pirate Jewels",
    "category": "Connect 3",
    "create_date": "2019-06-17",
    "description": "Remove all colored backgrounds in this Pirate game. Connect three or more of the same colored jewels to remove the jewels and the backgrounds.",
    "url": "https://cdn.htmlgames.com/MysteriousPirateJewels/index.html",
    "youtube": "https://www.youtube.com/watch?v=C9M2B_tYxhE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MysteriousPirateJewels&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MysteriousPirateJewels/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MysteriousPirateJewels/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MysteriousPirateJewels/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mysteriouspiratejewels200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mysteriouspiratejewels500.webp"
    },
    {
    "name": "Huge Spider Solitaire",
    "category": "Spider",
    "create_date": "2019-06-13",
    "description": "Huge version of the Spider Solitaire game. On the tableau build a group of cards from King to Ace in suit. You can place cards in descending order but you can only move groups that are in suit.",
    "url": "https://cdn.htmlgames.com/HugeSpiderSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=PwA_3eBNb00",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HugeSpiderSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HugeSpiderSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HugeSpiderSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HugeSpiderSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hugespidersolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hugespidersolitaire500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/hugespidersolitaire800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/hugespidersolitaire1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/hugespidersolitaire1280720.webp"
    },
    {
    "name": "Gaps Solitaire",
    "category": "Montana",
    "create_date": "2019-06-10",
    "description": "Arrange all cards in color from 2 to King. Move a card to empty spots that are the same color and one higher in sequence or start with a 2 on the extreme left. You have 3 shuffles.",
    "url": "https://cdn.htmlgames.com/GapsSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=H6kUPBq58a4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=GapsSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/GapsSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/GapsSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/GapsSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/addictionsolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/addictionsolitaire500.webp"
    },
    {
    "name": "Daily Kaodoku",
    "category": "Sudoku",
    "create_date": "2019-06-06",
    "description": "Daily Kaodoku puzzles in 4 difficulty degrees. Solve this Sudoku like game. Instead of numbers in the Sudoku game, this game contains smileys. Every smiley has three possible mouths and three possible shapes creating nine different combinations. Every unique smilly can only appear once in every row, column and 3x3 box like in Sudoku.",
    "url": "https://cdn.htmlgames.com/DailyKaodoku/index.html",
    "youtube": "https://www.youtube.com/watch?v=ht78HNnwiZM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyKaodoku&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyKaodoku/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyKaodoku/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyKaodoku/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dailykaodoku200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dailykaodoku500.webp"
    },
    {
    "name": "Rune Mahjongg",
    "category": "Mahjong Games",
    "create_date": "2019-06-04",
    "description": "Play 3 different Mahjong games in one game. Remove the tiles with various mechanisms: combine, connect or collide.",
    "url": "https://cdn.htmlgames.com/RuneMahjongg/index.html",
    "youtube": "https://www.youtube.com/watch?v=XNemmseLT1s",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=RuneMahjongg&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/RuneMahjongg/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/RuneMahjongg/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/RuneMahjongg/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/runemahjongg200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/runemahjongg500.webp"
    },
    {
    "name": "Neon Fence",
    "category": "Puzzle Games",
    "create_date": "2019-05-27",
    "description": "Fence in the requested colored blocks. Draw a line around the requested colors. You can find the requested colors at the top.",
    "url": "https://cdn.htmlgames.com/NeonFence/index.html",
    "youtube": "https://www.youtube.com/watch?v=wuFAf1vWv80",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=NeonFence&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/NeonFence/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/NeonFence/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/NeonFence/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/neonfence200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/neonfence500.webp"
    },
    {
    "name": "Crystal Hexajong",
    "category": "Mahjong Games",
    "create_date": "2019-05-23",
    "description": "Mahjong Solitaire with hexagonal tiles. Remove tiles in pairs of the same. Only free tiles can be removed. A tile is free if it has 3 free neighbouring sides.",
    "url": "https://cdn.htmlgames.com/CrystalHexajong/index.html",
    "youtube": "https://www.youtube.com/watch?v=nIis_M9FFSM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CrystalHexajong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CrystalHexajong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CrystalHexajong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CrystalHexajong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/crystalhexajong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/crystalhexajong500.webp"
    },
    {
    "name": "Happy Flowers",
    "category": "Connect 3",
    "create_date": "2019-05-23",
    "description": "Remove flowers by matching 3 or more of the same. Drag a row or column and connect the same flowers. Reach the indicated numbers of flowers to remove to advance to the next level.",
    "url": "https://cdn.htmlgames.com/HappyFlowers/index.html",
    "youtube": "https://www.youtube.com/watch?v=NgwTYnNblAk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=HappyFlowers&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/HappyFlowers/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/HappyFlowers/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/HappyFlowers/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/happyflowers200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/happyflowers500.webp"
    },
    {
    "name": "Jewel Mahjongg",
    "category": "Mahjong Solitaire",
    "create_date": "2019-05-20",
    "description": "Remove all jewels in this Mahjong game. Combine two free tiles with the same jewels.",
    "url": "https://cdn.htmlgames.com/JewelMahjongg/index.html",
    "youtube": "https://www.youtube.com/watch?v=rJBZEM4xctU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JewelMahjongg&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JewelMahjongg/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JewelMahjongg/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JewelMahjongg/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jewelmahjongg200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jewelmahjongg500.webp"
    },
    {
    "name": "Vegetables Farm",
    "category": "Collapse Games",
    "create_date": "2019-05-16",
    "description": "Remove all vegetables on the Farm. Click on groups of 3 or more of the same.",
    "url": "https://cdn.htmlgames.com/VegetablesFarm/index.html",
    "youtube": "https://www.youtube.com/watch?v=AbngLlh7Jmc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=VegetablesFarm&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/VegetablesFarm/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/VegetablesFarm/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/VegetablesFarm/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/vegetablesfarm-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/vegetablesfarm500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/vegetablesfarm800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/vegetablesfarm1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/vegetablesfarm1280720.webp"
    },
    {
    "name": "Crescent Solitaire",
    "category": "Solitaire Games",
    "create_date": "2019-05-13",
    "description": "Move the cards from the crescent to the foundations. The kings are built down by suit up to aces and the aces are built up, also by suit, to kings. Use the shuffles when you have no more possible moves. The top card of each pile in the crescent are available to play on the foundations or the tableau. Only one card can be moved at a time and building on the tableau is either up or down by suit and can go round-the-corner (placing a king over an ace and vice versa).",
    "url": "https://cdn.htmlgames.com/CrescentSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=fwmpaUl6Mls",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CrescentSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CrescentSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CrescentSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CrescentSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/crescentsolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/crescentsolitaire500.webp"
    },
    {
    "name": "Catch the Thief",
    "category": "Platform",
    "create_date": "2019-05-10",
    "description": "Catch all the Thieves by freezing them. Use the on-screen controls or the arrow keys and space bar.",
    "url": "https://cdn.htmlgames.com/CatchTheThief/index.html",
    "youtube": "https://www.youtube.com/watch?v=I1N2WbOWWQg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CatchTheThief&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CatchTheThief/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CatchTheThief/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CatchTheThief/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/catchthethief200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/catchthethief500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/catchthethief800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/catchthethief1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/catchthethief1280720.webp"
    },
    {
    "name": "The Travel Puzzle",
    "category": "Connect 3",
    "create_date": "2019-05-06",
    "description": "Remove all bricks by matching 3 or more. Click on a row or column to slide a new brick and make connected groups of 3 or more of the same colored bricks.",
    "url": "https://cdn.htmlgames.com/TheTravelPuzzle/index.html",
    "youtube": "https://www.youtube.com/watch?v=iLb60St0Usc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=TheTravelPuzzle&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/TheTravelPuzzle/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/TheTravelPuzzle/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/TheTravelPuzzle/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/thetravelpuzzle200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/thetravelpuzzle500.webp"
    },
    {
    "name": "Circus Hidden Objects",
    "category": "Hidden Object Games",
    "create_date": "2019-04-18",
    "description": "Find all the hidden objects in the Circus. Click on hidden objects.",
    "url": "https://cdn.htmlgames.com/CircusHiddenObjects/index.html",
    "youtube": "https://www.youtube.com/watch?v=zXtIhvRZz-I",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=CircusHiddenObjects&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/CircusHiddenObjects/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/CircusHiddenObjects/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/CircusHiddenObjects/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/circushiddenobjects200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/circushiddenobjects500.webp"
    },
    {
    "name": "Jigsaw Jam Animal",
    "category": "Puzzle Games",
    "create_date": "2019-04-18",
    "description": "Complete the Animal Jigsaw Puzzles. Drag the puzzle piece to the right spot.",
    "url": "https://cdn.htmlgames.com/JigsawJamAnimal/index.html",
    "youtube": "https://www.youtube.com/watch?v=hLObOQSb-lg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=JigsawJamAnimal&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/JigsawJamAnimal/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/JigsawJamAnimal/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/JigsawJamAnimal/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/jigsawjamanimal200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/jigsawjamanimal500.webp"
    },
    {
    "name": "Hangman",
    "category": "Word Games",
    "create_date": "2015-11-16",
    "description": "Guess the English words and try not to hang your man. Click on the letters to guess.",
    "url": "https://cdn.htmlgames.com/Hangman/index.html",
    "youtube": "https://www.youtube.com/watch?v=Y5hADzwCkw8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Hangman&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Hangman/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Hangman/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Hangman/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/hangman200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/hangman500.webp"
    },
    {
    "name": "Easter Mahjongg",
    "category": "Mahjong Solitaire",
    "create_date": "2019-04-11",
    "description": "Play a Mahjongg game for Easter holiday. Remove all tiles in pairs.",
    "url": "https://cdn.htmlgames.com/EasterMahjongg/index.html",
    "youtube": "https://www.youtube.com/watch?v=yH8tJFpW5lY",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=EasterMahjongg&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/EasterMahjongg/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/EasterMahjongg/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/EasterMahjongg/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/eastermahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/eastermahjong500.webp"
    },
    {
    "name": "Daily Star Battle",
    "category": "Daily Puzzles",
    "create_date": "2019-04-04",
    "description": "Play everday three new Star Battle puzzles. Place stars in such a way that there is only 1 star per row and per column and per region. Stars cannot touch even not diagonally.",
    "url": "https://cdn.htmlgames.com/DailyStarBattle/index.html",
    "youtube": "https://www.youtube.com/watch?v=ZrcLz1xHM0E",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyStarBattle&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyStarBattle/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyStarBattle/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyStarBattle/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/daily-star-battle-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/daily-star-battle-500.webp"
    },
    {
    "name": "Egypt Solitaire",
    "category": "Solitaire Games",
    "create_date": "2019-04-01",
    "description": "100 different levels of Solitaire Fun. Try to remove all cards by combining two of the same.",
    "url": "https://cdn.htmlgames.com/EgyptSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=50HpZWJXD-4",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=EgyptSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/EgyptSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/EgyptSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/EgyptSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/egyptsolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/egyptsolitaire500.webp"
    },
    {
    "name": "Mahjong Chain",
    "category": "Mahjong Connect",
    "create_date": "2019-03-28",
    "description": "Chain two of the same Mahjong tiles. Connect the same tiles with a path that has no more than two turns of 90 degrees.",
    "url": "https://cdn.htmlgames.com/MahjongChain/index.html",
    "youtube": "https://www.youtube.com/watch?v=y4lKU8Sgl4c",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MahjongChain&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MahjongChain/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MahjongChain/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MahjongChain/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mahjongchain200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mahjongchain500.webp"
    },
    {
    "name": "Fairy Triple Mahjong",
    "category": "Mahjong Games",
    "create_date": "2019-03-28",
    "description": "Match 3 of the same Mahjong tiles. Move tiles to the top free cells. If you have 3 of the same tiles then those tiles are removed.",
    "url": "https://cdn.htmlgames.com/FairyTripleMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=GCYfh21-oVA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FairyTripleMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FairyTripleMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FairyTripleMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FairyTripleMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/fairytriplemahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/fairytriplemahjong500.webp"
    },
    {
    "name": "Classic Solitaire",
    "category": "Klondike",
    "create_date": "2019-03-21",
    "description": "The classic Solitaire game. Move all cards to the four top foundations from Ace to King. On the tableau build down and alternate color.",
    "url": "https://cdn.htmlgames.com/ClassicSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=AX-ZoDBR_YE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ClassicSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ClassicSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ClassicSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ClassicSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/classicsolitaire-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/classic-solitaire500.webp"
    },
    {
    "name": "Master Qwans Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2019-03-21",
    "description": "Classic Mahjong game now as html5 game. Combine two of the same free tiles to remove the tiles.",
    "url": "https://cdn.htmlgames.com/MasterQwansMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=CI8S-CTM5YQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MasterQwansMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MasterQwansMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MasterQwansMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MasterQwansMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/masterqwansmahjong200120.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/masterqwansmahjong500.webp"
    },
    {
    "name": "Merge It",
    "category": "2048 & Merge",
    "create_date": "2019-03-18",
    "description": "Merge numbers into bigger numbers. Click on a group to increase a number and reach the goal.",
    "url": "https://cdn.htmlgames.com/MergeIt/index.html",
    "youtube": "https://www.youtube.com/watch?v=gGcUB1HQL4E",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MergeIt&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MergeIt/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MergeIt/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MergeIt/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mergeit200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mergeit500.webp"
    },
    {
    "name": "Mau Mau",
    "category": "Card Games",
    "create_date": "2019-03-14",
    "description": "Mau Mau: the German version of Crazy Eights. Be the first player to play away all your cards.",
    "url": "https://cdn.htmlgames.com/MauMau/index.html",
    "youtube": "https://www.youtube.com/watch?v=cBPeu8nu2TM",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MauMau&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MauMau/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MauMau/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MauMau/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/maumau200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/maumau500.webp"
    },
    {
    "name": "Sushi Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2019-03-11",
    "description": "Remove all sushi from the table. Click on two of the same sushi to remove it.",
    "url": "https://cdn.htmlgames.com/SushiMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=pHFYwOYnezE",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=SushiMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/SushiMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/SushiMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/SushiMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/sushimahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/sushimahjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/sushimahjong800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/sushimahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/sushimahjong1280720.webp"
    },
    {
    "name": "Emoji Pairs",
    "category": "Memory",
    "create_date": "2019-03-07",
    "description": "Try to find all pairs of Emojis. Click to open a tile. Find all pairs before time runs out.",
    "url": "https://cdn.htmlgames.com/EmojiPairs/index.html",
    "youtube": "https://www.youtube.com/watch?v=9qcrl2D5yVQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=EmojiPairs&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/EmojiPairs/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/EmojiPairs/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/EmojiPairs/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/emojipairs200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/emojipairs500.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/emojipairs800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/emojipairs1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/emojipairs1280720.webp"
    },
    {
    "name": "Fruit Cubes",
    "category": "Puzzle Games",
    "create_date": "2019-03-04",
    "description": "Fill the shapes with the Fruit Cubes. Drag the cubes into the shape and try to fill the complete shape. Click to rotate a cube on the conveyor belt.",
    "url": "https://cdn.htmlgames.com/FruitCubes/index.html",
    "youtube": "https://www.youtube.com/watch?v=06tPi16Yoyc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FruitCubes&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FruitCubes/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FruitCubes/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FruitCubes/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/fruitcubes200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/fruitcubes500.webp"
    },
    {
    "name": "Pesten",
    "category": "Card Games",
    "create_date": "2019-02-27",
    "description": "Pesten: the Dutch version of Crazy Eights. Be the first player to play away all your cards.",
    "url": "https://cdn.htmlgames.com/Pesten/index.html",
    "youtube": "https://www.youtube.com/watch?v=9zDsSOaO3a8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Pesten&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Pesten/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Pesten/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Pesten/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/pesten200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/pesten500.webp"
    },
    {
    "name": "Flower Tower Mahjong",
    "category": "Mahjong Tower",
    "create_date": "2019-02-25",
    "description": "Remove all stacked Flower tiles in this Tower Mahjong game. Combine two of the same free tile and try to remove all.",
    "url": "https://cdn.htmlgames.com/FlowerTowerMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=B8XtClhQooc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FlowerTowerMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FlowerTowerMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FlowerTowerMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FlowerTowerMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/flowertowermahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/flowertowermahjong500.webp"
    },
    {
    "name": "Neon Bubble",
    "category": "Bubble Shooter",
    "create_date": "2019-02-21",
    "description": "Shoot at the Neon Bubbles and match three or more. Aim and shoot.",
    "url": "https://cdn.htmlgames.com/NeonBubble/index.html",
    "youtube": "https://www.youtube.com/watch?v=SHsQ4IBcvYA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=NeonBubble&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/NeonBubble/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/NeonBubble/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/NeonBubble/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/neonbubble200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/neonbubble500.webp"
    },
    {
    "name": "Discover China",
    "category": "Difference Games",
    "create_date": "2019-02-18",
    "description": "Discover Mainland China in 30 levels. Find all the differences.",
    "url": "https://cdn.htmlgames.com/DiscoverChina/index.html",
    "youtube": "https://www.youtube.com/watch?v=iNqBhprJEnQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DiscoverChina&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DiscoverChina/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DiscoverChina/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DiscoverChina/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/discoverchina200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/discoverchina500.webp"
    },
    {
    "name": "Valentine 3D Mahjong",
    "category": "3D Mahjong",
    "create_date": "2019-02-12",
    "description": "A 3D Mahjong game for Valentine. Remove all cubes in pairs of the same. To remove a cube it needs to have at least two free adjacent sides.",
    "url": "https://cdn.htmlgames.com/Valentine3DMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=v4KB1Up-_aw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=Valentine3DMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/Valentine3DMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/Valentine3DMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/Valentine3DMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/valentine3dmahjong200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/valentine3dmahjong500.webp"
    },
    {
    "name": "Golf Solitaire Pro",
    "category": "Tripeaks & Golf",
    "create_date": "2019-02-11",
    "description": "A classic Golf Solitaire game. Remove all cards by removing cards that are one higher or one lower in value than the open card at the bottom.",
    "url": "https://cdn.htmlgames.com/GolfSolitairePro/index.html",
    "youtube": "https://www.youtube.com/watch?v=bS3f71mtSQA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=GolfSolitairePro&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/GolfSolitairePro/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/GolfSolitairePro/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/GolfSolitairePro/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/golfsolitairepro200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/golfsolitairepro500.webp"
    },
    {
    "name": "Daily Killer Sudoku",
    "category": "Sudoku",
    "create_date": "2019-02-07",
    "description": "Play Daily Killer Sudoku levels. Fill the grid with the numbers 1 to 9, such that each row, column and box contains each number only once. Additionally, a Killer Sudoku grid is divided into cages, each cage has its own background color. The values of the cells in a cage must sum up to the total for that cage, shown in the cage's top left corner. The same number cannot appear in a cage more than once.",
    "url": "https://cdn.htmlgames.com/DailyKillerSudoku/index.html",
    "youtube": null,
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyKillerSudoku&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyKillerSudoku/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyKillerSudoku/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyKillerSudoku/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dailykillersudoku200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dailykillersudoku500.webp"
    },
    {
    "name": "Bubble Trouble",
    "category": "Retro",
    "create_date": "2019-02-04",
    "description": "Destroy the all the bubbles. Shoot and destroy the bubbles. Use the arrow keys to move and the space bar to shoot. Or drag your character and tap to shoot.",
    "url": "https://cdn.htmlgames.com/BubbleTrouble/index.html",
    "youtube": "https://www.youtube.com/watch?v=nrscNA0-Kg8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BubbleTrouble&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BubbleTrouble/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BubbleTrouble/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BubbleTrouble/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/bubbletrouble200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/bubbletrouble500.webp"
    },
    {
    "name": "Blitz Wizards",
    "category": "Brain Games",
    "create_date": "2019-01-31",
    "description": "Find the object that is not in the picture in terms of color or shape. Click to start a game and click the correct object.",
    "url": "https://cdn.htmlgames.com/BlitzWizards/index.html",
    "youtube": "https://www.youtube.com/watch?v=ApLO1a7MwPA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=BlitzWizards&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/BlitzWizards/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/BlitzWizards/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/BlitzWizards/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/blitzwizards200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/blitzwizards500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/blitzwizards800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/blitzwizards1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/blitzwizards1280720.webp"
    },
    {
    "name": "Mouse Connection",
    "category": "Puzzle Games",
    "create_date": "2019-01-28",
    "description": "Connect the food for the mouse in the correct order. The example is given top left. You can only connect horizontally or vertically.",
    "url": "https://cdn.htmlgames.com/MouseConnection/index.html",
    "youtube": "https://www.youtube.com/watch?v=NEeJGZNpCfU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MouseConnection&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MouseConnection/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MouseConnection/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MouseConnection/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/mouseconnection200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/mouseconnection500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/mouseconnection800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/mouseconnection1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/mouseconnection1280720.webp"
    },
    {
    "name": "China Temple Mahjong",
    "category": "Mahjong Solitaire",
    "create_date": "2019-01-24",
    "description": "Play a relaxed game of Mahjong Solitaire. Combine two of the same free tiles to remove tiles from the layout.",
    "url": "https://cdn.htmlgames.com/ChinaTempleMahjong/index.html",
    "youtube": "https://www.youtube.com/watch?v=PkF20Jp5_q8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ChinaTempleMahjong&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ChinaTempleMahjong/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ChinaTempleMahjong/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ChinaTempleMahjong/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/chinatemplemahjong-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/chinatemplemahjong500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/chinatemplemahjong800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/chinatemplemahjong1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/chinatemplemahjong1280720.webp"
    },
    {
    "name": "World Cruise",
    "category": "Hidden Object Games",
    "create_date": "2019-01-21",
    "description": "Find all the hidden objects. Click to select an object. Drag to move around the screen.",
    "url": "https://cdn.htmlgames.com/WorldCruise/index.html",
    "youtube": "https://www.youtube.com/watch?v=KND2t10UhGs",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=WorldCruise&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/WorldCruise/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/WorldCruise/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/WorldCruise/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/worldcruise-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/worldcruise500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/worldcruise800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/worldcruise1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/worldcruise1280720.webp"
    },
    {
    "name": "Greedy Worm",
    "category": "Snake",
    "create_date": "2019-01-17",
    "description": "Eat as many presents as you can. Click or Tap to let your worm change directions.",
    "url": "https://cdn.htmlgames.com/GreedyWorm/index.html",
    "youtube": "https://www.youtube.com/watch?v=RDzn_tdxkdU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=GreedyWorm&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/GreedyWorm/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/GreedyWorm/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/GreedyWorm/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/greedyworm200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/greedyworm500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/greedyworm800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/greedyworm1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/greedyworm1280720.webp"
    },
    {
    "name": "Pixel Factory",
    "category": "Memory",
    "create_date": "2019-01-15",
    "description": "Paint with pixels and recreate an image. Choose the right color and click a cell to paint it.",
    "url": "https://cdn.htmlgames.com/PixelFactory/index.html",
    "youtube": "https://www.youtube.com/watch?v=t-wq2JXViPg",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PixelFactory&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PixelFactory/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PixelFactory/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PixelFactory/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/pixelfactory200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/pixelfactory500.webp"
    },
    {
    "name": "Flower Solitaire",
    "category": "Klondike",
    "create_date": "2019-01-15",
    "description": "A Flower Klondike Solitaire game. Move all cards to the four foundations from Ace to King. On the tableau build down in alternating color.",
    "url": "https://cdn.htmlgames.com/FlowerSolitaire/index.html",
    "youtube": "https://www.youtube.com/watch?v=1l8z4Qv8x18",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FlowerSolitaire&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FlowerSolitaire/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FlowerSolitaire/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FlowerSolitaire/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/flowersolitaire200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/flowersolitaire500.webp"
    },
    {
    "name": "Pyramid Mountains",
    "category": "Pyramid",
    "create_date": "2019-01-14",
    "description": "Pyramid Solitaire in the Mountains. Combine two cards to a total value of 13 and try to remove all cards.",
    "url": "https://cdn.htmlgames.com/PyramidMountains/index.html",
    "youtube": "https://www.youtube.com/watch?v=S9XZ7LKHQGQ",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PyramidMountains&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PyramidMountains/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PyramidMountains/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PyramidMountains/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/pyramidmountains200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/pyramidmountains500.webp"
    },
    {
    "name": "Pirate Jewel Collapse",
    "category": "Collapse Games",
    "create_date": "2019-01-10",
    "description": "Collapse groups of 3 or more jewels by clicking on connected groups.",
    "url": "https://cdn.htmlgames.com/PirateJewelCollapse/index.html",
    "youtube": "https://www.youtube.com/watch?v=EAFZPNqLVqw",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=PirateJewelCollapse&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/PirateJewelCollapse/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/PirateJewelCollapse/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/PirateJewelCollapse/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/piratejewelcollapse-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/piratejewelcollapse500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/piratejewelcollapse800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/piratejewelcollapse1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/piratejewelcollapse1280720.webp"
    },
    {
    "name": "Farm Stacker",
    "category": "Connect 3",
    "create_date": "2019-01-07",
    "description": "Stack animals on the farm. Create groups of five or more of the same animals to remove the groups.",
    "url": "https://cdn.htmlgames.com/FarmStacker/index.html",
    "youtube": "https://www.youtube.com/watch?v=anUAOxPVelc",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FarmStacker&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FarmStacker/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FarmStacker/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FarmStacker/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/farmstacker200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/farmstacker500.webp"
    },
    {
    "name": "Feed the Animals",
    "category": "Mahjong Connect",
    "create_date": "2018-12-28",
    "description": "Connect animals and their food. Connect the animal with the right food, you can only connect if the connecting path has no more than two changes of direction.",
    "url": "https://cdn.htmlgames.com/FeedTheAnimals/index.html",
    "youtube": "https://www.youtube.com/watch?v=n6EWohFssLk",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FeedTheAnimals&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FeedTheAnimals/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FeedTheAnimals/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FeedTheAnimals/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/feedtheanimals200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/feedtheanimals500.webp"
    },
    {
    "name": "Moving tiles",
    "category": "Mahjong Games",
    "create_date": "2018-12-21",
    "description": "Combine tiles before they move off the screen. Combine two of the same tiles.",
    "url": "https://cdn.htmlgames.com/MovingTiles/index.html",
    "youtube": "https://www.youtube.com/watch?v=CO39_AFpmH8",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=MovingTiles&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/MovingTiles/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/MovingTiles/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/MovingTiles/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/movingtiles-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/movingtiles500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/movingtiles800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/movingtiles1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/movingtiles1280720.webp"
    },
    {
    "name": "Classic Word Search",
    "category": "Word Games",
    "create_date": "2018-12-17",
    "description": "Classic Word Search game. Find all the words in all levels.",
    "url": "https://cdn.htmlgames.com/ClassicWordSearch/index.html",
    "youtube": "https://www.youtube.com/watch?v=-OCSpDMchmA",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ClassicWordSearch&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ClassicWordSearch/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ClassicWordSearch/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ClassicWordSearch/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/classic-word-search-200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/classic-word-search500.webp"
    },
    {
    "name": "Frozen for Christmas",
    "category": "Platform",
    "create_date": "2018-12-13",
    "description": "Santa has to freeze all the Elves for Christmas. Use the on-screen controls or the arrow keys and space bar. Freeze and catch all the Elves.",
    "url": "https://cdn.htmlgames.com/FrozenForChristmas/index.html",
    "youtube": "https://www.youtube.com/watch?v=nlsSWJ88DIU",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=FrozenForChristmas&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/FrozenForChristmas/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/FrozenForChristmas/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/FrozenForChristmas/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/frozenforchristmas200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/frozenforchristmas500.webp"
    },
    {
    "name": "Christmas Tripeaks",
    "category": "Tripeaks & Golf",
    "create_date": "2018-12-10",
    "description": "Play a Tripeaks Solitaire game for Christmas. Select cards that are one higher or one lower in value than the open card (bottom right).",
    "url": "https://cdn.htmlgames.com/ChristmasTripeaks/index.html",
    "youtube": "https://www.youtube.com/watch?v=yQAmR-VT8QI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ChristmasTripeaks&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ChristmasTripeaks/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ChristmasTripeaks/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ChristmasTripeaks/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/christmastripeaks200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/christmastripeaks500.webp"
    },
    {
    "name": "Christmas Connect",
    "category": "Mahjong Connect",
    "create_date": "2018-12-06",
    "description": "Mahjong connect game for Christmas. Connect two of the same tiles with a path that has no more than two turns of 90 degrees.",
    "url": "https://cdn.htmlgames.com/ChristmasConnect/index.html",
    "youtube": "https://www.youtube.com/watch?v=3ACqExQ_ASo",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=ChristmasConnect&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/ChristmasConnect/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/ChristmasConnect/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/ChristmasConnect/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/christmasconnect200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/christmasconnect500.webp"
    },
    {
    "name": "2048 City",
    "category": "2048 & Merge",
    "create_date": "2018-12-03",
    "description": "2048 game with buildings. Collide buildings to let the city grow. Reach the indicated building.",
    "url": "https://cdn.htmlgames.com/2048City/index.html",
    "youtube": "https://www.youtube.com/watch?v=WjzlGQ6dvy0",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=2048City&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/2048City/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/2048City/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/2048City/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/2048city200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/2048city500300.webp",
    "thumb6": "https://www.htmlgames.com/uploaded/game/thumb800/2048city800450.webp",
    "thumb7": "https://www.htmlgames.com/uploaded/game/thumb1000/2048city1024.webp",
    "thumb8": "https://www.htmlgames.com/uploaded/game/thumb1280/2048city1280720.webp"
    },
    {
    "name": "Daily Letter Logic",
    "category": "Word Games",
    "create_date": "2018-11-29",
    "description": "Everday new letter logic puzzles in 2 sizes. Fill in the given words on the grid using letters already given as hints. Click on a cell and then a word across or down. You cannot type letters. Use all words correctly to finish a puzzle.",
    "url": "https://cdn.htmlgames.com/DailyLetterLogic/index.html",
    "youtube": "https://www.youtube.com/watch?v=VWkuw0wsSwI",
    "embed": "<div><script src=\"https://cdn.htmlgames.com/embed.js?game=DailyLetterLogic&amp;width=800&amp;height=480&amp;bgcolor=white\"></script></div>",
    "width": 800,
    "height": 480,
    "thumb1": "https://cdn.htmlgames.com/DailyLetterLogic/img/icon/icon-120x120.png",
    "thumb2": "https://cdn.htmlgames.com/DailyLetterLogic/img/icon/icon-196x196.png",
    "thumb3": "https://cdn.htmlgames.com/DailyLetterLogic/img/icon/image-300x200.jpg",
    "thumb4": "https://www.htmlgames.com/uploaded/game/thumb200/dailyletterlogic200.webp",
    "thumb5": "https://www.htmlgames.com/uploaded/game/thumb500/dailyletterlogic500.webp"
    }
]