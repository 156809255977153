export const menu = [
    {
      path:'/',
      title:'najox games',
      
    },
    {
      path:'/affiliates',
      title:'affiliates'
    },
    {
      path:'/developers',
      title:'developers'
    },
    {
      path:'/contact',
      title:'contact us'
    },
    {
      path:'/about',
      title:'about us'
    },
    {
      path:'/privacy',
      title:'privacy policy'
    },
  ]