import React from 'react'
import "./style.css"

const About = () => {
  return (
    <div className='about_container'>
      <h2>About Us</h2>

      <p>Free Online Games - Play Now On NajoxGames</p>
      <p>NajoxGames features the latest and best free online games. We offer instant play to all our games without downloads, login, popups, or other distractions. You can enjoy playing fun games without interruptions from downloads or pop-ups.</p>
      <p>NajoxGames has the best free online games selection and offers the most fun experience to play alone or with friends.</p>

      <h2>About Games</h2>
      <p>Our mission is simple - to create a browser-gaming platform that works seamlessly for users around the world, and rewards developers both big and small.</p>
      <p>Our games are playable on desktop, tablet, and mobile so you can enjoy them at school, at home, or on the road. Every month millions of gamers from all over the world play their favorite games on NajoxGames. Our goal is to create the ultimate online playground. Free and open to all.</p>
      <p>We're a team of makers, techies, adventurers – and some gamers too. We’re kids of all ages, and love what we do.</p>
      <p>Just load up your favorite games instantly in your web browser and enjoy the experience.</p>
      <p>You can play our games on desktop mobile devices. That includes everything from desktop PCs, laptops, and Chromebooks, to the latest smartphones and tablets from Apple and Android.</p>
    </div>
  )
}

export default About
